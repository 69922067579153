/* eslint-disable */
import api from "@/api";
import { push } from 'notivue'

export default {
    namespaced: true,
    state: {
        projectAnalytics: null,
        projectData: null,
        indexGIP: null,
        projectSections: null,
        totalAnswers: null,
        totalQuestions: null,
    },

    getters: {
        getAnalytics(state) {
            return state.projectAnalytics;
        },
        getProjectData(state) {
            return state.projectData;
        },
        getIndexGIP(state) {
            return state.indexGIP;
        },
        getProjectSections(state) {
            return state.projectSections;
        },
        getTotalAnswers(state) {
            return state.totalAnswers;
        },
        getTotalQuestions(state) {
            return state.totalQuestions;
        },
    },

    mutations: {
        SET_ANALYTICS(state, payload) {
            state.projectAnalytics = payload;
        },
        SET_PROJECT_DATA(state, payload) {
            state.projectData = payload;
        },
        SET_INDEX_GIP(state, payload) {
            state.indexGIP = payload;
        },
        SET_PROJECT_SECTIONS(state, payload) {
            state.projectSections = payload;
        },
        SET_TOTAL_ANSWER(state, payload) {
            state.totalAnswers = payload;
        },
        SET_TOTAL_QUESTIONS(state, payload) {
            state.totalQuestions = payload;
        },
    },

    actions: {
        fetchAnalytics: async (context, payload) => {
            try {
                let data = (await api.sip.getAnalytics(payload)).data;
                context.commit("SET_ANALYTICS", data);
            } catch (err) {
                push.error(`Ошибка загрузки данных аналитики!`)
            }
        },
        fetchProjectData: async (context, payload) => {
            try {
                let data = (await api.sip.getInfo(payload)).data;
                context.commit("SET_PROJECT_DATA", data);
            } catch (err) {
                push.error(`Ошибка загрузки данных проекта!`)
            }
        },
        fetchIndexGIP: async (context, payload) => {
            try {
                let params = new URLSearchParams();
                if (["project", "details", "analytics", "section"].includes(payload.name)) {
                    params.append("project", payload.params.id_proj);
                }
                if (["section"].includes(payload.name)) {
                    params.append("section", payload.params.id_sect);
                }
                let data = (await api.sip.indexGip(params)).data;
                context.commit("SET_INDEX_GIP", data.index_gip);
            } catch (err) {
                push.error(`Ошибка загрузки данных индекса ГИП!`)
            }
        },
        fetchAnswerQuestions: async (context, payload) => {
            try {
                let params = new URLSearchParams();
                if (["project", "details", "analytics", "section"].includes(payload.name)) {
                    params.append("project", payload.params.id_proj);
                }
                if (["section"].includes(payload.name)) {
                    params.append("section", payload.params.id_sect);
                }
                let data = (await api.sip.answerQuestions(params)).data;
                context.commit("SET_TOTAL_ANSWER", data.answer_count);
                context.commit("SET_TOTAL_QUESTIONS", data.question_count);
            } catch (err) {
                push.error(`Ошибка загрузки данных индекса ГИП!`)
            }
        },
        fetchProjectSections: async (context, payload) => {
            try {
                let data = (await api.sip.list("sections", payload)).data;
                context.commit("SET_PROJECT_SECTIONS", data);
            } catch (err) {
                push.error(`Ошибка загрузки данных секций проекта!`)
            }
        },
        getTemplate: async (context, templateType) => {
            const TEMPLATE_TYPES = ['gantt', 'cost-profiles']
            try {
                if (!TEMPLATE_TYPES.includes(templateType)) throw `Import template type ${templateType} is not accepted.`
                let data = (await api.sip.getImportTemplate(templateType)).data;
                return data;
            } catch (err) {
                console.log(err);
                notify({
                    group: "app",
                    type: "error",
                    title: "Ошибка получения итоговых данных",
                    text: err.response,
                });
            }
        },
        getResults: async (context, payload = null) => {
            try {
                let data = (await api.sip.getResults(payload)).data;
                return data;
            } catch (err) {
                push.error(`Ошибка получения итоговых данных!`)
            }
        },
        createProject: async (context, payload) => {
            try {
                let data = (await api.sip.create("projects", payload)).data;
                return data;
            } catch (err) {
                push.error(`Ошибка создания проекта!`)
            }
        },
        fetchList: async (context, payload) => {
            let apiSection;
            let params;
            if (typeof payload === "string") {
                apiSection = payload;
            } else {
                apiSection = payload.get("apiSection");
                payload.delete("apiSection");
                params = payload;
            }
            try {
                let data = (await api.sip.list(apiSection, params)).data;
                return data;
            } catch (err) {
                push.error(`Ошибка получения данных!`)
            }
        },
        patchObject: async (context, payload) => {
            const isIndexGipUpdateFields = ["maturity", "is_relevant"];

            let apiSection = payload.apiSection;
            delete payload.apiSection;
            let objectId = payload.objectId;
            delete payload.objectId;
            return await api.sip.patch(objectId, payload, apiSection);
            // try {
            //     let data = (await api.sip.patch(objectId, payload, apiSection)).data;
            //     return data;
            // } catch (err) {
            //     push.error(`Ошибка получения данных!`)
            // }
        },
        deleteObject: async (context, payload) => {
            let apiSection = payload.apiSection;
            delete payload.apiSection;
            let objectId = payload.objectId;
            delete payload.objectId;
            try {
                await api.sip.delete(objectId, apiSection);
                return;
            } catch (err) {
                push.error(`Ошибка удаления объекта!`)
            }
        },
        fetchQuestionsList: async (context, payload) => {
            let apiSection;
            let params;
            if (typeof payload === "string") {
                apiSection = payload;
            } else {
                apiSection = payload.get("apiSection");
                payload.delete("apiSection");
                params = payload;
            }
            try {
                let data = (await api.question.list(apiSection, params)).data;
                return data;
            } catch (err) {
                push.error(`Ошибка получения данных!`)
            }
        },
    },
};
