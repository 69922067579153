<template>
    <ul class="error-list">
        <li v-for="err in errors" :key="err" class="error">
            {{ err }}
        </li>
    </ul>
</template>

<script>
export default {
    name: 'error-list',
    props: {
        errors: Array,
    },
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

ul.error-list {
    color: $color-red-1;
    width: 100%;
    font-size: 13px;
    list-style-type: none;
    padding: 10px 0;
}
</style>
