<template>
    <div class="time">
        {{ time }}
    </div>
</template>

<script>
export default {
    name: 'sip-time',
    data() {
        return {
            time: '',
            options: {
                // era: 'long',
                // year: 'numeric',
                // month: 'long',
                // day: 'string',
                // weekday: 'long',
                // timezone: 'UTC',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            },
        }
    },
    mounted: function () {
        window.setInterval(() => {
            let t = new Date();
            // this.time = `${t.getHours()}:${t.getMinutes()}`;
            this.time = t.toLocaleString('ru', this.options);
        }, 1000)
    },
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

.time {
    color: $color-gray-3;
    text-align: center;
    vertical-align: center;
    margin: 5px 50px;
    font-size: 24px;
    font-family: $sf-light;
}
</style>