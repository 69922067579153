<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false" :disabled="disabled ? true : false">
    <div class="selected" :class="selected == defaultValue ? 'default' : ''" :style="{ open: open }"
      @click="if (!disabled) open = !open;">
      {{ getSelectedOption() }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="
        selected = option.id;
      open = false;
      if (!disabled) { $emit('update:modelValue', option.id); };
      if (!disabled) { $emit('change', option.id); };
      ">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sip-select-custom',
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
      default: 'Выберите значение',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    modelValue: {
      type: undefined,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected:
        this.modelValue ? this.modelValue
          : this.defaultValue ? this.defaultValue
            : this.options.length > 0
              ? this.options[0]
              : null,
      open: false,
    };
  },
  methods: {
    getSelectedOption() {
      if (this.selected && this.options) {
        let option = this.options.find(el => this.selected == el.id)
        if (option) return option.name;
        return this.defaultValue;
      }
    }
  },
  mounted() {
    this.$emit("update:modelValue", this.selected);
    // this.$emit("change", this.selected);
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';
@import '@/css/components/select.scss';
</style>