const sidebar = {
    defaultWidth: 76,       // ширина sidebar по умолчанию
    extendedWidth: 237,     // ширина раскрытого sidebar
    arrowAvatarPadding: 15,
    arrowAvatarPaddingDefault: 8,
}

const navbar = {
    height: 74,
}

const table = {
    margins: 36,
    paddings: 35,
    capHeight: 56,
    rowHeight: 56,
    marginLeft: 35,
}

const projectInfo = {
    tableStatus: 'projectList',
    project: null,
    section: null,
}

const projectList_columnData = [
    {
        keyName: 'name',
        title: 'Название проекта',
        type: 'text',
        isStretchable: true,
        columnWidth: 368,
        textCapWidth: 300,
        textRowWidth: 342,
        columnWidthPercent: 36.3,
        textCapWidthPercent: 7.78,
    },
    {
        keyName: 'stage',
        title: 'Этап',
        type: 'text',
        isStretchable: false,
        columnWidth: 140,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.02,
        textCapWidthPercent: 2.09,
    },
    {
        keyName: 'phase',
        title: 'Фаза',
        type: 'text',
        isStretchable: false,
        columnWidth: 160,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 11.27,
        textCapWidthPercent: 2.2,
    },
    {
        keyName: 'indexGIP',
        title: 'Индекс ГИП',
        type: 'gip',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.58,
        textCapWidthPercent: 5.19,
    },
    {
        keyName: 'manager',
        title: 'Автор',
        type: 'text',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 12.41,
        textCapWidthPercent: 2.59,
    },
    {
        keyName: 'updated_at',
        title: 'Последнее изменение',
        type: 'date',
        isStretchable: false,
        columnWidth: 220,
        textCapWidth: 200,
        textRowWidth: 342,
        columnWidthPercent: 18.04,
        textCapWidthPercent: 9.64,
    },
    {
        keyName: '',
        title: '',
        type: 'menu',
        isStretchable: false,
        columnWidth: 220,
        textCapWidth: 200,
        textRowWidth: 342,
        columnWidthPercent: 3.38,
        textCapWidthPercent: 0,
    },
]

const sectionList_columnData = [
    {
        keyName: 'section.name',
        title: 'Область экспертизы',
        type: 'text',
        isStretchable: true,
        columnWidth: 368,
        textCapWidth: 300,
        textRowWidth: 342,
        columnWidthPercent: 36.3,
        textCapWidthPercent: 7.78,
    },
    {
        keyName: 'expert.username',
        title: 'Эксперт',
        type: 'text',
        isStretchable: false,
        columnWidth: 140,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.02,
        textCapWidthPercent: 2.09,
    },
    {
        keyName: 'attachment_count',
        title: 'Файлов, ед.',
        type: 'text',
        isStretchable: false,
        columnWidth: 160,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 11.27,
        textCapWidthPercent: 2.2,
    },
    {
        keyName: 'question_count',
        title: 'Ответов, ед.',
        type: 'gip',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.58,
        textCapWidthPercent: 5.19,
    },
    {
        keyName: 'index_gip',
        title: 'Проработка, %',
        type: 'text',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 12.41,
        textCapWidthPercent: 2.59,
    },
]

const questionList_columnData = [
    {
        keyName: 'section.name',
        title: '№ вопроса',
        type: 'text',
        isStretchable: true,
        columnWidth: 368,
        textCapWidth: 300,
        textRowWidth: 342,
        columnWidthPercent: 36.3,
        textCapWidthPercent: 7.78,
    },
    {
        keyName: 'expert.username',
        title: 'Формулировка вопроса',
        type: 'text',
        isStretchable: false,
        columnWidth: 140,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.02,
        textCapWidthPercent: 2.09,
    },
    {
        keyName: 'attachment_count',
        title: 'Характеристика ГИП',
        type: 'text',
        isStretchable: false,
        columnWidth: 160,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 11.27,
        textCapWidthPercent: 2.2,
    },
    {
        keyName: 'question_count',
        title: 'Степень проработки',
        type: 'gip',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 9.58,
        textCapWidthPercent: 5.19,
    },
    {
        keyName: 'index_gip',
        title: 'Комментарий',
        type: 'text',
        isStretchable: false,
        columnWidth: 200,
        textCapWidth: 114,
        textRowWidth: 342,
        columnWidthPercent: 12.41,
        textCapWidthPercent: 2.59,
    },
]

export {
    sidebar,
    navbar,
    table,
    projectInfo,
    projectList_columnData,
    sectionList_columnData,
    questionList_columnData,
}