export default {
    namespaced: true,
    state: {
        sidebarWidth: null,
        screenWidth: null,
        screenHeight: null,
        // tableStatus: ['projectList', ''],
        projectName: null,
        section: null,
        subSection: null,
        isSidebarOpen: false,
        indexGIPNav: null,
        activeViewUser: null,
    },
    getters: {
        getSidebarWidth(state) {
            return state.sidebarWidth
        },
        getScreenWidth(state) {
            return state.screenWidth
        },
        getScreenHeight(state) {
            return state.screenHeight
        },
        getSection(state) {
            return state.section
        },
        getSubSection(state) {
            return state.subSection
        },
        getIsSidebarOpen(state) {
            return state.isSidebarOpen
        },
        getActiveViewUser(state) {
            return state.activeViewUser
        },
        indexGIPNav(state) {
            return state.indexGIPNav
        }
    },
    mutations: {
        SET_ACTIVE_VIEW_USER(state, payload) {
            state.activeViewUser = payload
        },
        SET_SIDEBAR_WIDTH(state, payload) {
            state.sidebarWidth = payload
        },
        SET_SCREEN_WIDTH(state, payload) {
            state.screenWidth = payload
        },
        SET_SCREEN_HEIGHT(state, payload) {
            state.screenHeight = payload
        },
        // SET_TABLE_STATUS(state, payload) {
        //     state.tableStatus = payload
        // },
        SET_SECTION(state, payload) {
            state.section = payload
        },
        SET_SUBSECTION(state, payload) {
            state.subSection = payload
        },
        OPEN_SIDEBAR(state) {
            state.isSidebarOpen = true
        },
        CLOSE_SIDEBAR(state) {
            state.isSidebarOpen = false
        },
        SET_INDEX_GIP_NAV(state, payload) {
            state.indexGIPNav = payload
        }
    },
    actions: {
        setActiveViewUser({ commit }, payload) {
            commit('SET_ACTIVE_VIEW_USER', payload)
        },
        setSidebarWidth({ commit }, payload) {
            commit('SET_SIDEBAR_WIDTH', payload)
        },
        setScreenWidth({ commit }, payload) {
            commit('SET_SCREEN_WIDTH', payload)
        },
        setScreenHeight({ commit }, payload) {
            commit('SET_SCREEN_HEIGHT', payload)
        },
        // setTableStatus({ commit }, payload) {
        //     commit('SET_TABLE_STATUS', payload)
        // },
        setSection({ commit }, payload) {
            commit('SET_SECTION', payload)
        },
        setSubSection({ commit }, payload) {
            commit('SET_SUBSECTION', payload)
        },
        openSidebar({ commit }) {
            commit('OPEN_SIDEBAR')
        },
        closeSidebar({ commit }) {
            commit('CLOSE_SIDEBAR')
        },
        changeSidebar({ state, commit }) {
            if (!state.isSidebarOpen) commit('OPEN_SIDEBAR');
            else commit('CLOSE_SIDEBAR')
        },
        setIndexGIPNav({ commit }, payload) {
            commit('SET_INDEX_GIP_NAV', payload)
        },
    }
}