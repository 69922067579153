<template>
    <section class="container">
        <div class="panel">
            <div class="skeleton">
                <div class="skeleton__tr">
                    <div class="skeleton__td"></div>
                    <div class="skeleton__td"></div>
                    <div class="skeleton__td"></div>
                    <div class="skeleton__td"></div>
                    <div class="skeleton__td"></div>
                </div>
                <div class="skeleton__hr"></div>
                <div class="skeleton__section skeleton__section--table">
                    <div v-for="row in rows" :key="row" class="skeleton__tr">
                        <div v-for="col in cols" :key="col" class="skeleton__td"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SkeletonLoader',
    props: {
        rows: {
            type: Number,
            default: 12,
        },
        cols: {
            type: Number,
            default: 5,
        },
    },
};
</script>

<style lang="scss" scoped>
.skeleton {
    *:empty {
        background: #f6f7f8;
        background-position: -500px 0;
        animation: skeletonShine 1s linear 0s infinite normal forwards;
        background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 1000px 100%;
    }

    &__hr {
        height: 2px;
        width: 100%;
        margin-bottom: 20px;
    }

    &__tr {
        display: flex;
        gap: 20px;


    }

    &__td {
        flex: 1 1 100%;
        height: 30px;
        margin: 12px 0;

        &:nth-child(2) {
            flex-basis: 500%;
        }

        &:nth-child(3) {
            flex-basis: 300%;
        }

        &:nth-child(4) {
            flex-basis: 900%;
        }

        &:nth-child(5) {
            flex-basis: 700%;
        }
    }
}

@keyframes skeletonShine {
    to {
        background-position: 500px 0;
    }
}
</style>