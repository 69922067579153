const EMPTY_NAME = '-'

export const mText = {
    methods: {
        getSliceOfString(str_, count_) {
            return str_.slice(0, count_);
        },
        getFullName(user_, priorityName_ = 'last_name', showSecondName = false) {
            if (user_) {
                let first_, last_, second_;
                if (priorityName_ == 'first_name') {
                    first_ = user_.first_name || '';
                    last_ = user_.last_name || '';
                } else if (priorityName_ == 'last_name') {
                    first_ = user_.last_name || '';
                    last_ = user_.first_name || '';
                }
                if (first_ == '' && last_ == '') return EMPTY_NAME;
                if (last_ != '' && last_.length > 1) last_ = last_[0] + '.';
                if (priorityName_ == 'last_name' && showSecondName && user_.second_name) {
                    second_ = user_.second_name[0] || '';
                    if (user_.second_name.length > 1) second_ = second_ + '.';
                    return `${first_} ${last_}${second_}`;
                }
                return `${first_} ${last_}`;
            }
            return
        },
        getInitialsByUser(user_) {
            if (!user_) return "";
            if (Object.hasOwn(user_, 'first_name') && Object.hasOwn(user_, 'last_name')) {
                let f_name = '';
                let l_name = '';
                if (user_.first_name != null && user_.first_name.length > 0) f_name = user_.first_name[0];
                if (user_.last_name != null && user_.last_name.length > 0) l_name = user_.last_name[0];
                if (f_name == '' && l_name == '') return EMPTY_NAME
                return `${f_name}${l_name}`;
            }
            return "";
        },
    }
}
