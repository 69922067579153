<template>
	<div class="avatar__wrapper" :style="{ height: `${size}px`, width: `${size}px` }">
		<img v-if="user.image" :class="avatarIcon" :src="user.image" alt="Аватар пользователя"
			:style="{ height: `${size + 2}px`, width: `${size + 2}px`, }" />
		<div v-else class="avatar" :style="{
			backgroundColor: getBGColor(user.color),
			color: user.color,
			fontSize: getFontSize()
		}">
			{{ getInitialsByUser(user) }}
		</div>
	</div>
</template>

<script>
import { mText } from '@/utils/text'

export default {
	name: "sip-avatar",
	mixins: [mText],
	props: {
		user: {
			type: Object,
			required: true,
		},
		size: {
			type: Number,
			default: 35,
		},
	},
	data() {
		return {
			isSourceAvaliable: false,
			color: "#000000",
			border: "#ffffff88",
			image: "",
			first_name: "",
			last_name: "",
		};
	},
	watch: {
		user(value_) {
			this.color = value_.color;
			this.getInitialsByUser(value_);
			this.first_name = value_.first_name;
			this.last_name = value_.last_name;
			this.image = value_.image;
		},
	},
	methods: {
		getFontSize() {
			return this.size == 30 ? "13px" : "14px"
		},
		getBGColor(hex_) {
			if (hex_) {
				return hex_ + "70";
			}
			return "#ffffff60";
		},
	},
	computed: {
		avatarIcon() {
			return this.size == 30 ? "avatar small" : "avatar"
		},
	},
	updated() {
		this.color = this.user.color;
		this.getInitialsByUser(this.user);
	},
	mounted() {
		if (this.user) {
			if (this.user == null || this.user == undefined) return
			this.color = this.user.color;
			if (this.user.image) {
				this.isSourceAvaliable = true;
				this.image = this.user.image;
			}
			this.first_name = this.user.first_name;
			this.last_name = this.user.last_name;
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";

.avatar-icon {
	max-width: 40px;
	background: none;
	height: 100%;
	padding: 0;
	// border: none;
	object-fit: cover;
	border-radius: 0.6rem,
}

.avatar {
	background: none;
	min-height: 100%;
	min-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	// border-radius: 50%;
	border: none;
	height: 15px;
	width: 15px;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 100%;

	& .small {
		font-size: 11px;
	}
}

.avatar__wrapper {
	background: none;
	padding: 0;
	margin: 0;
	aspect-ratio: 1 / 1;
}
</style>
