<template>
	<div class="tb-background">
		<Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>
		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :showSearch="true" v-model:searchQuery="searchText" />
		<Transition name="fade">
			<div class="table table__wrapper" v-if="userListFiltered.length != 0">
				<div class="row row__header">
					<div class="col col__header" style="width: 60px;justify-content: flex-start;margin-left: 20px">#
					</div>
					<div class="col-2 col__header" style="margin-left: 20px"
						:class="this.currentSortField === 'name' ? 'selected' : ''" @click="sortTable('name')">
						Имя
						<img :class="['imm_array_sort', getSortClasses('name')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col-2 col__header" style="width: 220px"
						:class="this.currentSortField === 'role' ? 'selected' : ''" @click="sortTable('role')">
						Роль
						<img :class="['imm_array_sort', getSortClasses('role')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col-2 col__header" :class="this.currentSortField === 'email' ? 'selected' : ''"
						@click="sortTable('email')">
						Электронная почта
						<img :class="['imm_array_sort', getSortClasses('email')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col col__header">Области экспертизы</div>
				</div>

				<div class="table__body">
					<TransitionGroup name="list">
						<div class="row row__content" v-for="(row, rowIndex) in userListFiltered" :key="row.id"
							@click="goToUser(row.id)">
							<div class="col col__content ">{{ rowIndex + 1 }}</div>
							<div class="col-2 col__content" style="margin-left: 20px">
								<sip-avatar :user="row" :style="{ marginRight: '15px' }" :size="30" />
								{{ row.name }}
							</div>
							<div class="col-1 col__content" style="width: 220px" :style="roleColor(row)">{{ row.role }}
							</div>
							<div class="col-2 col-sm-2 col__content">{{ row.email }}</div>
							<div class="col col__content">
								<sip-badge :color="section.color" v-for="section in getSections(row.sections)"
									:key="section.slug">
									{{ section.name }}
								</sip-badge>
							</div>
						</div>
					</TransitionGroup>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import { mFilters } from "@/utils/filters";
import { mText } from "@/utils/text";
import { mPermissions } from "@/utils/permissions";
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { push } from 'notivue'

export default {
	name: "TableUsers",
	mixins: [mFilters, mText, mPermissions],
	components: { HeaderComp },
	data() {
		return {
			userList: [],
			sectionList: [],
			isProjectManagerOptions: [
				{ id: true, name: "Да" },
				{ id: false, name: "Нет" },
			],
			showPM: true,
			showNotPM: true,
			isLoading: false,
			searchText: "",
			searchNoContentMessage: "😅 ничего не нашлось...",
			sortDirection: 1,
			currentSortField: "name",
			navLinks: [{ id: "", name: "Доступный перечень пользователей", show: true }],
		};
	},
	methods: {
		getSections(sections) {
			return this.sectionList.filter(el => sections.includes(el.id))
		},
		goToUser(id_) {
			this.$router.push({ name: "user", params: { id_user: id_ } });
		},
		getBGBadgeColor(hex_) {
			return `${hex_}40`;
		},
		normalizeUserList(data_) {
			let new_data = [];
			data_.forEach((el) => {
				new_data.push({
					id: el.id,
					name: this.getFullName(el),
					first_name: el.first_name,
					last_name: el.last_name,
					username: el.username || "-",
					email: el.email || "-",
					sections: el.sections || [],
					image: el.image || "",
					color: el.color || "-",
					role: this.getUserRole(el),
				});
			});
			return new_data;
		},
		async getUserList() {
			this.isLoading = true
			try {
				const data = (await this.$api.user.list()).data;
				this.userList = this.normalizeUserList(data);
				this.sectionList = (await this.$api.question.list('sections')).data;
			} catch (error) {
				push.error(`Ошибка получения списка пользователей!`)
				this.$router.push({ path: "/" });
			} finally {
				this.isLoading = false
			}
		},
		roleColor(user) {
			return {
				color: this.checkAdmin(user) ? '#439DDB' : '#AFAFB9'
			}
		},
	},
	computed: {
		userListFiltered() {
			let data = this.userList;

			data = data.filter((user) => {
				return (
					user.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
					user.email.toLowerCase().includes(this.searchText.toLowerCase())
				);
			});

			// сортировка массива
			data.sort((a, b) => {
				let a_ = 0;
				let b_ = 0;

				if (this.currentSortField == "is_project_manager") {
					a_ = Boolean(a[this.currentSortField]);
					b_ = Boolean(b[this.currentSortField]);
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				} else {
					a_ = a[this.currentSortField];
					b_ = b[this.currentSortField];
					if (a_ == b_) return 1;
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				}
			});
			return data;
		},
	},
	created() {
		this.getUserList();
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableUsers.scss";
@import "@/css/animation/lists.scss";

.table__body {
	height: calc(100% - 150px);
}

.col__header:first-child,
.col__content:first-child {
	width: 60px;
	justify-content: flex-start;
	margin-left: 20px;
}

img.checked {
	background: transparent;
	padding: 0;
	margin: 0;
	height: 10px;
	filter: $filter-green-1;
}

img.unchecked {
	background: transparent;
	padding: 0;
	margin: 0;
	height: 10px;
	filter: $filter-red-1;
}

.arrow-up {
	transition: all ease-in-out 0.3s;
	transform: rotate(0deg);
}

.arrow-down {
	transition: all ease-in-out 0.3s;
	transform: rotate(180deg);
}

.imm_array_sort {
	width: 10px;
	padding: 0;
	background: none;
	filter: $filter-gray-5;

	&:hover {
		cursor: pointer;
	}
}

.col {
	margin: 10px;
}

.badge {
	margin: 3px;
}
</style>
