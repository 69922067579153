<template>
	<div class="general">
		<div class="small-block">
			<sidebar />
		</div>
		<div class="big-block">
			<navbar />
			<div class="page-second-screen">
				<router-view />
			</div>
		</div>
	</div>
</template>


<script>
	import Sidebar from "@/components/common/Sidebar.vue";
	import Navbar from "@/components/common/Navbar.vue";
	import { mapActions } from "vuex";

	export default {
		name: "PageHome",
		components: {
			Sidebar,
			Navbar,
		},
		data() {
			return {
				user: {
					is_project_manager: false,
					is_admin: false,
				},
			};
		},
		methods: {
			...mapActions({
				setSectionToStorage: "domprops/setSection",
				setSubSectionToStorage: "domprops/setSubSection",
				setUser: "user/setUser",
				closeSidebar: "domprops/closeSidebar",
			}),
		},
		async created() {
			if (this.$route.name === "projectList") {
				this.setSectionToStorage("projects");
			} else if (this.$route.name === "userList") {
				this.setSectionToStorage("users");
			} else if (this.$route.name === "createProject") {
				this.setSectionToStorage("projects");
				this.setSubSectionToStorage("createProject");
			}

			try {
				this.user = JSON.parse(localStorage.getItem("user"));
			} catch (error) {
				console.error(error);
			}
		},
		mounted() {
			// событие на закрытие сайдбара при нажатии по области
			const bigBlock = document.getElementsByClassName("big-block")[0];
			bigBlock.addEventListener("click", () => this.closeSidebar());
		},
	};
</script>


<style lang="scss" scoped>
	@import "@/css/main.scss";
</style>
