export default function (instance) {
  let apiModule = "sip";
  let apiSections = {
    projects: "projects",
    types: "types",
    sections: "sections",
    questions: "questions",
    gantt: "gantt",
    files: "files",
    costs: "cost-profiles",
  };

  return {
    list(section_ = "projects", params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/`);
      } else {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/`, {
          params: params,
        });
      }
    },
    retrieve(projectID, section_ = "projects", params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/${projectID}/`);
      } else {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/${projectID}/`, {
          params: params,
        });
      }
    },
    create(section_ = "projects", payload) {
      return instance.postForm(`api/${apiModule}/${apiSections[section_]}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    patch(id_, payload_, section_ = "projects") {
      return instance.patch(`api/${apiModule}/${apiSections[section_]}/${id_}/`, payload_);
    },
    delete(id_, section_ = "projects") {
      return instance.delete(`api/${apiModule}/${apiSections[section_]}/${id_}/`);
    },
    getAnalytics(projectID) {
      return instance.get(`api/${apiModule}/${apiSections["projects"]}/${projectID}/analytics/`);
    },
    getInfo(projectID) {
      return instance.get(`api/${apiModule}/${apiSections["projects"]}/${projectID}/info/`);
    },
    getResults(params = null) {
      return instance.get(`api/${apiModule}/projects/download_results/`, {
        responseType: "arraybuffer",
        params: params,
      });
    },

    // importGantt(payload) {
    //   return instance.postForm(`api/${apiModule}/gantt/import/`, payload, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    // },
    deleteGantt(payload) {
      return instance.postForm(`api/${apiModule}/gantt/delete/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    // importCostProfile(payload) {
    //   return instance.postForm(`api/${apiModule}/cost-profiles/import/`, payload, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    // },
    deleteCostProfile(payload) {
      return instance.postForm(`api/${apiModule}/cost-profiles/delete/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    indexGip(params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/index-gip/`);
      } else {
        return instance.get(`api/${apiModule}/index-gip/`, {
          params: params,
        });
      }
    },
    answerQuestions(params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/answer-questions/`);
      } else {
        return instance.get(`api/${apiModule}/answer-questions/`, {
          params: params,
        });
      }
    },

    importValidate(payload, _section = 'cost-profiles') {
      return instance.post(`api/${apiModule}/${_section}/import/validate/`, payload);
    },
    importSave(payload, _section = 'cost-profiles') {
      return instance.post(`api/${apiModule}/${_section}/import/save/`, payload);
    },
    getImportTemplate(_section = 'cost-profiles') {
      return instance.get(`api/${apiModule}/${_section}/import/get-template/`);
    },
  };
}
