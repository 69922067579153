<template>
	<div class="card">
		<div class="card_header">
			{{ title }}
			<slot name="select"></slot>
			<div class="card_header_controls">
				
				<div class="dropdown button__options">
					<img id='btndownload' class="button_options_icon" src="@/assets/icons/options.svg" type="button"
						data-bs-toggle="dropdown" aria-expanded="false">

					<ul class="dropdown-menu ">
						<li><a class="dropdown-item" href="#">...</a></li>
						<li><a class="dropdown-item" href="#">...</a></li>
						<li><a class="dropdown-item" href="#" @click="downloadCard('png')">Скачать</a></li>
					</ul>
				</div>

			</div>
		</div>
		<div class="card_body">
			<slot></slot>
		</div>
	</div>
</template>


<script>
import html2canvas from 'html2canvas';
export default {
	name: "sip-card",
	props: {
		title: String
	},
	data() {
		return {

		}
	},
	methods: {
		downloadCard(format) {
			let canvasTransformURLFormat = 'image/png';

			html2canvas(this.$el, {
				allowTaint: true,
				onclone: (clonedEl) => {
					// Обнуляем BoxShadow для корректного отображения и удаляем блок опций
					let dropdownEl = clonedEl.getElementsByClassName('dropdown-menu show')[0]
					let card = dropdownEl.closest(".card")
					card.style.boxShadow = 'none'
					dropdownEl.closest(".card_header_controls").remove()
				}
			}).then(canvas => {
				let imgURI
				switch (format) {
					case "png":
						imgURI = canvas.toDataURL(canvasTransformURLFormat)
						this.triggerDownload(
							imgURI,
							this.title,
							'.png'
						);
						break;
					default:
						break;
				}
			})
		},

		triggerDownload(href, filename, ext) {
			const downloadLink = document.createElement('a')
			downloadLink.href = href
			downloadLink.download = filename + ext
			document.body.appendChild(downloadLink)
			downloadLink.click()
			document.body.removeChild(downloadLink)
		},
	}
};
</script>


<style lang="scss" scoped>
	@import "@/css/general.scss";

	.card {
		background-color: rgba(#ffffff, 0);
		margin: 0;
		padding: 25px 30px;
		flex-grow: 1;
		height: auto;
		max-width: 100%;
		min-width: 30%;
		border-radius: 0px;
		border: none;
	}

	.card_header {
		border-bottom: 1px solid $color-gray-1;
		height: 35px;
		display: flex;
		justify-content: space-between;
		font-family: "sf-pro-display-light";
		color: #363639;
		font-size: 18px;
	}

	.card_body {
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-grow: 1;
	}

	.button__options {
		cursor: pointer;

		& .button_options_icon {
			transition: all ease 0.3s;
			filter: invert(90%) sepia(3%) saturate(6%) hue-rotate(88deg) brightness(94%) contrast(92%);
		}

		&:hover {
			& .button_options_icon {
				filter: none;
			}
		}
	}
</style>
