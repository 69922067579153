import axios from 'axios'
// import { Modal } from "bootstrap"

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.BASE_URL : 'http://localhost:8000/',
    headers: {
        accept: 'application/json;application/octet-stream',
    }
})
// const spinner = document.getElementById("spinner");
// if (!spinner) {
//     console.error("Modal element does not exist in the DOM.");
// }
// const pendingModal = new Modal(spinner);


instance.interceptors.request.use(
    (config) => {
        const access = localStorage.getItem('access')
        if (access) {
            config.headers = {
                'Authorization': `Bearer ${access}`,
            }
        }
        // pendingModal.show()
        return config
    }, (error) => {
        // pendingModal.hide()
        return Promise.reject(error);
    }
)

instance.interceptors.response.use(
    (config) => {
        // pendingModal.hide()
        return config
    },
    async (error) => {
        // pendingModal.hide()
        const originalConfig = error.config;
        if (error.response) {
            if (error.response.status == 401) {
                originalConfig._retry = true;
                try {
                    const token = localStorage.getItem('refresh');
                    const rs = await refreshToken(token);
                    const { access } = rs.data;
                    localStorage.setItem("access", access);
                    instance.defaults.headers.common["Authorization"] = `Bearer ${access}`;
                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        this.$router.push('login')
                        return Promise.reject(_error.response.data);
                    }
                }
            }
            if (error.response.status === 403 && error.response.data) {
                return Promise.reject(error.response.data);
            }
        }
        return Promise.reject(error);
    }
)

function refreshToken(token) {
    return instance.post('api/auth/jwt/refresh/', { 'refresh': token });
}

export default instance

