<template>
	<div class="tb-background" style="position: relative">
		<HeaderComp :navItems="navLinks" :currentPage="currentPage" @changePage="(page) => (currentPage = page)"
			:showBtn="true">
			<template v-slot:buttons>
				<sip-button style="justify-self: flex-end" :class="!checkRequired() ? 'bg-gray' : 'bg-green'"
					@click="createUser" :disabled="checkRequired() ? false : true">
					Зарегистрировать
				</sip-button>
			</template>
		</HeaderComp>
		<div class="content">
			<div class="content-block row" v-if="currentPage == 'profile'">
				<div class="block-group">
					<div class="subtitle">Аватар</div>
					<div class="block-row start">
						<div class="block" style="gap: 10px; height: 100%; width: auto; justify-content: flex-start">
							<input type="file" accept="image/jpeg,image/png" id="file" ref="file"
								@change="handleFileUpload($event.target.files)" style="display: none"
								placeholder=" Импорт" />
							<div class="figure__wrapper">
								<img id="avatar" class="figure__img"
									:class="tempCreationCommonData.image ? 'selected' : ''" :src="getImageSource"
									@click="$refs.file.click()" />
								<img src="@/assets/icons/cross_mark.svg" class="btn-image"
									:class="tempCreationCommonData.image ? 'selected' : ''"
									@click.prevent.stop="clearImage()" />
							</div>
						</div>

						<div v-for="err in errors.image" :key="err" class="errors">{{ err }}</div>
					</div>
				</div>
				<div class="block-group">
					<div class="subtitle">Основные данные</div>
					<!-- Название проекта -->
					<div class="block">
						<label>Логин<span class="form-label-required">*</span></label>
						<sip-input :class="errors.username ? 'red-outline' : ''" type="text"
							v-model.trim="tempCreationCommonData.username" />
						<div v-for="err in errors.username" :key="err" class="errors">{{ err }}</div>
					</div>
					<div class="block">
						<label>Пароль<span class="form-label-required">*</span></label>
						<div class="block-row">
							<sip-input :class="errors.password ? 'red-outline' : ''"
								:type="isButtonEyeActive ? 'text' : 'password'"
								v-model.trim="tempCreationCommonData.password" />
							<img v-if="isButtonEyeActive" class="img-show-pass img-show-pass-on"
								src="@/assets/icons/eye_open.svg" @click="() => (isButtonEyeActive = false)" />
							<img v-else class="img-show-pass img-show-pass-off" src="@/assets/icons/eye_close.svg"
								@click="() => (isButtonEyeActive = true)" />
						</div>
						<div v-for="err in errors.password" :key="err" class="errors">{{ err }}</div>
					</div>
					<div class="block">
						<label>Почта</label>
						<sip-input type="email" v-model.trim="tempCreationCommonData.email" />
						<div v-for="err in errors.email" :key="err" class="errors">{{ err }}</div>
					</div>
				</div>
				<div class="block-group">
					<div class="subtitle">Является ли пользователь руководителем проекта?</div>
					<div class="block-row start">
						<sip-checkbox v-model="tempCreationCommonData.is_project_manager" />
						<label>Да / Нет</label>
						<div v-for="err in errors.is_project_manager" :key="err" class="errors">{{ err }}</div>
					</div>
					<div class="block">
						<label>Цвет</label>
						<sip-input type="color" v-model="tempCreationCommonData.color" />
						<div v-for="err in errors.color" :key="err" class="errors">{{ err }}</div>
					</div>
				</div>
				<div class="block-group" style="margin-bottom: 500px">
					<div class="subtitle">Дополнительные сведения</div>
					<div class="block">
						<label>Имя</label>
						<sip-input type="text" v-model.trim="tempCreationCommonData.first_name" />
						<div v-for="err in errors.first_name" :key="err" class="errors">{{ err }}</div>
					</div>
					<div class="block">
						<label>Фамилия</label>
						<sip-input type="text" v-model.trim="tempCreationCommonData.last_name" />
						<div v-for="err in errors.last_name" :key="err" class="errors">{{ err }}</div>
					</div>
					<div class="block">
						<label>Отчество</label>
						<sip-input type="text" v-model.trim="tempCreationCommonData.second_name" />
						<div v-for="err in errors.second_name" :key="err" class="errors">{{ err }}</div>
					</div>

				</div>
			</div>
			<div class="content-block row" v-if="currentPage == 'areaExpertise'">
				<expertise-page :sectionsArray="sectionsData" :page='"userCreate"' />
				<!-- <div class="table table__wrapper">
					<div class="row row__header">
						<div class="col-1 col__header">#</div>
						<div class="col col__header">Область экспертизы</div>
						<div class="col-2 col__header" style="margin-right: 7px">
							<sip-checkbox :checked="allSelected" @change="changeSelection" />
							<span>{{ allSelected ? "Снять выделение" : "Выбрать все" }}</span>
						</div>
					</div>
					<div class="table__body">
						<div class="row row__content" v-for="(row, rowIndex) in sectionsData" :key="row.id">
							<div class="col-1 col__content">{{ rowIndex + 1 }}</div>
							<div class="col col__content">{{ row.name }}</div>
							<div class="col-2 col__content">
								<sip-checkbox v-model="row.selected" />
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import HeaderComp from "@/components/pages/HeaderComp.vue";
import ExpertisePage from "@/components/pages/components/ExpertisePage.vue";
import { push } from 'notivue'

export default {
	name: "FormUserCreate",
	components: { HeaderComp, ExpertisePage },
	data() {
		return {
			tempCreationCommonData: {
				username: "", // required
				first_name: "",
				last_name: "",
				password: "",
				email: "",
				is_project_manager: false,
				color: "",
				image: null,
			},
			sectionsData: [],
			errors: {},
			isButtonEyeActive: false,

			currentPage: "profile",
			navLinks: [
				{ id: "profile", name: "Профиль пользователя", show: true },
				{ id: "areaExpertise", name: "Области экспертизы", show: true },
			],
			isLoading: false,
		};
	},
	computed: {
		getImageSource() {
			return this.tempCreationCommonData.image || require("@/assets/misc/avatar_empty.jpg");
		},
		allSelected() {
			return this.sectionsData.map(el => el.selected).filter(el => el == true).length == this.sectionsData.length
		},
	},
	methods: {
		changeSelection(e) {
			this.sectionsData.forEach(el => el.selected = e.target.checked)
		},
		handleFileUpload(targetFiles_) {
			if (targetFiles_.length > 0) {
				let fileToLoad = targetFiles_[0];
				let fileReader = new FileReader();
				fileReader.onload = (fileLoadedEvent) => {
					this.setImage(fileLoadedEvent.target.result);
				};
				fileReader.readAsDataURL(fileToLoad);
			}
		},
		setImage(base64data) {
			this.tempCreationCommonData.image = base64data;
		},
		clearImage() {
			this.tempCreationCommonData.image = null;
		},

		checkRequired() {
			if (this.tempCreationCommonData.username == null || this.tempCreationCommonData.username == "") {
				return false;
			}
			if (this.tempCreationCommonData.password == null || this.tempCreationCommonData.password == "") {
				return false;
			}
			return true;
		},
		// Создание проекта
		async createUser() {
			let payload = this.makePayloadObject();
			(payload);

			try {
				await this.$api.user.create(payload);
				push.success(`Пользователь успешно создан!`)
				this.$router.push({ name: "userList" });
			} catch (error) {
				this.errors = {};
				Object.keys(error.response.data).forEach((el) => {
					this.errors[el] = error.response.data[el];
				});
				push.error(`Ошибка создания пользователя!`)
			}
		},
		makePayloadObject() {
			let payload = {};
			const keys = Object.keys(this.tempCreationCommonData);

			// Добавляем связки "Секция-Эксперт"
			let se = [];
			this.sectionsData.forEach((section) => {
				if (section.selected) {
					se.push(section.id);
				}
			});
			if (se.length) {
				payload["sections"] = se;
			}

			// Очищаем объект от нулевых полей
			keys.forEach((key) => {
				if (
					this.tempCreationCommonData[key] != null &&
					this.tempCreationCommonData[key] != "Выберите значение" &&
					this.tempCreationCommonData[key] != ""
				) {
					payload[key] = this.tempCreationCommonData[key];
				}
			});

			return payload;
		},
	},
	async created() {
		try {
			const sections = (await this.$api.question.list("sections")).data;
			sections.forEach((el) => {
				this.sectionsData.push(Object.assign({ selected: false }, el));
			});
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
@charset "utf-8";
@import "@/css/pages/formProjectCreate.scss";
@import "@/css/animation/lists.scss";

.col__header>span {
	font-family: $sf-medium;
	background: none;
}


.col__header:first-child,
.row__header .col__header.sm,
.col__content:first-child,
.col__content.sm {
	max-width: 40px !important;
	justify-content: flex-start !important;
	margin-left: 15px;
}

.block-group {
	max-width: 45%;
	min-width: 25%;
}

.errors {
	color: red;
	font-size: 12px;
}

.red-outline {
	border: 1px solid red;
}

.block-row {
	position: relative;

	&.start {
		justify-content: flex-start;
		align-items: center;
	}
}

.img-show-pass {
	position: absolute;
	right: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 25px;
	transition: all ease 0.2s;
}

.img-show-pass:hover {
	transform: translateY(-1px);
	cursor: pointer;
}

.img-show-pass-off {
	filter: $filter-gray-3;
}

.img-show-pass-on {
	filter: $filter-blue-3;
}

.btn-image {
	display: none;
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(red, 0.5);
	border-radius: 5px;
	padding: 5px;
	transition: opacity 500ms ease;
	cursor: pointer;

	filter: invert(21%) sepia(88%) saturate(6039%) hue-rotate(355deg) brightness(96%) contrast(118%);

	&.selected {
		display: block;
		opacity: 0.1;

		&:hover {
			opacity: 0.5;
		}
	}
}

.figure__wrapper {
	height: 100%;
	gap: 20px;
	transition: all ease 0.2s;
	position: relative;

	&:hover .figure__img {
		opacity: 0.7;
		cursor: pointer;
		border: 2px dashed rgba(gray, 0.5);
	}

	&:hover .figure__img.selected {
		opacity: 1;
		cursor: pointer;
		border: 2px dashed rgba(gray, 0);
	}

	&::before {
		content: "Нажмите на фото, чтобы загрузить новое";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		height: 40px;
		width: 150px;
		font-size: 14px;
		color: black;
		opacity: 0;
		transition: opacity ease 0.2s;
		cursor: pointer;
	}

	&:hover(:has(.btn-image:hover, .selected)) {
		&::before {
			opacity: 0;
		}
	}

	&:hover::before {
		opacity: 1;
	}
}

.content .content-block {
	max-height: none;
}

.figure__img {
	height: 300px;
	width: 300px;
	opacity: 0.5;
	transition: all ease 0.2s;
	border: 2px dashed rgba(gray, 0);
	border-radius: 100%;

	&.selected {
		opacity: 0.8;
	}
}
</style>
