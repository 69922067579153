<template>
    <sip-modal-delete v-model:show="modalDeleteCostVisible" :text="textForDelete"
        @deleteObject="deleteCost(objectIdToDelete)" />
    <sip-modal-delete v-model:show="modalDeleteAllCostVisible" :text="textForDelete"
        @deleteObject="deleteAllCostProfile()" />
    <sip-modal-import-cost-profile v-model:show="modalImportCostProfileVisible" :data="costProfileData"
        @updateCostProfile="updateAllCostProfile" />

    <!-- Форма для добавления нового элемента профиля капитальных вложений -->
    <div class="block-group file-picker" v-if="!expertPermission()">
        <div class="sip-form">
            <div class="btn-sample" @click="getCostProfileTemplate">
                <div class="btn-sample-layout">
                    <img class="img-sample" src="@/assets/buttons/download.svg">
                    <div class="innerText-sample">
                        Скачать шаблон
                    </div>
                </div>
            </div>
            <div class="btn-import" @click="$refs.file.click()">
                <div class="btn-import-layout">
                    <input type="file" accept="text/csv" id="file" ref="file"
                        @change="handleCsvFileUpload($event.target.files, importCostProfilef, Object.keys(this.costProfileNewItem), $event)"
                        style="display: none" placeholder=" Импорт" />
                    <img class="img-import" src="@/assets/buttons/import_map.svg">
                    <div class="innerText-import">
                        Импорт затрат
                    </div>
                </div>
            </div>
            <div class="btn-delete" @click="showDeleteModal('costAll', 'Весь профиль капитальных вложений')"
                :disabled="expertPermission() || costProfile.length == 0">
                <div class="btn-delete-layout">
                    <img class="img-delete" src="@/assets/icons/trash.svg">
                    <div class="innerText-delete">
                        Удалить таблицу
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="table table__wrapper cost">
        <div class="row row__header">
            <div class="cost-year col__header cost" :class="this.currentSortField === 'cost_year' ? 'selected' : ''"
                @click="sortTable('cost_year')">Год
                <img :class="['imm_array_sort', getSortClasses('cost_year')]" src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-measurement-unit col__header cost"
                :class="this.currentSortField === 'cost_measurement_unit' ? 'selected' : ''"
                @click="sortTable('cost_measurement_unit')">Ед. изм.
                <img :class="['imm_array_sort', getSortClasses('cost_measurement_unit')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-oil-gas-exploration col__header cost"
                :class="this.currentSortField === 'cost_oil_gas_exploration' ? 'selected' : ''"
                @click="sortTable('cost_oil_gas_exploration')">ГРР
                <img :class="['imm_array_sort', getSortClasses('cost_oil_gas_exploration')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-preinvestment-study col__header cost"
                :class="this.currentSortField === 'cost_preinvestment_study' ? 'selected' : ''"
                @click="sortTable('cost_preinvestment_study')">Предынвест. исслед.
                <img :class="['imm_array_sort', getSortClasses('cost_preinvestment_study')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-design-survey-work col__header cost"
                :class="this.currentSortField === 'cost_design_survey_work' ? 'selected' : ''"
                @click="sortTable('cost_design_survey_work')">ПИР
                <img :class="['imm_array_sort', getSortClasses('cost_design_survey_work')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-drilling col__header cost"
                :class="this.currentSortField === 'cost_drilling' ? 'selected' : ''"
                @click="sortTable('cost_drilling')">Бурение
                <img :class="['imm_array_sort', getSortClasses('cost_drilling')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-construction col__header cost"
                :class="this.currentSortField === 'cost_construction' ? 'selected' : ''"
                @click="sortTable('cost_construction')">Обустройство
                <img :class="['imm_array_sort', getSortClasses('cost_construction')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="cost-delete col__header"></div>
        </div>
        <div class="table__body">
            <transition-group name="list">
                <div class="row row__content" v-for="(row) in costProfileFiltered" :key="row.id">
                    <div class="cost-year col__content">
                        <sip-input class="input__table short" type="number" v-model.trim="row.year" name="year"
                            @blur="getInputCostProfileValue($event, row.id)"
                            @focus="saveInitialNumberValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-measurement-unit col__content">
                        <sip-select :isNullAllowed="true" class="input__table short select" name="measurement_unit"
                            :options="unitOptions" @change="getSelectCostProfileValue($event, row.id)"
                            v-model="row.measurement_unit" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-oil-gas-exploration col__content">
                        <sip-input class="input__table short" name="oil_gas_exploration" type="number"
                            :value="Number(row.oil_gas_exploration).toFixed(1) / 1"
                            @blur="getInputCostProfileValue($event, row.id)"
                            @focus="saveInitialNumberValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-preinvestment-study col__content">
                        <sip-input name="preinvestment_study" type="number"
                            :value="Number(row.preinvestment_study).toFixed(1) / 1" class="input__table short"
                            @focus="saveInitialNumberValue($event, row.id)"
                            @blur="getInputCostProfileValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-design-survey-work col__content">
                        <sip-input name="design_survey_work" type="number"
                            :value="Number(row.design_survey_work).toFixed(1) / 1" class="input__table short"
                            @focus="saveInitialNumberValue($event, row.id)"
                            @blur="getInputCostProfileValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-drilling col__content">
                        <sip-input name="drilling" type="number" :value="Number(row.drilling).toFixed(1) / 1"
                            class="input__table short" @focus="saveInitialNumberValue($event, row.id)"
                            @blur="getInputCostProfileValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-construction col__content">
                        <sip-input name="construction" type="number" :value="Number(row.construction).toFixed(1) / 1"
                            class="input__table short" @focus="saveInitialNumberValue($event, row.id)"
                            @blur="getInputCostProfileValue($event, row.id)" :disabled="expertPermission()" />
                    </div>
                    <div class="cost-delete col__content">
                        <a class="table__menu__btns delete" :hidden="expertPermission()"
                            @click="showDeleteModal('cost', row.year, row.id)">
                            <img src="@/assets/icons/trash.svg" :class="!expertPermission() ? 'gray' : ''" /></a>
                    </div>
                </div>
            </transition-group>
            <div class="row row__create" v-if="!expertPermission()">
                <div class="cost-year col__content">
                    <sip-input class="input__table short" type="number" v-model.number="costProfileNewItem.year"
                        :placeholder="'год'" :disabled="expertPermission()" />
                </div>
                <div class="cost-measurement-unit col__content">
                    <sip-select class="select__table short select" :options="unitOptions" :placeholder="'ед.изм.'"
                        v-model="costProfileNewItem.measurement_unit" :disabled="expertPermission()" />
                </div>
                <div class="cost-oil-gas-exploration col__content">
                    <sip-input class="input__table short" type="number"
                        v-model.number="costProfileNewItem.oil_gas_exploration" :placeholder="'знач.'"
                        :disabled="expertPermission()" />
                </div>
                <div class="cost-preinvestment-study col__content">
                    <sip-input class="input__table short" type="number" :placeholder="'знач.'"
                        v-model.number="costProfileNewItem.preinvestment_study" :disabled="expertPermission()" />
                </div>
                <div class="cost-design-survey-work col__content">
                    <sip-input class="input__table short" type="number" :placeholder="'знач.'"
                        v-model.number="costProfileNewItem.design_survey_work" :disabled="expertPermission()" />
                </div>
                <div class="cost-drilling col__content">
                    <sip-input class="input__table short" type="number" :placeholder="'знач.'"
                        v-model.number="costProfileNewItem.drilling" :disabled="expertPermission()" />
                </div>
                <div class="cost-construction col__content">
                    <sip-input class="input__table short" type="number" :placeholder="'знач.'"
                        v-model.number="costProfileNewItem.construction" :disabled="expertPermission()" />
                </div>
                <div class="cost-delete col__content">
                    <a class="table__menu__btns" @click="createCost()">
                        <img src="@/assets/icons/plus.svg"
                            :class="costProfileNewItem.year !== null ? 'blue' : 'blue-unselect'" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { Modal } from "bootstrap";
import { measurementUnits } from "@/database/utils";
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { mPermissions } from "@/utils/permissions";
import { push } from 'notivue'


export default {
    name: "CapexPage",
    mixins: [mDate, mFile, mPermissions, mFilters],
    props: {
        costData: {
            type: Object,
            default: null,
        },
        unitOptions: {
            type: Object,
            default: null,
        },
        isTempResult: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            costProfile: this.costData,
            isLoading: false,
            measurementUnits: Object.keys(measurementUnits),
            costProfileNewItem: {
                year: null,
                measurement_unit: null,
                oil_gas_exploration: null,
                preinvestment_study: null,
                design_survey_work: null,
                drilling: null,
                construction: null,
            },
            modalDeleteCostVisible: false,
            modalDeleteAllCostVisible: false,
            modalImportCostProfileVisible: false,
            objectIdToDelete: null,
            textForDelete: "",
            tempInputNumberValue: null,
            isFirstRowAHead: true,
            csvSeparator: ";",
            costProfileData: null,
            sortDirection: 1,
            currentSortField: 'cost_year',
        };
    },
    computed: {
        //  #region - Блок работы с фильтрацией

        /** Функция фильтрации таблицы  */
        costProfileFiltered() {
            let data = this.costProfile;
            (this.costProfile);

            if (this.currentSortField.startsWith("cost_")) {
                let sortField = this.currentSortField.replace('cost_', '')
                data.sort((a, b) => {
                    let a_ = 0;
                    let b_ = 0;
                    const MINIMAL_VALUE = -1000000;
                    if ([
                        'year',
                        'oil_gas_exploration',
                        'preinvestment_study',
                        'design_survey_work',
                        'drilling',
                        'construction',
                    ].includes(sortField)) {
                        a_ = parseFloat(a[sortField]);
                        b_ = parseFloat(b[sortField]);
                        if (isNaN(a_)) a_ = MINIMAL_VALUE;
                        if (isNaN(b_)) b_ = MINIMAL_VALUE;
                        return (a_ - b_) * this.sortDirection;
                    } else {
                        a_ = a[sortField];
                        b_ = b[sortField];
                        if (a_ == b_) return 1;
                        let comp = a_ < b_ ? -1 : 1;
                        return comp * this.sortDirection;
                    }
                });
            }
            return data
        },
        //#endregion
    },
    methods: {
        /** Загрузчик файла для редактирование проекта */
        async handleCostProfileUpload(e) {
            if (!e.target.files || e.target.files.length === 0) return;
            const file = e.target.files[0];
            const reader = new FileReader();
            e.target.value = null;
            reader.onload = (e) => {
                const content = e.target.result;
                let rows = content
                    .split("\n")
                    .map((row) => row.trim())
                    .filter((row) => row != "");
                let data = [];
                if (this.isFirstRowAHead) {
                    let heads = rows[0].split(this.csvSeparator);
                    rows.splice(0, 1);
                    rows.forEach((row) => {
                        let row_splitted = row.split(this.csvSeparator);
                        data.push(Object.fromEntries(heads.map((_, i) => [heads[i], row_splitted[i].replace(',', '.')])));
                    });
                } else {
                    data = rows.map((row) => {
                        return row.split(this.csvSeparator);
                    });
                }
                this.modalImportCostProfileVisible = true;
                this.costProfileData = data;
            };
            reader.readAsText(file);
            e.target.value = null;
        },

        // [-]
        /** Загрузчик файла для добавление проекта  */
        async handleCsvFileUpload(targetFiles_, callback_, headsRequired = [], $event) {
            if (!this.isTempResult) {
                this.handleCostProfileUpload($event)
                return
            }
            if (!targetFiles_ || targetFiles_.length === 0) return;
            let headsRequired_ = JSON.stringify(headsRequired);
            const file = targetFiles_[0];
            const reader = new FileReader();
            const dateRegexp = /^\d{2}.\d{2}.\d{4}$/;
            const dateFromFormat = "DD.MM.YYYY";
            const dateToFormat = "YYYY-MM-DD";
            reader.onload = (e) => {
                const content = e.target.result;
                let rows = content
                    .split("\n")
                    .map((row) => row.trim())
                    .filter((row) => row != "");
                let data = [];
                if (this.isFirstRowAHead) {
                    let heads = rows[0].split(this.csvSeparator);
                    rows.splice(0, 1);
                    rows.forEach((row) => {
                        let row_splitted = row.split(this.csvSeparator);
                        let tmp = Object.fromEntries(heads.map((_, i) => [heads[i], row_splitted[i].replace(",", ".")]));
                        data.push(tmp);
                    });
                    data.forEach((row, indx) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                        row["id"] = indx;
                    });

                    try {
                        if (JSON.stringify(heads) != headsRequired_) {
                            throw 'Структура файла не соответствует требуемой.'
                        }
                    } catch (err) {
                        push.error(`Ошибка загрузки файлов!`)
                        return
                    }
                } else {
                    data = rows.map((row) => {
                        return row.split(this.csvSeparator); // Удаляем пробелы и символы возврата каретки в каждом столбце
                    });
                    data.forEach((row, indx) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                        row["id"] = indx;
                    });
                }
                callback_(data);
            };
            reader.readAsText(file);
            $event.target.value = null;
        },

        // [-]
        /** Функция ... даты  */
        formatDatesAndSetProject(data, dateFields) {
            const formatter = new Intl.DateTimeFormat("en-ca", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
            const dateFormat = "DD.MM.YYYY";
            const projectId = this.$route.params.id_proj;

            data.forEach(el => {
                dateFields.forEach(field => {
                    const date = moment(el[field], dateFormat);
                    if (date.isValid()) {
                        el[field] = formatter.format(date.toDate());
                    }
                });
                el.project = projectId;
            });
        },

        /** Удаление всего массива капитальных вложений  */
        async deleteAllCostProfile() {
            if (this.isTempResult) {
                this.costProfile = [];
                push.success("Элементы профиля капитальных вложений удалены успешно!");
                this.$emit('update:tempCreationCostProfileData', this.costProfile)
                return
            }
            const data = { project: this.$route.params.id_proj };
            await this.handleWithModal(async () => {
                await this.$api.sip.deleteCostProfile(data);
                push.success("Элементы профиля капитальных вложений удалены успешно!");
                this.costProfile = [];
                this.$emit('update:costProfile', this.costProfile)
            });
        },

        /** Подфункция импорта  */
        async importCostProfilef(origin_data_) {
            if (origin_data_) Array.prototype.push.apply(this.costProfile, origin_data_);
        },

        /** Функция показа модального окна  */
        showDeleteModal(modalType_, objectName_, objectId_ = null) {
            let user = JSON.parse(localStorage.getItem("user"));
            if (!user.is_project_manager & !user.is_admin) return;

            switch (modalType_) {
                case "cost":
                    this.modalDeleteCostVisible = true;
                    break;
                case "costAll":
                    this.modalDeleteAllCostVisible = true;
                    break;
            }

            this.textForDelete = `Вы уверены, что хотите удалить ${objectName_}?`;
            this.objectIdToDelete = objectId_;
        },

        // [-]
        /** Добавление спиннера  */
        async handleWithModal(action) {
            const spinner = document.getElementById("spinner");
            if (!spinner) {
                console.error("Modal element does not exist in the DOM.");
                return;
            }

            const pendingModal = new Modal(spinner);
            try {
                pendingModal.show();
                await action();
            } catch (error) {
                console.error(error);
                push.error("Произошла ошибка при выполнении операции!");
            } finally {
                pendingModal.hide();
            }
        },

        /** Полное обновление таблицы  */
        updateAllCostProfile(costProfile_) {
            if (costProfile_) {
                this.costProfile = [...costProfile_]
                if (this.isTempResult) {
                    this.$emit('update:tempCreationCostProfileData', this.costProfile)
                } else {
                    this.$emit('update:costProfile', this.costProfile)
                }

            }
        },

        saveInitialNumberValue(event_, id_) {
            if (!this.isTempResult) {
                const val = this.costProfileFiltered.find(
                    el => el.id == id_)[event_.target.name];
                (this.costProfileFiltered);
                this.tempInputNumberValue = val;
                event_.target.value = val;
            } else {
                this.tempInputNumberValue = event_.target.value
            }
        },


        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },

        /**  Запись введенного числа в строке */
        getInputCostProfileValue(event, id_) {
            const val = parseFloat(event.target.value);

            if (this.isNumeric(this.tempInputNumberValue)) {
                if (Number(this.tempInputNumberValue).toFixed(1) == val) return;
            }
            event.target.value = val == 0 ? 0 : val.toFixed(1) / 1;
            if (this.tempInputNumberValue == val) return;

            if (!this.isTempResult) {
                const field = event.target.name;
                this.updateCostProfile(field, val, id_).then(() => {
                    this.costProfileFiltered.find(
                        el => el.id == id_)[field] = val
                })
            } else {
                const indx = this.costProfileFiltered.findIndex(
                    el => el.id == id_);
                this.costProfile[indx][event.target.name] = val
                this.$emit('update:tempCreationCostProfileData', this.costProfile)
            }
        },

        /**  Обновление единиц измерения в строке */
        getSelectCostProfileValue(event, id_) {
            let field = "measurement_unit";
            if (!this.isTempResult) this.updateCostProfile(field, event.target.value, id_);

        },

        /**  Добавление постоянного объекта в таблицу капитальных вложений */
        addCostProfileItem(object_) {
            this.costProfile = [...this.costProfile, object_];
            this.costProfileNewItem = {
                year: null,
                measurement_unit: null,
                oil_gas_exploration: null,
                preinvestment_study: null,
                design_survey_work: null,
                drilling: null,
                construction: null,
            };
        },

        // [-]
        /** Функция скачивания шаблона  */
        async getCostProfileTemplate() {
            try {
                this.$store.dispatch("project/getTemplate", 'cost-profiles').then((response) => {
                    this.forceDownload(
                        response,
                        'cost_profile_template.csv',
                        "", false, true
                    );
                });
            } catch (error) {
                push.error(`Ошибка выгрузки данных!`)
            }
        },

        /** Создание элемента капитальных вложений  */
        async createCost() {
            if (this.costProfileNewItem.year == null) return
            if (this.isTempResult) {
                this.addCostProfileTempItem()
                return
            }
            let payload = {};
            Object.keys(this.costProfileNewItem).forEach((key) => {
                if (this.costProfileNewItem[key] != null && this.costProfileNewItem[key] != "") {
                    payload[key] = this.costProfileNewItem[key];
                }
            });
            payload["project"] = this.$route.params.id_proj;
            try {
                const data = (await this.$api.sip.create("costs", payload)).data;
                this.addCostProfileItem(data);
            } catch (error) {
                push.error(`Ошибка создания таблицы капитальных вложений!`)
            }
            this.$emit('update:costProfile', this.costProfile)
        },

        // [-]
        /** Обновление массива  */
        updateArray(array, id) {
            return array.filter((el) => el.id !== id);
        },

        // [-]
        /** Общая функция удаление элемента капитальных вложений  */
        async deleteItem(id, type, arrayName, successMessage, errorMessage) {
            try {
                await this.$api.sip.delete(id, type);
                this[arrayName] = this.updateArray(this[arrayName], id);
                this.$emit('update:costProfile', this[arrayName])
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        // [-]
        /** Триггер функция удаления элемента капитальных вложений  */
        async deleteCost(id) {
            if (this.isTempResult) {
                this.costProfile = [...this.costProfile.filter((el) => el.id != id)];
                this.$emit('update:tempCreationCostProfileData', this.costProfile)
                return
            }
            await this.deleteItem(
                id,
                "costs",
                "costProfile",
                "Элемент таблицы капитальных вложений успешно удален!",
                "Ошибка удаления элемента таблицы капитальных вложений!"
            );
        },


        /** Добавление новой строчки в таблице  */
        addCostProfileTempItem() {
            let emptyItem = {
                id: Date.now(),
                year: this.costProfileNewItem.year,
                measurement_unit: this.costProfileNewItem.measurement_unit,
                oil_gas_exploration: this.costProfileNewItem.oil_gas_exploration,
                preinvestment_study: this.costProfileNewItem.preinvestment_study,
                design_survey_work: this.costProfileNewItem.design_survey_work,
                drilling: this.costProfileNewItem.drilling,
                construction: this.costProfileNewItem.construction,
            };
            this.costProfile = [...this.costProfile, emptyItem];
            this.costProfileNewItem = {
                year: null,
                measurement_unit: "млн",
                oil_gas_exploration: null,
                preinvestment_study: null,
                design_survey_work: null,
                drilling: null,
                construction: null,
            };
            this.$emit('update:tempCreationCostProfileData', this.costProfile)
        },

        // [-]
        /** Общая функция для обновления объектов  */
        async updateObject(apiSection, objectId, updates, successMessage, errorMessage) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };

            try {
                await this.$store.dispatch("project/patchObject", payload);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        // [-]
        /** Обновление "Капитальные вложения"  */
        async updateCostProfile(field_, value_, itemId_) {
            await this.updateObject(
                "costs",
                itemId_,
                { [field_]: value_ },
                "Значение капитальных вложений успешно обновлено!",
                "Ошибка обновления капитальных вложений!"
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.table__wrapper {
    width: 64%;
    min-width: 30vw;
}

.sip-form>.btn-file {
    width: 110px;
}

.sip-form {
    gap: 10px;
    justify-content: flex-start;
}

.btn-file,
.btn-image {
    font-size: 13px !important;
    width: max-content;
}

.select {
    &:hover {
        background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
        background-position-x: calc(100% - 15px) !important;
    }
}

.select__table:has(> option[value=null]:checked) {
    background: url("@/assets/icons/select_arrow_blue.svg") no-repeat right !important;
    background-position-x: calc(100% - 15px) !important;
    color: #2F9ADB !important;
}

.input__table {
    font-size: 13px;

    &::placeholder {
        font-size: 13px;
        color: #2F9ADB;
        font-family: "sf-pro-display-medium";
    }

    &.short {
        max-width: fit-content !important;
        width: 75% !important;
    }

    &input[type=date]::placeholder {
        color: #2f99db8a;
    }
}

.select__table {
    font-size: 13px;

    &.short {
        width: 75% !important;
    }
}

.row__create {
    background-color: #E8F6FF;

    &:hover {
        background-color: #E8F6FF;
    }

    select,
    input {
        color: #0c69a2;
    }

    input[type=date] {
        color: #2F9ADB;
    }

    &.duration {
        color: #2F9ADB;
    }

    input[type=date]::-webkit-calendar-picker-indicator {
        filter: invert(51%) sepia(99%) saturate(381%) hue-rotate(161deg) brightness(87%) contrast(96%);
    }
}

input[type=date] {
    font-family: "sf-pro-display-medium";
}

.row.row__content.row__disabled {
    background: $color-gray-0;

    & .col__content {
        color: $color-gray-3;
    }

    & .col__content {
        color: $color-gray-3;
    }
}

.file-picker {
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.6%;
    position: relative;
}

.col__header {
    padding: 10px;
}

.content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    z-index: 3;
    position: relative;
}

.block>.text {
    font-family: $sf-light;
    font-size: 12px;

    &>span.bold {
        font-family: $sf-bold;
    }
}

.content-block {
    padding: 0 15px;
    overflow-y: hidden !important;
}


.content-block.row {
    gap: 20px;
}

.table__body {
    height: 62vh;
    width: calc(100% + 7px);
}

.table__menu__btns .red,
.gray {
    cursor: default;
    width: 23px;
}

input {
    background-color: rgba(255, 255, 255, 0.5);
}

.btn-delete[disabled="true"] {
    background-color: #fe615980;
}

::-webkit-scrollbar {
    position: absolute;
    width: 7px;
}

.blue-unselect {
    cursor: default;
}
</style>
