<template>
    <select v-model="innerValue">
        <option disabled value="">Выберите значение</option>
        <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.first_name[0] }}. {{ option.last_name }}
        </option>
    </select>
</template>

<script>
export default {
    name: 'sip-select-user',
    emits: ['update:modelValue'],
    data() {
        return {
            innerValue: this.modelValue,
        };
    },
    watch: {
        innerValue(newValue) {
            this.$emit('update:modelValue', newValue)
        },
        modelValue(newValue) {
            this.innerValue = newValue;
        }
    },
    props: {
        modelValue: {
            type: Number,
            default: null
        },
        options: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/css/components/select.scss';
</style>