<template>
	<Transition name="fade">
		<div class="header" v-if="!isLoading">
			<ul class="nav nav-tabs">
				<li class="nav-item" v-for="item in navItems" :key="item.id" :class="item.show ? '' : 'disabled'">
					<a class="nav-link" :class="currentPage === item.id ? 'active' : ''" :style="fontStyle"
						@click.prevent="setCurrentPage(item)" href="#">
						{{ item.name }}
					</a>
					<Transition name="bounce">
						<div v-if="currentPage === item.id" class="selected-divider"></div>
					</Transition>
				</li>
				<li class="filters__container" v-if="showSearch">
					<sip-input class="search-filter" :class="{ focus: localSearchQuery.length != 0 ? true : false }"
						v-model="localSearchQuery" placeholder="Поиск" />
				</li>
				<li class="btn__container" v-if="showBtn">
					<slot name="buttons"></slot>
				</li>
			</ul>
		</div>
	</Transition>
</template>

<script>
export default {
	name: "HeaderComp",
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		navItems: {
			type: Array,
			default: () => [],
		},
		currentPage: {
			type: String,
			default: "",
		},
		showSearch: {
			type: Boolean,
			default: false,
		},
		showBtn: {
			type: Boolean,
			default: false,
		},
		searchQuery: {
			type: String,
			default: "",
		},
		showNavItem: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["changePage", "update:searchQuery"],
	data() {
		return {
			localSearchQuery: this.searchQuery,
		};
	},
	watch: {
		searchQuery(newVal) {
			this.localSearchQuery = newVal;
		},
		localSearchQuery(newVal) {
			this.$emit("update:searchQuery", newVal);
		},
	},
	methods: {
		setCurrentPage(page) {
			if (!page.show) return;
			this.$emit("changePage", page.id);
		},
	},
	computed: {
		fontStyle() {
			return {
				fontFamily: this.navItems.length > 1 ? "sf-pro-display-regular" : "sf-pro-display-light",
				cursor: this.navItems.length > 1 ? "pointer" : "default",
				pointerEvents: this.navItems.length > 1 ? "all" : "none",
			}
		}
	},
	// mounted() {
	// 	(this.isLoading, this.navItems, this.showSearch, this.searchQuery);
	// }
};

</script>

<style lang="scss" scoped>
@import "@/css/general.scss";
@import "@/css/pages/tableQuestion.scss";
@import "@/css/animation/lists.scss";

.btn__container {
	gap: 3px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	align-items: flex-end;
}

.nav-item:not(:first-child)::after {
	content: "";
	display: inline-block;
	height: 20px;
	width: 1px;
	background-color: #afafaf75;
	position: absolute;
	left: -2.1em;
	top: 4px;
}

.page {
	height: 100%;
}

.nav {
	--bs-nav-link-padding-y: 0rem;
	--bs-nav-link-padding-x: 0rem;
}

.header {
	height: 66px;
}

.nav-tabs {
	border-bottom: none;
	gap: 4em;
	// padding-bottom: 2.5rem;
}

.nav-item {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;

}

.nav-tabs .nav-link {
	border: none;
	font-size: clamp(12px, 1.2vw, 16px);

	&:hover {
		opacity: 70%;
	}
}

.divider {
	height: 20px;
	width: 1px;
	background-color: #afafaf75;
}

.selected-divider {
	height: 2px;
	width: 20px;
	background-color: #363639;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}

.bounce-leave-active {
	animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

.search-filter {
	width: 40px;
	height: 35px;
	padding: 0;
	background: none;
	font-family: $sf-light;
	font-size: 15px;
	color: #363639;

	border: none;
	border-radius: 0;
	border-bottom: 1px solid #363639;

	transition: all ease-in-out 0.3s;

	&::placeholder {
		color: #363639;
		transition: all ease-in-out 0.3s;
	}

	&:hover {
		background: none;
	}

	&:hover::placeholder,
	&:focus::placeholder {
		color: $color-gray-4;
	}

	&.focus,
	&:focus {
		outline: none;
		border: none;
		background: none;
		border-bottom: 1px solid #363639;
		width: 200px;
	}
}

.disabled a {
	color: #afafaf42;
}
</style>
