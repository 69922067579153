<template>
    <div @mousemove.self="getCoursorPosition($event)" class="gantt-block" :style="setGanttBlockStyle()">
        <ul @mousemove.self="closeTooltip($event)" class="gantt-block-tooltip" :style="setTooltipStyle">
            <li>Дата начала: {{ row.start_date }}</li>
            <li>Дата окончания: {{ row.end_date }}</li>
        </ul>
    </div>
</template>


<script>
export default {
    name: 'GanttBlock',
    data() {
        return {
            topCursor: 0,
            leftCursor: 0,
        }
    },
    props: {
        row: Object,
        index: Number,
        ganttChart: Object,
        contentWidth: Number,
        ganttCursorPositionX: Number,
    },
    methods: {
        setGanttBlockStyle() {
            let duration = (Date.parse(this.row.end_date) - Date.parse(this.row.start_date)) / 86400000 * 100 / this.ganttChart.durationDays;
            let dataStartPercent = (Date.parse(this.row.start_date) / 86400000 - this.ganttChart.startingDay) * 100 / this.ganttChart.durationDays;
            return {
                width: `${duration}%`,
                marginLeft: `${dataStartPercent}%`,
            }
        },
        getCoursorPosition(e_) {
            let rect = e_.target.getBoundingClientRect();
            this.topCursor = e_.clientY - rect.top - 30;

            let leftPartWidth = this.contentWidth * 30 / 100;
            let availableWidth = this.contentWidth - leftPartWidth;

            let toolTipWidth = document.getElementsByClassName('gantt-block-tooltip')[0];
            toolTipWidth = window.getComputedStyle(toolTipWidth, null).getPropertyValue('width');
            toolTipWidth = Number(toolTipWidth.slice(0, toolTipWidth.indexOf("px")));

            if (this.ganttCursorPositionX <= leftPartWidth + availableWidth / 2) {
                this.leftCursor = e_.clientX - rect.left + 10;
            } else {
                this.leftCursor = e_.clientX - rect.left - (toolTipWidth + 20);
            }
        },
        closeTooltip(e_) {
            this.topCursor = e_.clientY;
            this.leftCursor = e_.clientX;
        },
    },
    computed: {
        setTooltipStyle() {
            return {
                top: `${this.topCursor}px`,
                left: `${this.leftCursor}px`,
                position: `fixed`,
            }
        },
    },
    mounted() {
        this.leftCursor = 0;
        this.topCursor = 0;
    },
}
</script>


<style lang="scss" scoped>
@import '@/css/pages/pageAnalytics.scss';
</style>