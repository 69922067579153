const projectCreatePayload = {
    gantt_chart: [],
    section_experts: [],
    name: "",
    description: "",
    purpose: "",
    planned_duration: null,
    actual_duration: null,
    capital_cost: null,
    security_cost: null,
    net_present_value: null,
    internal_rate_of_return: null,
    profit_to_investment_ratio: null,
    gas_flowrate: null,
    team_size: null,
    phase: "",
    group: null,
    stage: null,
    type: null,
    first_driver: null,
    second_driver: null,
    third_driver: null
}


const importedCreatingData = [
    {
        name: 'block1',
        title: 'Название проекта',
        field: 'name',
        required: true,
        value: null,
        id: null,
    },
    {
        name: 'block2',
        title: 'Объект/актив',
        field: 'type',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block3',
        title: 'Этап проекта',
        field: 'stage',
        required: true,
        value: null,
        id: null,
    },
    {
        name: 'block4',
        title: 'Фаза проекта',
        field: 'phase',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block5',
        title: 'Цель проекта',
        field: 'purpose',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block6',
        title: 'Характеристики проекта', // ????????///////////////////////////////////////////////////////////////
        field: 'description',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block7',
        title: 'Срок плановый, мес',
        field: 'planned_duration',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block8',
        title: 'Кап. расходы Q/Capex, тыс.руб.',
        field: 'capital_cost',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block9',
        title: 'Затраты на безопасность, тыс.руб.',
        field: 'security_cost',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block10',
        title: 'Срок фактический, мес',
        field: 'actual_duration',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block11',
        title: 'Добыча, млрд.м3',
        field: 'gas_flowrate',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block12',
        title: 'Человек в команде',
        field: 'team_size',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block13',
        title: 'Главный',
        field: 'first_driver',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block14',
        title: 'Второстепенный',
        field: 'second_driver',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block15',
        title: 'Третьестепенный',
        field: 'third_driver',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block16',
        title: 'NPV, млн.руб.',
        field: 'net_present_value',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block17',
        title: 'IRR, %',
        field: 'internal_rate_of_return',
        required: false,
        value: null,
        id: null,
    },
    {
        name: 'block18',
        title: 'PI',
        field: 'profit_to_investment_ratio',
        required: false,
        value: null,
        id: null,
    },
]


const fileTypes = [
    'Подземная часть',
    'Наземная часть',
    'План реализации проекта',
    'Бизнес-кейс',
]


const filesTemp = [
    'Файл 1',
    'Файл 2',
    'Файл 3',
    'Файл 4',
]


export {
    projectCreatePayload,
    importedCreatingData,
    fileTypes,
    filesTemp,
}