import api from "@/api"
import { push } from 'notivue'
export default {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem('user')) || null,
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getUserId(state) {
            return state.user.id
        },
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload;
        },

        CLEAR_IMAGE(state) {
            if (Object.hasOwn(state.user, 'image')) {
                state.user.image = null;
                let user = JSON.parse(localStorage.getItem('user'));
                user.image = null;
                localStorage.setItem('user', JSON.stringify(user));
            }
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('SET_USER', payload)
        },
        fetchUser: async (context) => {
            try {
                let data = (await api.user.getMe()).data;
                context.commit('SET_USER', data)
            } catch (err) {
                push.error(`Ошибка загрузки данных пользователя!`)
            }
        },
        fetchUsers: async () => {
            try {
                let data = (await api.user.list()).data;
                return data
            } catch (err) {
                push.error(`Ошибка загрузки данных пользователей!`)
            }
        },
    }
}