<template>
	<div class="tb-background">
		<sip-modal-delete v-model:show="modalDeleteAllGanttVisible" :text="textForDelete"
			@deleteObject="deleteAllGantt()" />
		<HeaderComp :navItems="navLinks" :currentPage="currentPage" @changePage="(page) => (currentPage = page)"
			:showBtn="true" :showSearch="false">
			<template v-slot:buttons>
				<div class="btn-add" @click="createProject()" :disabled="checkRequired() ? false : true">
					<div class="btn-add-layout">
						<img class="img-add" src="@/assets/icons/plus.svg">
						<div class="innerText-add">
							Создать проект
						</div>
					</div>
				</div>
				<div class='project_ready_info'>
					<img :class="!checkRequired() ? 'mark-color-red' : 'mark-color-green'" :src="getImageMark" />
					<div class='project_ready_info_text'>
						<div :class="!checkRequired() ? 'font-color-red' : 'font-color-green'">
							{{ countCheckedRequired() }}
						</div>
						<span> / 4 выполнено </span>
					</div>
					<img class=" img_middle_buttons pink_info" src="@/assets/icons/info.svg"
						@mouseenter="showLegend = true" @mouseleave="showLegend = false" />
				</div>
			</template>
		</HeaderComp>
		<div class="content">
			<transition name="fade">
				<div class="tooltip-container" v-if="showLegend == true">
					<div class="subtitle">Условия создания проекта</div>
					<div class="tooltip-content-block" v-for="item in tooltipItems" :key="item.key">
						<div class="verification-element">
							<div :class="getStatus(item.key) ? 'verification-element__done' : ''">{{ item.label }}</div>
						</div>
						<div class="status">
							<img class="img_middle_buttons" :src="getImageSource(item.key)"
								:style="setStausImgColor(item.key)" />
						</div>
					</div>
				</div>
			</transition>
			<div class="content-block commons-page" v-if="currentPage == 'commons'">
				<commons-page :isTempCreation=true :commonsData="tempCreationCommonData"
					:finExpenses="tempCreationCostProfileData" :projectStage="stagesObjArray"
					:objectType="typesObjArray" :creatingDataArray="creatingData" :isNullAllowed="true" />
			</div>
			<div class="content-block row" v-if="currentPage == 'sections'">

				<expertise-page :sectionsArray="sectionsObjArrayFiltered" :page='"projectCreate"'
					:creatingDataArray="creatingData" :projectStage="stagesObjArray"
					:commonsData="tempCreationCommonData"
					:tempCreationSectionExpertsData="tempCreationSectionExpertsData" />

				<!-- <div class="block">
					<label>Этап проекта<span class="form-label-required"
							v-if="creatingData[0].required">*</span></label>
					<sip-select v-model="tempCreationCommonData.stage" :options="stagesObjArray"
						@change="updateSectionObjArrayFiltered" required />
				</div>
				<div class="table table__wrapper">
					<div class="table table__wrapper" v-if="tempCreationCommonData.stage">
						<div class="row row__header">
							<div class="col-1 col__header">
								<sip-checkbox @change="e => e.target.checked ? selectAll() : unselectAll()" />
							</div>
							<div class="col col__header">Область экспертизы</div>
							<div class="col col__header">Эксперт</div>
						</div>
						<div class="table__body" style="max-height: 55vh">
							<div class="row row__content" v-for="row in sectionsObjArrayFiltered" :key="row.id"
								:class="checkAndGetSectionUserBGClass(row)" :disabled="!row.selected">
								<div class="col-1 col__content">
									<sip-checkbox v-model="row.selected" :modelValue="row.selected" />
								</div>
								<div class="col col__content">{{ row.name }}</div>
								<div class="col col__content" style="margin-left:40px">
									<sip-avatar v-if="row.user" :user="row.user" :style="{ marginRight: '15px' }"
										:size="30" />
									<sip-select style="padding-right:40px; width: auto;" :isNullAllowed="true"
										class="select__table" :options="getSelectOptionsFromUser(row.users)"
										:disabled="!row.selected" v-model="tempCreationSectionExpertsData[row.id]"
										@change="showUserImage($event, row)" />
								</div>
							</div>
						</div>
					</div>
				</div> -->
			</div>
			<div class="content-block row" v-if="currentPage == 'roadmap'">
				<road-map-page @update:tempCreationGanttChartData="updateGanttChartData"
					:gantChartData="tempCreationGanttChartData" :isTempResult="true" />
			</div>
			<div class="content-block row" v-if="currentPage == 'capex'">
				<capex-page @update:tempCreationCostProfileData="updateCostProfileData"
					:costData="tempCreationCostProfileData" :unitOptions="measurementUnitOptions"
					:isTempResult="true" />
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { importedCreatingData } from "@/database/projectCreateTemplate.js";
import { measurementUnits } from "@/database/utils";
import { mPermissions } from "@/utils/permissions";
import { mFilters } from "@/utils/filters";
import { mText } from "@/utils/text";
import { push } from 'notivue'

import CapexPage from "@/components/pages/components/CapexPage.vue";
import CommonsPage from "@/components/pages/components/CommonsPage.vue";
import RoadMapPage from "@/components/pages/components/RoadMapPage.vue";
import ExpertisePage from "@/components/pages/components/ExpertisePage.vue";

export default {
	name: "FormProjectCreate",
	mixins: [mPermissions, mFilters, mText],
	components: { HeaderComp, CapexPage, CommonsPage, RoadMapPage, ExpertisePage },
	data() {
		return {
			navLinks: [
				{ id: "commons", name: "Общие сведения", show: true },
				{ id: "sections", name: "Области экспертизы", show: true },
				{ id: "roadmap", name: "Дорожная карта", show: true },
				{ id: "capex", name: "Капитальные вложения", show: true },
			],
			tooltipItems: [
				{ key: "name", label: "Название проекта" },
				{ key: "stage", label: "Этап проекта" },
				{ key: "region", label: "Область экспертизы" },
				{ key: "expert", label: "Выбор эксперта" },
			],
			tempCreationCommonData: {
				name: "", // required
				description: "",
				purpose: "",
				phase: "",
				conclusion: "",
				group: null,
				stage: null, // required
				type: null,
				actual_duration: null,
				planned_duration: null,
				gas_flowrate: null,
				net_present_value: null,
				internal_rate_of_return: null,
				profit_to_investment_ratio: null,
				capital_cost: null,
				security_cost: null,
				team_size: null,
				first_driver: null,
				second_driver: null,
				third_driver: null,
				section_experts: [],
				gantt_chart: [],
			},
			tempCreationSectionExpertsData: {},
			tempCreationGanttChartData: [],
			tempCreationGanttChartItem: {
				name: "",
				start_date: null,
				end_date: null,
			},
			tempCreationCostProfileData: [],
			tempCreationCostProfileItem: {
				year: null,
				measurement_unit: "млн",
				oil_gas_exploration: null,
				preinvestment_study: null,
				design_survey_work: null,
				drilling: null,
				construction: null,
			},
			currentPage: "commons",
			showLegend: false,
			creatingData: [],
			payload: {},
			projectFileNewItem: null,
			tempFileArray: [],
			typesObjArray: [],
			stagesObjArray: [],
			sectionsObjArray: [],
			sectionsObjArrayFiltered: [],
			usersObjArray: [],
			measurementUnits: Object.keys(measurementUnits),
			measurementUnitOptions: [],
			// Флаги для модалок
			modalDeleteAllGanttVisible: false,
			// Параметры для удаления айтемов
			objectIdToDelete: null,
			textForDelete: "",
			isFirstRowAHead: true,
			csvSeparator: ";",
			sortDirection: 1,
			currentSortField: "",
		};
	},
	watch: {
		'tempCreationCommonData.stage'() {
			this.clearSelection();
		}
	},
	computed: {
		ganttChartFiltered() {
			let data = this.tempCreationGanttChartData;
			if (this.currentSortField.startsWith("gantt_")) {
				let sortField = this.currentSortField.replace("gantt_", "");
				data.sort((a, b) => {
					let a_ = 0;
					let b_ = 0;
					if (["start_date", "end_date"].includes(sortField)) {
						a_ = new Date(a[sortField]);
						b_ = new Date(b[sortField]);
						return (a_ - b_) * this.sortDirection;
					} else {
						a_ = a[sortField];
						b_ = b[sortField];
						if (a_ == b_) return 1;
						let comp = a_ < b_ ? -1 : 1;
						return comp * this.sortDirection;
					}
				});
			}
			return data;
		},
		getImageBtn() {
			return !this.checkRequired() ? require("@/assets/icons/info.svg") : require("@/assets/icons/check_mark_with_border.svg");
		},
		getImageMark() {
			return !this.checkRequired() ? require("@/assets/icons/cross_mark.svg") : require("@/assets/icons/check_mark.svg");
		},
		statusImageName() {
			return this.tempCreationCommonData.name == "";
		},
		statusImageStage() {
			return !this.tempCreationCommonData.stage;
		},
		statusImageRegion() {
			let sectionsLength = this.sectionsObjArrayFiltered.filter((el) => el.selected == true);
			return sectionsLength.length == 0;
		},
		statusImageExpert() {
			let sectionsLength = this.sectionsObjArrayFiltered.filter((el) => el.selected == true);
			let tempCreationSectionExpertsIds = [];
			sectionsLength.forEach((section) => {
				tempCreationSectionExpertsIds.push(this.tempCreationSectionExpertsData[section.id] == null);
			});
			if (tempCreationSectionExpertsIds.length !== 0) {
				return tempCreationSectionExpertsIds.includes(true);
			}
			return true;
		},
	},
	methods: {
		updateCostProfileData(newData) {
			this.tempCreationCostProfileData = newData
		},
		updateGanttChartData(newData) {
			this.tempCreationGanttChartData = newData
		},
		showUserImage(event, row) {
			let tmp = row.users.find((user) => user.id === Number(event.target.value))
			let gg = this.sectionsObjArrayFiltered.findIndex((sectionsID) => sectionsID.id === row.id)
			this.sectionsObjArrayFiltered[gg] = { ...this.sectionsObjArrayFiltered[gg], user: tmp }
			return tmp
		},

		getImageSource(key) {
			const statusProperties = {
				name: 'statusImageName',
				stage: 'statusImageStage',
				region: 'statusImageRegion',
				expert: 'statusImageExpert',
			};
			const imageSources = {
				true: require("@/assets/icons/cross_mark_with_border.svg"),
				false: require("@/assets/icons/check_mark_with_border.svg"),
			};
			const statusProperty = statusProperties[key];
			const imageSource = this[statusProperty] ? imageSources.true : imageSources.false;
			return imageSource;
		},

		setStausImgColor(key) {
			const statusProperties = {
				name: 'statusImageName',
				stage: 'statusImageStage',
				region: 'statusImageRegion',
				expert: 'statusImageExpert',
			};
			const filterValues = {
				true: "invert(50%) sepia(69%) saturate(539%) hue-rotate(71deg) brightness(95%) contrast(89%)",
				false: "invert(99%) sepia(0%) saturate(15%) hue-rotate(343deg) brightness(88%) contrast(91%)",
			};
			const statusProperty = statusProperties[key];
			const filterTrue = this[statusProperty] ? filterValues.false : filterValues.true;
			return {
				filter: filterTrue,
			};
		},
		getStatus(key) {
			const statusImages = {
				name: this.statusImageName,
				stage: this.statusImageStage,
				region: this.statusImageRegion,
				expert: this.statusImageExpert
			};

			return !statusImages[key] || false;
		},

		showDeleteModal(modalType_, objectName_, objectId_ = null) {
			switch (modalType_) {
				case "ganttAll":
					this.modalDeleteAllGanttVisible = true;
					break;
			}
			this.textForDelete = `Вы уверены, что хотите удалить ${objectName_}?`;
			this.objectIdToDelete = objectId_;
		},

		async handleCsvFileUpload(targetFiles_, callback_, headsRequired = []) {
			if (!targetFiles_ || targetFiles_.length === 0) return;
			let headsRequired_ = JSON.stringify(headsRequired);
			const file = targetFiles_[0];
			const reader = new FileReader();
			const dateRegexp = /^\d{2}.\d{2}.\d{4}$/;
			const dateFromFormat = "DD.MM.YYYY";
			const dateToFormat = "YYYY-MM-DD";

			reader.onload = (e) => {
				const content = e.target.result;
				let rows = content
					.split("\n")
					.map((row) => row.trim())
					.filter((row) => row != "");
				let data = [];
				if (this.isFirstRowAHead) {
					let heads = rows[0].split(this.csvSeparator);
					rows.splice(0, 1);
					rows.forEach((row) => {
						let row_splitted = row.split(this.csvSeparator);
						let tmp = Object.fromEntries(heads.map((_, i) => [heads[i], row_splitted[i].replace(",", ".")]));
						data.push(tmp);
					});
					data.forEach((row, indx) => {
						Object.keys(row).forEach((k) => {
							if (row[k].match(dateRegexp)) {
								row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
							}
						});
						row["id"] = indx;
					});

					try {
						if (JSON.stringify(heads) != headsRequired_) {
							throw 'Структура файла не соответствует требуемой.'
						}
					} catch (err) {
						push.error(`Ошибка загрузки файлов!`)
						return
					}
				} else {
					data = rows.map((row) => {
						return row.split(this.csvSeparator); // Удаляем пробелы и символы возврата каретки в каждом столбце
					});
					data.forEach((row, indx) => {
						Object.keys(row).forEach((k) => {
							if (row[k].match(dateRegexp)) {
								row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
							}
						});
						row["id"] = indx;
					});
				}
				callback_(data);
			};
			reader.readAsText(file);
		},

		importGantt(origin_data_) {
			if (origin_data_) Array.prototype.push.apply(this.tempCreationGanttChartData, origin_data_);
		},
		deleteAllGantt() {
			this.tempCreationGanttChartData = [];
		},

		updateSectionObjArrayFiltered(e) {
			this.sectionsObjArrayFiltered = this.sectionsObjArray.filter((el) => {
				return Object.values(el.stages).includes(Number(e.target.value));
			});
		},
		getSelectOptionsFromUser(users_) {
			let options = [];
			users_.forEach((el) => {
				options.push({
					id: el.id,
					name: this.getFullName(el),
				});
			});
			return options;
		},


		addGanttChartItem() {
			let emptyItem = {
				id: Date.now(),
				name: this.tempCreationGanttChartItem.name,
				start_date: this.tempCreationGanttChartItem.start_date,
				end_date: this.tempCreationGanttChartItem.end_date,
			};
			this.tempCreationGanttChartData = [...this.tempCreationGanttChartData, emptyItem];
			this.tempCreationGanttChartItem = {
				name: "",
				start_date: null,
				end_date: null,
			};
		},

		deleteGanttChartItem(id_) {
			this.tempCreationGanttChartData = [...this.tempCreationGanttChartData.filter((el) => el.id != id_)];
		},

		selectAll() {
			this.sectionsObjArray.forEach((el) => (el.selected = true));
		},
		unselectAll() {
			this.sectionsObjArray.forEach((el) => (el.selected = false));
		},
		countCheckedRequired() {
			let counter = 0
			if (this.tempCreationCommonData.name !== null && this.tempCreationCommonData.name !== "") {
				counter += 1
			}
			if (this.tempCreationCommonData.stage) {
				counter += 1
			}
			let sectionsLength = this.sectionsObjArrayFiltered.filter((el) => el.selected == true);
			if (sectionsLength.length !== 0) {
				counter += 1
			}
			let tempCreationSectionExpertsIds = [];
			sectionsLength.forEach((section) => {
				tempCreationSectionExpertsIds.push(this.tempCreationSectionExpertsData[section.id] == null);
			});
			if (tempCreationSectionExpertsIds.includes(false)) {
				counter += 1
			}
			return counter;
		},
		clearSelection() {
			this.sectionsObjArray.forEach((el) => {
				el.selected = false;
			});
			Object.keys(this.tempCreationSectionExpertsData).forEach(
				(k) => this.tempCreationSectionExpertsData[k] = null);
		},
		checkRequired() {
			if (this.tempCreationCommonData.name == null || this.tempCreationCommonData.name == "") {
				return false;
			}
			if (!this.tempCreationCommonData.stage) {
				return false;
			}
			let sectionsLength = this.sectionsObjArrayFiltered.filter((el) => el.selected == true);
			if (sectionsLength.length == 0) {
				return false;
			}
			let tempCreationSectionExpertsIds = [];
			sectionsLength.forEach((section) => {
				tempCreationSectionExpertsIds.push(this.tempCreationSectionExpertsData[section.id] == null);
			});
			if (tempCreationSectionExpertsIds.includes(true)) {
				return false;
			}
			return true;
		},

		// Создание проекта
		async createProject() {
			let payload = this.makePayloadObject();
			try {
				this.$store.dispatch("project/createProject", payload).then((response) => {
					localStorage.setItem("projectName", response.name);
					this.$router.push({ name: "project", params: { id_proj: response.id } });
				});
			} catch (error) {
				push.error(`Ошибка создания проекта!`)
			}
		},

		makePayloadObject() {
			let payload = {};
			const keys = Object.keys(this.tempCreationCommonData);

			// Добавляем связки "Секция-Эксперт"
			let se = [];
			this.sectionsObjArrayFiltered.forEach((section) => {
				if (section.selected) {
					se.push({ section: section.id, expert: this.tempCreationSectionExpertsData[section.id] });
				}
			});
			payload["section_experts"] = se;

			// Добавляем связки "Дорожная карта"
			let rm = [];
			this.tempCreationGanttChartData.forEach((item) => {
				rm.push({ name: item.name, start_date: item.start_date, end_date: item.end_date });
			});
			payload["gantt_chart"] = rm;

			// Добавляем связки "Капитальные вложения"
			let cp = [];
			(this.tempCreationCostProfileData)
			this.tempCreationCostProfileData.forEach((item) => {
				cp.push({
					year: this.isNumeric(item.year) ? Number(item.year) : 0,
					oil_gas_exploration: this.isNumeric(item.oil_gas_exploration) ? Number(item.oil_gas_exploration) : 0,
					preinvestment_study: this.isNumeric(item.preinvestment_study) ? Number(item.preinvestment_study) : 0,
					design_survey_work: this.isNumeric(item.design_survey_work) ? Number(item.design_survey_work) : 0,
					drilling: this.isNumeric(item.drilling) ? Number(item.drilling) : 0,
					construction: this.isNumeric(item.construction) ? Number(item.construction) : 0,
				});
			});
			payload["cost_profile"] = cp;

			// Очищаем объект от нулевых полей
			keys.forEach((key) => {
				if (
					this.tempCreationCommonData[key] != null &&
					this.tempCreationCommonData[key] != "Выберите значение" &&
					this.tempCreationCommonData[key] != ""
				) {
					payload[key] = this.tempCreationCommonData[key];
				}
			});

			return payload;
		},
		createSectionList(sections, users) {
			let sectionsObjArray = []
			sections.forEach((section) => {
				let userSections = {
					users: users.filter((user) => {
						let s_ids = [];
						if (user.sections) {
							user.sections.forEach((el) => s_ids.push(el));
							return s_ids.includes(section.id);
						}
					}),
				};
				userSections.users = userSections.users.sort((a, b) => { return (a.last_name <= b.last_name ? -1 : 1) })
				let item = Object.assign(userSections, { selected: false }, section);
				sectionsObjArray.push(item);
				this.tempCreationSectionExpertsData[section.id] = null;
			});
			this.sectionsObjArray = sectionsObjArray.toSorted((a, b) => (a.order - b.order))
			this.sectionsObjArrayFiltered = [...this.sectionsObjArray];
		},
		isNumeric(str) {
			if (typeof str != "string") return false // we only process strings!  
			return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
				!isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
		}
	},
	async created() {
		this.creatingData = JSON.parse(JSON.stringify(importedCreatingData));
		try {
			this.$store.dispatch("project/fetchList", "types").then((response) => {
				this.typesObjArray = response.map((el) => {
					return { name: el.name, id: Number(el.id) };
				});
				(this.typesObjArray);

			});
			this.$store.dispatch("project/fetchQuestionsList", "stages").then((response) => {
				this.stagesObjArray = response.map((el) => {
					return { name: el.name, id: Number(el.id) };
				});
			});
			let users;
			this.$store.dispatch("user/fetchUsers").then((response) => {
				this.usersObjArray = response.map((el) => {
					return { name: `${el.first_name} ${el.last_name}`, id: Number(el.id) };
				});
				users = response;
				return this.$store.dispatch("project/fetchQuestionsList", "sections").then((response) => {
					this.createSectionList(response, users);
				});
			});
			Object.entries(measurementUnits).forEach((el) => {
				this.measurementUnitOptions.push({
					id: el[1],
					name: el[0],
				});
			});
		} catch (error) {
			console.log(error.response);
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/pages/formProjectCreate.scss";
@import "@/css/animation/lists.scss";
@import "@/css/checkbox.scss";

.block {
	width: 15%;
}

.sections__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.content-block {
	padding: 0 15px;
	overflow-y: hidden !important;

	&.commons-page {
		align-items: center;
		padding: 0;
	}
}

.content {
	margin-top: 15px;
}

.table__body {
	height: 57vh;
}

.table__menu__btns .blue {
	width: 18px;
}

.table__menu__btns .red {
	width: 18px;
}

.col__header {
	padding: 10px;
}

.subtitle {
	font-family: $sf-regular;
	font-size: 14px;
	width: 100%;
	padding-bottom: 0px;
	margin-bottom: 15px;
}

.content {
	margin-top: 15px;
	padding-left: 0px;
	padding-right: 0px;
}


.sip-form>.btn-file {
	width: 110px;
}

.sip-form {
	gap: 10px;
	justify-content: flex-end;
}

.content-block.row {
	gap: 20px;
}

.sip-form-project {
	gap: 10px;
	justify-content: flex-start;
}

.btn-file,
.btn-image {
	font-size: 13px !important;
}


.tooltip-container {
	position: absolute;
	// top: -50px;
	right: 0px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	background-color: rgba(white, 1);
	box-shadow: 0px 20px 20px 0px rgba(black, 0.2);
	border-radius: 5px;
	transition: all ease-in-out 500ms;
	animation: appearing;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	width: auto;
	padding: 20px;
	align-content: center;
	height: auto;
	gap: 10px;

	& .subtitle {
		font-size: 12px;
		font-family: $sf-medium;
	}

	& .tooltip-content-block {
		width: 100%;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		gap: 20px;
	}

	& .verification-element {
		font-size: 14px;
		width: 100%;

		& .verification-element__done {
			text-decoration: line-through;
			color: $color-gray-3;
		}
	}
}

.status {
	display: flex;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.img_middle_buttons {
	cursor: pointer;
	height: 17px;
	display: flex;
	justify-content: center;
}

.pink_info {
	filter: brightness(0) saturate(100%) invert(76%) sepia(7%) saturate(33%) hue-rotate(188deg) brightness(94%) contrast(88%);
}

input[type=checkbox] {
	&:focus {
		outline: none;
		background-color: $color-blue-1;
	}
}

.project_ready_info {
	display: flex;
	align-items: center;
	color: #AFAFB9;
	gap: 8px;
	width: 180px;
	justify-content: flex-end;
}

.project_ready_info_text {
	display: flex;
	font-family: "sf-pro-display-regular";
	font-size: 13px;

}

.project_ready_info_text>div {
	margin-right: 3px;
}

.mark-color-red {
	filter: brightness(0) saturate(100%) invert(75%) sepia(70%) saturate(6584%) hue-rotate(331deg) brightness(118%) contrast(99%);
}

.mark-color-green {
	filter: brightness(0) saturate(100%) invert(67%) sepia(19%) saturate(1866%) hue-rotate(70deg) brightness(79%) contrast(89%);
}
</style>
