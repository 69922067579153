<template>
	<button class="btn">
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: "sip-button",
};
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';
@import '@/css/colors/cps.scss';

.btn {
	background: none;
	border: none;
	border-radius: 2px;
	background: none;
	background-color: $color-gray-1;
	padding: 5px 10px;
	font-size: 13px;
	min-width: 70px;
	// width: fit-content;
	max-width: 200px;
	// height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all ease 200ms;

	&:focus {
		opacity: 1;
	}

	&:hover {
		opacity: 0.9;
	}

	&[disabled] {
		opacity: 0.5;
	}

	&[disabled]:hover {
		opacity: 0.5;
	}
}

.btn img {
	scale: 70%;
}


.btn-big {
	font-size: 18px;
}

.btn-small {
	font-size: 13px;
}


.bg-red {
	background-color: $color-red-1;
	color: $color-white;
}

.bg-yellow {
	background-color: $color-yellow-1;
}

.bg-blue {
	background-color: $color-blue-6;
	color: $color-white;
}

.bg-light-blue {
	border-radius: 0px;
	background-color: #2F9ADB;
	color: $color-white;
}

.bg-blue img {
	filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.bg-green {
	background-color: #1ABC72;
	color: $color-white;
}

.bg-gray {
	background-color: $color-gray-2;
}

.btn-transparent {
	border: none;
	outline: none;
	background: transparent;
}
</style>
