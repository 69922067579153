<template>
    <div class="block-group" style="max-width: 100%; gap: 30px">
        <div class="block">
            <label style="font-size: 13px;">Текст заключения</label>
            <sip-table-textarea class="textarea__detail" v-if="!expertPermission()" name="conclusion" type="text"
                v-model="conclusionData.conclusion" @blur="getInputValue($event, conclusionData.conclusion)"
                @focus="saveInitialValue($event.target.value)" :disabled="expertPermission()" style="width: 50%;" />
            <div v-else-if="conclusionData.is_conclusion_published">
                {{ conclusionData.conclusion }}
            </div>
            <no-content-inline v-else :message="`Не предоставлено`" />
        </div>
        <div class="block" style="justify-content: flex-start;  padding-left: 10px" v-if="!expertPermission()">
            <div class="text">
                Заключение
                <span class="bold">
                    {{ conclusionData.is_conclusion_published ? "опубликовано" : "не опубликовано" }}
                </span>
            </div>
            <label class="switch">
                <input :checked="conclusionData.is_conclusion_published" type="checkbox" @change="changePublishedStatus"
                    :disabled="expertPermission()" />
                <span class="slider round"></span>
            </label>
        </div>
        <div class="block" style="padding-top: 15px; padding-left: 10px ">
            <div class="text">
                Данное заключение руководителя проекта будет опубликовано в
            </div>
            <div class="text">
                соответствующем разделе экрана “Аналитика”.
            </div>
        </div>
    </div>
</template>

<script>
import { mPermissions } from "@/utils/permissions";
import { push } from "notivue";

export default {
    mixins: [mPermissions],
    props: {
        APIdata: {
            type: Object,
            default: null,
        },
        isProject: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            conclusionData: this.APIdata,
            tempInputValue: null,
        };
    },
    methods: {
        getInputValue(event) {
            if (this.tempInputValue == event.target.value) {
                return;
            }

            let field = event.target.name;
            let value = event.target.value;

            this.updateCommonProjectData(field, value);
            if (field == "name") {
                localStorage.setItem("projectName", value);
            }
        },

        /** Обновление "Общие сведения"  */
        async updateCommonProjectData(field_, value_) {
            const objectId = this.isProject !== false ? this.$route.params.id_proj : this.conclusionData.id
            let payload = {};
            payload["apiSection"] = this.isProject !== false ? "projects" : "sections"
            payload["objectId"] = objectId;
            payload[field_] = value_;
            try {
                this.$store.dispatch("project/patchObject", payload);
                push.success(`Заключение успешно обновлено!`)
            } catch (error) {
                push.error(`Ошибка обновления параметра секции!`)
            }
        },

        //#end
        /** Общая функция для обновления объектов  */
        async updateObject(apiSection, objectId, updates, successMessage, errorMessage) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };

            try {
                await this.$store.dispatch("project/patchObject", payload);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        /** Сохранение временного значения  */
        saveInitialValue(value_) {
            this.tempInputValue = value_;
        },

        /** Изменение статуса публикации  */
        changePublishedStatus() {
            if (this.conclusionData.is_conclusion_published) {
                this.conclusionData.is_conclusion_published = false;
                this.updateCommonProjectData("is_conclusion_published", false);
            } else {
                this.conclusionData.is_conclusion_published = true;
                this.updateCommonProjectData("is_conclusion_published", true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.textarea__detail {
    border: 1px solid rgba(#004269, 0.28) !important;
    border-radius: 0px !important;
    min-width: 55% !important;
    min-height: 300px !important;
    max-height: 55vh !important;
    background: transparent !important;
    overflow-y: auto !important;
}

.content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    z-index: 3;
    position: relative;
}

.block>.text {
    font-family: $sf-light;
    font-size: 13px;

    &>span.bold {
        font-family: $sf-bold;
    }

    &.gray {
        color: #9C9C9C;
    }
}

input {
    background-color: rgba(255, 255, 255, 0.5);
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 17px;
    margin-top: 7px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e5e8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 0;
    // bottom: 4px;
    background-color: #b3b8ba;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #c2dff1;
}

input:focus+.slider {
    box-shadow: 0 0 1px #c2dff1;
}

input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
    background-color: #2f9adb;
}

/* Rounded sliders */
.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
