<template>
    <div id="app" class="parent-page">

        <router-view />
        <notifications group="app" position="top right" />
        <notifications group="auth" position="top right" />
        <modal-spinner ref="modal"></modal-spinner>
        <Notivue v-slot="item">
            <Notification :item="item" />
        </Notivue>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { Notivue } from 'notivue'
import { Notification } from 'notivue'

export default {
    name: 'App',
    components: { Notivue, Notification },
    data() {
        return {
            screenWidth: 0,
            screenHeight: 0,
        }
    },
    watch: {
        screenWidth(value_) {
            this.setScreenWidth(value_)
        },
        screenHeight(value_) {
            this.setScreenHeight(value_)
        },
    },
    methods: {
        ...mapActions({
            setScreenWidth: 'domprops/setScreenWidth',
            setScreenHeight: 'domprops/setScreenHeight',
        }),
        updateWidth() {
            this.screenWidth = window.innerWidth;
        },
        updateHeight() {
            this.screenHeight = window.innerHeight;
        },
    },
    created() {
        // слушаем события изменения экрана
        window.addEventListener('resize', this.updateWidth); 
        window.addEventListener('resize', this.updateHeight);    
    },
    mounted() {
        this.screenHeight = window.innerHeight;
    }
}
</script>


<style lang="scss" scoped>
@import '@/css/general.scss';
@import '@/css/customStyleNotivue.scss';

.parent-page {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    background-color: rgb(239, 243, 246);

    & ::selection {
        background: $color-blue-3;
        color: $color-white;
    }
}
</style>
