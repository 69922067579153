<template>
  <div class="tb-background" :style="setCanvasStyle">
    <!-- Прелоадер -->
    <Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>

    <!-- Хеадер -->
    <HeaderComp :isLoading="isLoading" :navItems="navLinks" />

    <!-- Блок с фильтрами ("Блоков с информацией") -->
    <div class="board">
      <transition name="buttons_opacity_trns">
        <div v-if="isFiltersShown" class="board_full_style">
          <div class="inner_block">
            <div class="board_item" v-for="item in checkboxItems1" :key="item.id">
              <input type="checkbox" class="btn-check" :id="item.id" autocomplete="off" :checked="item.model"
                @input="toggleItem(item)" />
              <label class="filter_button" :class="!item.isChecked ? 'button_checked' : ''" :for="item.id">
                {{ item.label }}
              </label>
            </div>
          </div>
          <div class="inner_block">
            <div class="board_item" v-for="item in checkboxItems2" :key="item.id">
              <input type="checkbox" class="btn-check" :id="item.id" autocomplete="off" :checked="item.model"
                @input="toggleItem(item)" />
              <label class="filter_button" :class="!item.isChecked ? 'button_checked' : ''" :for="item.id">
                {{ item.label }}
              </label>
            </div>
          </div>
        </div>
      </transition>

      <transition name="divider_opacity_trns">
        <div class="divider small_divider" v-if="isFiltersShown"></div>
      </transition>

      <div class="left_part" @click="openFiltersBar">
        <img class="arrow" src="@/assets/icons/arrow_light.svg" :style="setArrowStyle" />
        <div class="block_info_label">
          Блоков с информацией ({{ checkboxItemsCount }} /
          {{ checkboxItemsAll }})
        </div>
      </div>

      <div class="divider small_divider"></div>

      <div class="button__pdf">
        <img id="btndownload" class="button__pdf__icon" src="@/assets/buttons/download_pdf.svg" type="button"
          @click="downloadPDF" />
      </div>
    </div>

    <!-- Блок с основной информацией -->
    <Transition name="fade">
      <div class="content" v-if="APIData && projectData" :style="setCanvasContentStyle">
        <!-- Строка 1: Основное; Драйверы; Бизнес-кейс; Другое -->
        <div v-if="showCommons || showDrivers || showBusiness || showOthers" class="content_row">
          <!-- Блок 1 - "Сведения о проекте" -->
          <sip-card v-if="checkCommons && showCommons" :title="'Основные сведения о проекте'">
            <div class="card_body_inner">
              <div class="block">
                <label class="info_label">Тип актива</label>
                <div class="info_content" v-if="projectData.type">
                  {{ projectData.type }}
                </div>
                <div class="info_content" v-else>{{ noValuesText }}</div>
              </div>
              <div class="block">
                <label class="info_label">Фаза проекта</label>
                <div class="info_content" v-if="projectData.phase">
                  {{ projectData.phase }}
                </div>
                <div class="info_content" v-else>{{ noValuesText }}</div>
              </div>
              <div class="block">
                <label class="info_label">Цели проекта</label>
                <div class="info_content" v-if="projectData.purpose" disabled name="purpose" type="text"
                  style="width: 90%">
                  {{ projectData.purpose }}
                </div>
                <div class="info_content" v-else>{{ noValuesText }}</div>
              </div>
              <div class="block">
                <label class="info_label">Краткая характеристика</label>
                <div class="info_content" v-if="projectData.description" disabled name="description" type="text"
                  style="width: 90%">
                  {{ projectData.description }}
                </div>
                <div class="info_content" v-else>{{ noValuesText }}</div>
              </div>
            </div>

            <div class="card_body_inner inner_right">
              <div class="right_side">
                <label class="info_label text_right">Этап проекта</label>
                <div class="info_content">{{ projectData.stage }}</div>
              </div>
              <div class="right_side">
                <label class="info_label text_right">Сроки (план/факт), мес.
                </label>
                <div class="info_block_duratrion">
                  <div>
                    <div class="info_stat" v-if="projectData.planned_duration">
                      {{ projectData.planned_duration }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>

                  <div class="info_stat_divider">/</div>

                  <div>
                    <div class="info_stat" v-if="projectData.actual_duration">
                      {{ projectData.actual_duration }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>
                </div>
              </div>
              <div class="right_side">
                <label class="info_label text_right">Индекс готовности проекта</label>
                <div class="index_gip_chart">
                  <apexchart height="200" width="130" type="radialBar" :options="indexGIPOptions"
                    :series="indexGIPOptions.series" />
                </div>
              </div>
            </div>
          </sip-card>

          <div v-if="
            checkCommons &&
            showCommons &&
            ((checkDrivers && showDrivers) ||
              (checkBusiness && showBusiness) ||
              (checkOthers && showOthers))
          " class="divider_general">
            <div class="divider big_divider"></div>
          </div>

          <!-- Блоки 2, 3, 4... -->
          <div class="card_group" :class="checkCommons && showCommons ? '' : 'card_fullwidth'" v-if="
            (checkDrivers && showDrivers) ||
            (checkBusiness && showBusiness) ||
            (checkOthers && showOthers)
          ">
            <div class="card_row">
              <div class="card_drivers" :class="checkBusiness && showBusiness ? '' : 'card_fullwidth'">
                <sip-card v-if="checkDrivers && showDrivers" :title="'Драйверы проекта'">
                  <div class="card_body_inner">
                    <div>
                      <label class="info_label">Главный драйвер</label>
                      <div class="info_content" v-if="projectData.first_driver">
                        {{ projectData.first_driver }}
                      </div>
                      <div class="info_content" v-else>{{ noValuesText }}</div>
                    </div>
                    <div>
                      <label class="info_label">Второстепенный драйвер</label>
                      <div class="info_content" v-if="projectData.second_driver">
                        {{ projectData.second_driver }}
                      </div>
                      <div class="info_content" v-else>{{ noValuesText }}</div>
                    </div>
                    <div>
                      <label class="info_label">Третий драйвер по приоритету</label>
                      <div class="info_content" v-if="projectData.third_driver">
                        {{ projectData.third_driver }}
                      </div>
                      <div class="info_content" v-else>{{ noValuesText }}</div>
                    </div>
                  </div>
                </sip-card>
              </div>

              <div v-if="
                checkDrivers && showDrivers && checkBusiness && showBusiness
              " class="divider_general">
                <div class="divider big_divider"></div>
              </div>

              <!-- Блок 3 - "Показатели бизнес-кейса" -->
              <div class="card_business" :class="checkDrivers && showDrivers ? '' : 'card_fullwidth'">
                <sip-card v-if="checkBusiness && showBusiness" :title="'Показатели бизнес-кейса'">
                  <div class="card_body_inner">
                    <div class="colmplicated_cell">
                      <div>
                        <label class="info_label">Чистый дисконтированный доход</label>
                        <div class="info_content">NPV, млн. руб.</div>
                      </div>
                      <div>
                        <div class="info_stat" v-if="projectData.net_present_value">
                          {{
                            rewriteToWholeNumber(projectData.net_present_value)
                          }}
                        </div>
                        <div class="info_aux" v-else>-</div>
                      </div>
                    </div>
                    <div class="colmplicated_cell">
                      <div>
                        <label class="info_label">Внутренняя норма доходности</label>
                        <div class="info_content">IRR, %</div>
                      </div>
                      <div>
                        <div class="info_stat" v-if="projectData.internal_rate_of_return">
                          {{
                            rewriteDotToComma(
                              projectData.internal_rate_of_return
                            )
                          }}
                        </div>
                        <div class="info_aux" v-else>-</div>
                      </div>
                    </div>
                    <div class="colmplicated_cell">
                      <div>
                        <label class="info_label">Индекс прибыльности</label>
                        <div class="info_content">PI</div>
                      </div>
                      <div>
                        <div class="info_stat" v-if="projectData.profit_to_investment_ratio">
                          {{
                            rewriteDotToComma(
                              projectData.profit_to_investment_ratio
                            )
                          }}
                        </div>
                        <div class="info_aux" v-else>-</div>
                      </div>
                    </div>
                  </div>
                </sip-card>
              </div>
            </div>

            <!-- Блок 4 - "Другие показатели" -->
            <sip-card v-if="checkOthers && showOthers" :title="'Другие показатели'">
              <div class="others_cell_group">
                <div class="colmplicated_cell">
                  <div>
                    <label class="info_label">Капитальные расходы</label>
                    <div class="info_content">Capex, млрд. руб.</div>
                  </div>
                  <div>
                    <div class="info_stat" v-if="projectData.cost_profile.length != 0">
                      {{ rewriteToWholeNumber(summaryCapitalCost) }}
                    </div>
                    <div class="info_stat" v-else-if="projectData.capital_cost">
                      {{ rewriteToWholeNumber(projectData.capital_cost) }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>
                </div>

                <div class="divider others_divider"></div>
                <div class="colmplicated_cell">
                  <div>
                    <label class="info_label">Добыча</label>
                    <div class="info_content">Q, млрд. м3.</div>
                  </div>
                  <div>
                    <div class="info_stat" v-if="projectData.gas_flowrate">
                      {{ projectData.gas_flowrate }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>
                </div>

                <div class="divider others_divider"></div>
                <div class="colmplicated_cell">
                  <div>
                    <label class="info_label">Охрана</label>
                    <div class="info_content">P, тыс. руб.</div>
                  </div>
                  <div>
                    <div class="info_stat" v-if="projectData.security_cost">
                      {{ projectData.security_cost }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>
                </div>

                <div class="divider others_divider"></div>
                <div class="colmplicated_cell">
                  <div>
                    <label class="info_label">Численность команды</label>
                    <div class="info_content">N, чел.</div>
                  </div>
                  <div>
                    <div class="info_stat" v-if="projectData.team_size">
                      {{ projectData.team_size }}
                    </div>
                    <div class="info_aux" v-else>-</div>
                  </div>
                </div>
              </div>
            </sip-card>
          </div>
        </div>

        <!-- Строка 2: Группы ГИП; Проработка -->
        <div v-if="showGroups || showSections" class="content_row">
          <!-- Блок 5 - "Группы ГИП (Аналитические индексы интеграции проекта)" -->
          <sip-card v-if="checkGroups && showGroups" :title="'Группы ГИП (Аналитические индексы интеграции проекта)'">
            <div class="gip_group_container">
              <div v-for="group in indexGIPOptionsDetails" :key="group.groupId">
                <div v-if="group.series[0]" class="gip_group">
                  <div class="gip_column">
                    <div class="gip_group_label">{{ group.name }}</div>
                    <apexchart height="200" width="130" type="radialBar" :options="group" :series="group.series" />
                    <div class="gip_group_content">
                      <div v-for="rb in linearBarOptions" :key="rb.chart.id">
                        <div v-if="getGroupIds(rb.chart.id, group.groupId)" class="gip_block">
                          <div class="gip_group_label label_progress_addit">
                            {{ correctChartNames(rb.name) }}
                          </div>
                          <sip-progress :value="rewriteToWholeNumber(rb.series[0])" :width="65" :height="6"
                            :showPercent="true" :type="'analytics'" :justifyContent="'space-between'"
                            :percentFontSize="20" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="drawGipDivider(indexGIPOptionsDetails, group)" class="divider gip_divider" :class="drawGipDivider(indexGIPOptionsDetails, group)
                      ? 'gip_divider_margin_right'
                      : ''
                    "></div>
                </div>
              </div>
            </div>
          </sip-card>

          <div v-if="checkGroups && showGroups && checkSections && showSections" class="divider_general">
            <div class="divider big_divider"></div>
          </div>

          <!-- Блок 6 - "Полнота проработки по областям" -->
          <div v-if="checkSections && showSections" :class="checkGroups && showGroups && checkSections && showSections
              ? 'right_cards'
              : 'card_fullwidth'
            ">
            <sip-card :title="'Полнота проработки по областям'">
              <apexchart width="500" height="500" type="radar" :options="sectionOptions"
                :series="sectionOptions.series" />
            </sip-card>
          </div>
        </div>

        <!-- Строка 3: Динамика капитала; Характеристики ГИП -->
        <div v-if="showCapex || showCharacteristics" class="content_row">
          <!-- Блок 7 - "Динамика капитала" -->
          <!-- <sip-card v-if="checkCapex && showCapex" :title="'Динамика капитала' {{ costUnits }}"> -->
          <sip-card v-if="checkCapex && showCapex" :title="`Динамика капитала, ${costUnits}`">
            <div class="chart_container">
              <apexchart width="850" height="400" type="bar" :options="investmentOptions"
                :series="investmentOptions.series" />
            </div>
          </sip-card>

          <div v-if="
            checkCapex &&
            showCapex &&
            checkCharacteristics &&
            showCharacteristics
          " class="divider_general">
            <div class="divider big_divider"></div>
          </div>

          <!-- Блок 8 - "Характеристики методологии ГИП" -->
          <div v-if="checkCharacteristics && showCharacteristics" :class="checkCapex &&
              showCapex &&
              checkCharacteristics &&
              showCharacteristics
              ? 'right_cards'
              : 'card_fullwidth'
            ">
            <sip-card :title="'Характеристики методологии ГИП'">
              <apexchart height="500" width="500" type="radar" :options="characteristicOptions"
                :series="characteristicOptions.series" />
            </sip-card>
          </div>
        </div>

        <!-- Строка 4: График инвестиций; Заключение -->
        <div v-if="showInvestments || showConclusions" class="content_row">
          <!-- Блок 9 - "График инвестиций" -->
          <sip-card v-if="checkInvestments && showInvestments" :title="`График инвестиций (накопл.), ${costUnits}`">
            <div class="chart_container">
              <apexchart width="850" height="400" type="area" :options="capexOptions" :series="capexOptions.series" />
            </div>
          </sip-card>

          <div v-if="
            checkInvestments &&
            showInvestments &&
            checkConclusions &&
            showConclusions
          " class="divider_general">
            <div class="divider big_divider"></div>
          </div>

          <!-- Блок 10 - "Интегральное заключение" -->
          <div v-if="checkConclusions && showConclusions" :class="checkInvestments &&
              showInvestments &&
              checkConclusions &&
              showConclusions
              ? 'right_cards'
              : 'card_fullwidth'
            ">
            <sip-card :title="'Интегральное заключение'">
              <div class="conclusion_group">
                <div class="block conclusion_author">
                  <sip-avatar :user="projectData.manager" :style="{ marginRight: '16px' }" :size="33" />
                  <div>
                    <div class="author_initials">
                      {{ projectData.manager.first_name }}
                      {{ projectData.manager.last_name }}
                    </div>
                    <div class="info_label">Руководитель проекта</div>
                    <!-- <div class="info_label">{{ userRole }}</div> -->
                  </div>
                </div>
                <div class="block conclusion_text_container">
                  <div class="conclusion_text">
                    {{ projectData.conclusion }}
                  </div>
                </div>
              </div>
            </sip-card>
          </div>
        </div>

        <!-- Строка 5: Дорожная карта -->
        <div v-if="showGantt" class="content_row">
          <!-- Блок 11 - Дорожная карта -->
          <sip-card v-if="checkGantt && showGantt" :title="'Дорожная карта'">
            <sip-gantt :data="projectData.gantt_chart" />
          </sip-card>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { mapGetters } from "vuex";
import { push } from "notivue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getNavPaddHeight, getHeaderHeight } from "@/database/functionsBase.js";

export default {
  name: "TableProjectAnalytics",
  components: { HeaderComp },

  data() {
    return {
      checkboxItems1: [
        {
          id: "cb-commons",
          label: "Основное",
          model: "showCommons",
          isChecked: true,
        },
        {
          id: "cb-drivers",
          label: "Драйверы",
          model: "showDrivers",
          isChecked: true,
        },
        {
          id: "cb-business",
          label: "Бизнес-кейс",
          model: "showBusiness",
          isChecked: true,
        },
        {
          id: "cb-others",
          label: "Другое",
          model: "showOthers",
          isChecked: true,
        },
        {
          id: "cb-groups",
          label: "Группы ГИП",
          model: "showGroups",
          isChecked: true,
        },
        {
          id: "cb-sections",
          label: "Проработка",
          model: "showSections",
          isChecked: true,
        },
      ],
      checkboxItems2: [
        { id: "cb-capex", label: "CAPEX", model: "showCapex", isChecked: true },
        {
          id: "cb-characteristics",
          label: "Характеристики ГИП",
          model: "showCharacteristics",
          isChecked: true,
        },
        {
          id: "cb-investments",
          label: "Инвестиции",
          model: "showInvestments",
          isChecked: true,
        },
        {
          id: "cb-conclusions",
          label: "Заключение",
          model: "showConclusions",
          isChecked: true,
        },
        {
          id: "cb-gantt",
          label: "Дорожная карта",
          model: "showGantt",
          isChecked: true,
        },
      ],
      checkboxItemsCount: 0,
      checkboxItemsAll: 0,

      navLinks: [{ id: "", name: "Текущая экспертиза", show: true }],
      radarChartValueSumm_1: 0,
      radarChartValueSumm_2: 0,
      indexGIPValueSumm: 0,
      investmentOptionsValueSumm: 0,
      capexOptionsValueSumm: 0,
      noValuesText: "Нет значений",
      tempTextareaValue: undefined,

      // -----------------------------------
      // характеристики apexCharts диаграмм:
      // ---
      sectionOptions: {
        // диаграмма "Полнота проработки по областям"
        series: [
          {
            name: "",
            data: [],
          },
        ],
        labels: [],
        chart: {
          id: "",
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
      },
      investmentOptions: {
        // диаграмма "Динамика капитала"
        series: [
          {
            name: "ГРР",
            data: [],
          },
          {
            name: "Предынвестиционные исследования",
            data: [],
          },
          {
            name: "ПИР",
            data: [],
          },
          {
            name: "Бурение",
            data: [],
          },
          {
            name: "Обустройство",
            data: [],
          },
        ],
        labels: [],
        chart: {
          id: "",
          toolbar: {
            show: false,
          },
        },
      },
      characteristicOptions: {
        // диаграмма "Характеристики методологии ГИП"
        series: [
          {
            name: "",
            data: [],
          },
        ],
        labels: [],
        chart: {
          id: "",
          toolbar: {
            show: false,
          },
        },
      },
      capexOptions: {
        // диаграмма "График инвестиций"
        series: [
          {
            name: "ГРР",
            data: [],
          },
          {
            name: "Предынвестиционные исследования",
            data: [],
          },
          {
            name: "ПИР",
            data: [],
          },
          {
            name: "Бурение",
            data: [],
          },
          {
            name: "Обустройство",
            data: [],
          },
        ],
        labels: [],
        chart: {
          id: "",
          toolbar: {
            show: false,
          },
        },
      },
      indexGIPOptions: {
        // диаграмма "Индекс готовности проекта" (круговая) в блоке "Сведения о проекте"
        series: [
          {
            name: "",
            data: [],
          },
        ],
        labels: [],
        chart: {
          id: "",
          toolbar: {
            show: false,
          },
        },
      },

      // -----------------------------------
      // расчитываемые массивы для диаграмм:
      // ---
      investValSumm: [],
      investValConstr: [],
      investValDesign: [],
      investValDrill: [],
      investValExplore: [],
      investValPreinvest: [],
      investYears: [],

      // -----------------------------------
      indexGIPOptionsDetails: [],
      linearBarOptions: [],
      radialBarSeries: [],
      dict_map: {
        1: [1, 2],
        2: [3, 4, 5, 6, 7, 8],
        3: [9, 11],
        4: [10, 12],
      },
      isLoading: false,
      freq: "day",
      showCommons: true,
      showDrivers: true,
      showBusiness: true,
      showOthers: true,
      showGroups: true,
      showSections: true,
      showCapex: true,
      showCharacteristics: true,
      showInvestments: true,
      showConclusions: true,
      showGantt: true,

      isFiltersShown: false,
      arrowAngle: 180,
      screenHeight: 0,
      navPaddHeight: 0,
      canvasHeight: 0,
      canvasContentHeight: 0,
      analyticsHeaderHeight: 0,
      costUnits: "",
    };
  },

  methods: {
    toggleItem(item) {
      this[item.model] = !this[item.model];
      item.isChecked = !item.isChecked;
      this.countCheckboxItems();
    },

    /** считаем кол-во показываемых в данный момент блоков информации */
    countCheckboxItems() {
      let count = 0;
      for (let i = 0; i < this.checkboxItems1.length; i++) {
        if (this.checkboxItems1[i].isChecked) {
          count = count + 1;
        }
      }
      for (let i = 0; i < this.checkboxItems2.length; i++) {
        if (this.checkboxItems2[i].isChecked) {
          count = count + 1;
        }
      }
      this.checkboxItemsCount = count;
    },

    saveInitialValue(value_) {
      this.tempTextareaValue = value_;
    },

    getInputValue(event) {
      if (this.tempTextareaValue == event.target.value) {
        return;
      }
      let field = event.target.name;
      let value = event.target.value;
      this.updateCommonProjectData(field, value);
    },

    async updateCommonProjectData(field_, name_) {
      let payload = {};
      payload["apiSection"] = "projects";
      payload["objectId"] = this.$route.params.id_proj;
      payload[field_] = name_;
      try {
        this.$store.dispatch("project/patchObject", payload);
        push.success(`Параметр проекта "${field_}" успешно обновлен!`);
      } catch (error) {
        push.success(`Ошибка изменения параметра проекта!`);
      }
    },

    getFontColor(val) {
      if (val < 25) {
        return "#FE6259";
      } else if (val < 50) {
        return "#FEC331";
      } else {
        return "#0078D2";
      }
      // return '#2FAD30'
    },

    getGroupIds(section, group) {
      return this.dict_map[group].includes(section);
    },

    /**  Подготовка данных для блока "Полнота проработки по областям" */
    setSectionChartOptions() {
      let sectionNames = [];
      let sectionValues = [];
      let textColors = [];

      this.APIData.sections.forEach((el) => {
        if (el.value != null) {
          sectionNames.push(el.name.split(" "));
          sectionValues.push(el.value);
          textColors.push("#000000");
          this.radarChartValueSumm_1 += el.value;
        }
      });
      this.sectionOptions = {
        series: [{ data: sectionValues }],
        chart: {
          id: "vuechart-1",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: sectionNames,
          labels: {
            style: {
              fontSize: "11px",
              fontWeight: 500,
              colors: textColors,
            },
            // offsetX: 100,
          },
        },
        yaxis: {
          max: 100,
          min: 0,
          tickAmount: 5,
        },
        dataLabels: {
          enabled: true,
        },
      };
    },

    /**  Подготовка данных для блока "Группы ГИП (Аналитические индексы интеграции проекта)" */
    setSectionItemChartOptions() {
      this.APIData.sections.forEach((el) => {
        if (el.value != null) {
          this.linearBarOptions.push({
            chart: {
              height: 110,
              id: el.id,
              type: "radialBar",
            },
            colors: [
              function ({ value }) {
                if (value < 25) {
                  return "#FE6259";
                } else if (value < 50) {
                  return "#FEC331";
                } else {
                  return "#0078D2";
                }
              },
            ],
            stroke: {
              lineCap: "round",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "50%",
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                    color: "#888",
                    fontSize: "9px",
                  },
                  value: {
                    offsetY: 4,
                    color: this.getFontColor(el.value),
                    fontSize: "11px",
                    show: true,
                    formatter: function (val) {
                      return val.toFixed(0) + "%";
                    },
                  },
                },
              },
            },
            labels: [el.name],
            series: [el.value],
            name: el.name,
          });
        }
      });
    },

    /**  Подготовка данных для блока "Характеристики методологии ГИП" */
    setCharacteristicChartOptions() {
      let characteristicNames = [];
      let characteristicValues = [];
      let textColors = [];

      this.APIData.characteristics.forEach((el) => {
        if (el.value != null) {
          characteristicNames.push(el.name.split(" "));
          characteristicValues.push(el.value);
          textColors.push("#000000");
          this.radarChartValueSumm_2 += el.value;
        }
      });
      this.characteristicOptions = {
        series: [{ data: characteristicValues }],
        chart: {
          id: "vuechart-2",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: characteristicNames,
          labels: {
            style: {
              fontSize: "11px",
              fontWeight: 500,
              colors: textColors,
            },
            // offsetY: -20,
          },
        },
        yaxis: {
          max: 100,
          min: 0,
          tickAmount: 5,
        },
        dataLabels: {
          enabled: true,
        },
      };
    },

    /**  Подготовка данных для блока "Динамика капитала" */
    setCostProfileChartOptions() {
      let construction = [];
      let design_survey_work = [];
      let drilling = [];
      let oil_gas_exploration = [];
      let preinvestment_study = [];
      let year = [];
      let unit = [];
      let mainUnit = 0;

      // сортируем коллекцию по полю "year" (по возрастанию)
      this.projectData.cost_profile.sort(
        (a, b) => parseFloat(a.year) - parseFloat(b.year)
      );

      // записываем входящие значения (разносим по переменным)
      this.projectData.cost_profile.forEach((el) => {
        construction.push(el.construction);
        design_survey_work.push(el.design_survey_work);
        drilling.push(el.drilling);
        oil_gas_exploration.push(el.oil_gas_exploration);
        preinvestment_study.push(el.preinvestment_study);
        year.push(el.year);
        unit.push(el.measurement_unit);
        this.investmentOptionsValueSumm += el.value;
      });
      this.investYears = [...year];

      // определяем наибольшую единицу измерения (приоритетную)
      for (let i = 0; i < unit.length; i++) {
        if (unit[i] > mainUnit) {
          mainUnit = unit[i];
        }
      }
      if (mainUnit >= 1000000000) {
        this.costUnits = "млрд. руб.";
      } else if (mainUnit >= 1000000) {
        this.costUnits = "млн. руб.";
      } else if (mainUnit >= 1000) {
        this.costUnits = "тыс. руб.";
      }

      // корректируем те значения, единицы измерений которых не совпадают с приоритетными
      for (let i = 0; i < unit.length; i++) {
        if (unit[i] == mainUnit) {
          construction[i] = Math.round(construction[i]);
          design_survey_work[i] = Math.round(design_survey_work[i]);
          drilling[i] = Math.round(drilling[i]);
          oil_gas_exploration[i] = Math.round(oil_gas_exploration[i]);
          preinvestment_study[i] = Math.round(preinvestment_study[i]);
        } else if (unit[i] < mainUnit) {
          construction[i] = Math.round(construction[i] / (mainUnit / unit[i]));
          design_survey_work[i] = Math.round(
            design_survey_work[i] / (mainUnit / unit[i])
          );
          drilling[i] = Math.round(drilling[i] / (mainUnit / unit[i]));
          oil_gas_exploration[i] = Math.round(
            oil_gas_exploration[i] / (mainUnit / unit[i])
          );
          preinvestment_study[i] = Math.round(
            preinvestment_study[i] / (mainUnit / unit[i])
          );
        }

        // заносим данные в необходимые массивы
        this.investValSumm.push(
          construction[i] +
          design_survey_work[i] +
          drilling[i] +
          oil_gas_exploration[i] +
          preinvestment_study[i]
        );
        this.investValConstr.push(construction[i]);
        this.investValDesign.push(design_survey_work[i]);
        this.investValDrill.push(drilling[i]);
        this.investValExplore.push(oil_gas_exploration[i]);
        this.investValPreinvest.push(preinvestment_study[i]);
      }

      // формируем данные для построения графика
      this.investmentOptions = {
        series: [
          {
            name: "ГРР",
            data: oil_gas_exploration,
          },
          {
            name: "Предынвестиционные исследования",
            data: preinvestment_study,
          },
          {
            name: "ПИР",
            data: design_survey_work,
          },
          {
            name: "Бурение",
            data: drilling,
          },
          {
            name: "Обустройство",
            data: construction,
          },
        ],

        chart: {
          type: "bar",
          stacked: true,
          offsetY: -5,
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            dataLabels: {
              total: {
                enabled: true,
                offsetY: -10,
                style: {
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontFamily: "sf-pro-display-light",
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: year,
          labels: {
            // шрифт
            style: {
              fontFamily: "sf-pro-display-light",
              fontSize: "14px",
            },
          },
          axisBorder: {
            show: true,
            color: "#D7D7D7",
            offsetX: 0,
            offsetY: 0,
            height: 1,
          },
          axisTicks: {
            // вертикальные засечки на оси Х
            show: false,
          },
        },

        yaxis: {
          show: true,
          showAlways: true,
          max: Math.max(...this.investValSumm),
          labels: {
            // шрифт
            style: {
              fontFamily: "sf-pro-display-light",
              fontSize: "14px",
            },
          },
          axisBorder: {
            show: true,
            color: "#D7D7D7",
            offsetX: 0,
            offsetY: 0,
            height: 1,
          },
          axisTicks: {
            // вертикальные засечки на оси Х
            show: false,
          },
        },

        grid: {
          show: false,
          padding: {
            bottom: 30,
          },
        },

        legend: {
          position: "bottom",
          offsetY: -5,
          fontSize: "13px",
          fontFamily: "sf-pro-display-light",
          markers: {
            fillColors: ["#0078D2", "#80C1F2", "#1ABC72", "#F4EF72", "#F0BC00"],
          },
          formatter: function (seriesName) {
            return ["&nbsp;", seriesName, "&nbsp;&nbsp;&nbsp;"];
          },
        },

        fill: {
          opacity: 1,
          colors: ["#0078D2", "#80C1F2", "#1ABC72", "#F4EF72", "#F0BC00"],
        },
      };
    },

    /**  Подготовка данных для блока "График инвестиций" */
    setCostAccumulatedProfileChartOptions() {
      let summValues = [...this.investValSumm];
      let oil_gas_exploration = [...this.investValExplore];
      let preinvestment_study = [...this.investValPreinvest];
      let design_survey_work = [...this.investValDesign];
      let drilling = [...this.investValDrill];
      let construction = [...this.investValConstr];
      let year = [...this.investYears];
      // ---
      let accumVal;
      let accumSum = [];
      let accumExplore = [];
      let accumPreinvest = [];
      let accumDesign = [];
      let accumDrill = [];
      let accumConstr = [];

      // считаем накопленные величины для всех графиков
      accumVal = 0;
      summValues.forEach((el) => {
        accumVal += el;
        accumSum.push(accumVal);
      });
      accumVal = 0;
      oil_gas_exploration.forEach((el) => {
        accumVal += el;
        accumExplore.push(accumVal);
      });
      accumVal = 0;
      preinvestment_study.forEach((el) => {
        accumVal += el;
        accumPreinvest.push(accumVal);
      });
      accumVal = 0;
      design_survey_work.forEach((el) => {
        accumVal += el;
        accumDesign.push(accumVal);
      });
      accumVal = 0;
      drilling.forEach((el) => {
        accumVal += el;
        accumDrill.push(accumVal);
      });
      accumVal = 0;
      construction.forEach((el) => {
        accumVal += el;
        accumConstr.push(accumVal);
      });

      // let firstOffsetX = [10, 0, 0, 0, 0, 0];

      // формируем данные для построения графика
      this.capexOptions = {
        series: [
          {
            name: "ГРР",
            data: accumExplore,
          },
          {
            name: "Предынвестиционные исследования",
            data: accumPreinvest,
          },
          {
            name: "ПИР",
            data: accumDesign,
          },
          {
            name: "Бурение",
            data: accumDrill,
          },
          {
            name: "Обустройство",
            data: accumConstr,
          },
          // {
          // 	name: "Сумма капитальных затрат",
          // 	stacked: false,
          // 	data: accumSum,
          // },
        ],

        chart: {
          type: "area",
          stacked: true,
          offsetY: -5,
          toolbar: {
            show: false,
          },
        },

        stroke: {
          curve: "straight",
          width: 2,
        },

        labels: year,

        dataLabels: {
          enabled: true,
          offsetY: -10,
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            w.config.dataLabels.offsetX = dataPointIndex == 0 ? 20 : 0;
            // ---
            val = seriesIndex == 4 ? accumSum[dataPointIndex] : "";
            return val;
          },
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "sf-pro-display-light",
          },
          background: {
            foreColor: "#373D3F",
            opacity: 0,
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.65,
            opacityTo: 0.2,
          },
          colors: ["#97D1FD", "#EDF2F5", "#88F5C3", "#F2FA7F", "#F9D08F"],
        },

        colors: ["#0078D2", "#80C1F2", "#1ABC72", "#D7F112", "#F0BC00"],

        xaxis: {
          categories: year,
          labels: {
            // шрифт
            style: {
              fontFamily: "sf-pro-display-light",
              fontSize: "14px",
            },
          },
          axisBorder: {
            show: true,
            color: "#D7D7D7",
            offsetX: 0,
            offsetY: 0,
            height: 1,
          },
          axisTicks: {
            // вертикальные засечки на оси Х
            show: false,
          },
        },

        yaxis: {
          show: true,
          showAlways: true,
          max: Math.max(...accumSum),
          labels: {
            // шрифт
            style: {
              fontFamily: "sf-pro-display-light",
              fontSize: "14px",
            },
          },
          axisBorder: {
            show: true,
            color: "#D7D7D7",
            offsetX: 0,
            offsetY: 0,
            height: 1,
          },
          axisTicks: {
            // вертикальные засечки на оси Х
            show: false,
          },
        },

        grid: {
          show: false,
          padding: {
            bottom: 30,
          },
        },

        legend: {
          position: "bottom",
          offsetY: -5,
          fontSize: "13px",
          fontFamily: "sf-pro-display-light",
          markers: {
            fillColors: ["#0078D2", "#80C1F2", "#1ABC72", "#F4EF72", "#F0BC00"],
          },
          formatter: function (seriesName) {
            return ["&nbsp;", seriesName, "&nbsp;&nbsp;&nbsp;"];
          },
        },
      };
    },

    /**  Подготовка данных для круговой диаграммы "Индекс готовности проекта" */
    setIndexGIPOptions() {
      this.indexGIPOptions = {
        chart: {
          id: "index-gip-id",
        },
        colors: [
          function ({ value }) {
            if (value == 0) {
              return "#F4F4F4";
            } else if (value < 25) {
              return "#FE6259";
            } else if (value < 50) {
              return "#FEC331";
            } else {
              return "#0078D2";
            }
          },
        ],
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            top: -15,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "58%",
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
                color: "#888",
                fontSize: "9px",
              },
              value: {
                offsetY: 10,
                color: this.getFontColor(this.projectData.index_gip),
                fontSize: "30px",
                fontFamily: "$sf-light",
                show: true,
                formatter: function (val) {
                  return val.toFixed(0) + "%";
                },
              },
            },
          },
        },
        labels: [""],
        series: [this.projectData.index_gip],
      };
    },

    /**  Подготовка данных для коллекции круговых диаграммы "Группы ГИП..." */
    setIndexGIPOptionsDetails() {
      this.APIData.groups.forEach((el, index) => {
        if (el.value != null) {
          this.indexGIPValueSumm += el.value;
          this.indexGIPOptionsDetails.push({
            chart: {
              id: `index-gip-details-${index}`,
            },
            colors: [
              function ({ value }) {
                if (value < 25) {
                  return "#FE6259";
                } else if (value < 50) {
                  return "#FEC331";
                } else {
                  return "#0078D2";
                }
              },
            ],
            stroke: {
              lineCap: "round",
            },
            grid: {
              padding: {
                top: -20,
              },
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "58%",
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                    color: "#888",
                    fontSize: "9px",
                  },
                  value: {
                    offsetY: 10,
                    color: this.getFontColor(el.value),
                    fontSize: "30px",
                    fontFamily: "$sf-light",
                    show: true,
                    formatter: function (val) {
                      return val.toFixed(0) + "%";
                    },
                  },
                },
              },
            },
            labels: [""],
            series: [Math.round(el.value)],
            name: el.name,
            groupId: el.id,
          });
        }
      });
    },

    rewriteDotToComma(value_) {
      return String(value_).replace(".", ",");
    },

    rewriteToWholeNumber(value_) {
      return String(Math.round(value_).toLocaleString("en")).replace(",", "");
    },

    correctChartNames(value_) {
      switch (value_) {
        case "Инфраструктура, добыча, операционная деятельность":
          return "Инфраструктура, добыча, операц. деятельность";
        case "Капитальное строительство и закупки":
          return "Кап. строительство и закупки";
        case "План реализации проекта и проектные сервисы":
          return "Проектные сервисы";
        default:
          return value_;
      }
    },

    setBlockBodyCenteredStyle() {
      return {
        maxHeight: `${1000}px`,
        padding: `${0}px`,
      };
    },

    /**  Выгрузка аналитики в формате PDF */
    async downloadPDF() {
      const notification = push.promise(
        "Подготовка аналитики для выгрузки, пожалуйста подождите..."
      );
      let content = this.$el.getElementsByClassName("content_row");

      let pageOrientation = "l";
      let pdfUnit = "px";
      let pdfFormat = "a3";
      let pdfOption = {
        orientation: pageOrientation,
        unit: pdfUnit,
        format: pdfFormat,
      };

      // offsets
      let dy = 132;
      let dx = 20;
      let sx = 0;
      let sy = 0;

      let scale = 1.0;
      let canvasTransformURLFormat = "image/jpeg";
      let imageFormat = "JPEG";
      let heightLimit = 700;

      let pdf = new jsPDF(pdfOption);
      let pdfHeight = pdf.internal.pageSize.getHeight();
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let canvasWidth = pdfWidth - dx * 2;
      let canvasHeight = pdfHeight - dy * 2;

      // Создаем экран для отображения начальной страницы
      let titleCanvas = document.createElement("canvas");
      titleCanvas.width = canvasWidth;
      titleCanvas.height = canvasHeight;
      let titleContext = titleCanvas.getContext("2d");
      titleContext.fillStyle = "rgb(255, 255, 255)";
      titleContext.fillRect(0, 0, titleCanvas.width, titleCanvas.height);
      titleContext.font = "40px sf-pro-display-regular";
      titleContext.fillStyle = "rgb(0, 0, 0)";
      titleContext.textAlign = "center";
      titleContext.fillText(
        `Аналитика по проекту\n "${this.projectData.name}"`,
        titleCanvas.width / 2,
        titleCanvas.height / 2
      );
      let croppedDataUrl = titleCanvas.toDataURL(
        canvasTransformURLFormat,
        scale
      );
      pdf.addImage(
        croppedDataUrl,
        imageFormat,
        dx,
        dy,
        canvasWidth,
        canvasHeight
      );
      pdf.addPage(pdfFormat, pageOrientation);

      // Итерируемся по табличкам и преобразовываем их в canvas => JPEG => PDF
      for (let tmp of content) {
        await html2canvas(tmp, {
          allowTaint: false,
          imageTimeout: 0,
          onclone: (clonedEl) => {
            // Обнуляем BoxShadow для корректного отображения и добавляем границу
            let tmp = clonedEl.getElementsByClassName("card");
            for (let t of tmp) {
              t.style.boxShadow = "none";
              t.style.border = "1px solid grey";
              t.querySelector(".card_header_controls").remove();
            }
          },
        }).then((canvas) => {
          let imgData = canvas.toDataURL(canvasTransformURLFormat, scale);
          if (canvas.height > heightLimit) {
            let offScreenCanvas = document.createElement("canvas");
            offScreenCanvas.width = canvas.width;
            offScreenCanvas.height = pdfHeight;

            let offScreenContext = offScreenCanvas.getContext("2d");
            offScreenContext.fillStyle = "rgb(255, 255, 255)";
            offScreenContext.fillRect(
              0,
              0,
              offScreenCanvas.width,
              offScreenCanvas.height
            );
            offScreenContext.drawImage(
              canvas,
              sx,
              sy,
              canvas.width,
              pdfHeight,
              0,
              0,
              canvas.width,
              pdfHeight
            );

            let croppedDataUrl = offScreenCanvas.toDataURL(
              canvasTransformURLFormat,
              scale
            );
            pdf.addImage(
              croppedDataUrl,
              imageFormat,
              dx,
              dy,
              canvasWidth,
              canvasHeight
            );

            while (sy < canvas.height) {
              sy += canvasHeight;
              offScreenContext.fillRect(
                0,
                0,
                offScreenCanvas.width,
                offScreenCanvas.height
              );
              offScreenContext.drawImage(
                canvas,
                sx,
                sy,
                canvas.width,
                pdfHeight,
                0,
                0,
                canvas.width,
                pdfHeight
              );
              croppedDataUrl = offScreenCanvas.toDataURL(
                canvasTransformURLFormat,
                scale
              );
              pdf.addPage(pdfFormat, pageOrientation);
              pdf.addImage(
                croppedDataUrl,
                imageFormat,
                dx,
                dy,
                canvasWidth,
                canvasHeight
              );
            }
          } else {
            pdf.addImage(
              imgData,
              imageFormat,
              dx,
              dy,
              canvasWidth,
              canvasHeight
            );
            pdf.addPage(pdfFormat, pageOrientation);
          }
        });
      }
      ("все графики добавлены");
      notification.resolve("Файл успешно загружен!");
      pdf.save("download.pdf");
    },

    openFiltersBar() {
      this.isFiltersShown = !this.isFiltersShown;
      this.arrowAngle = this.arrowAngle + 180;
    },

    /**  рисуем разделитель между столбцами с "Группами ГИП" */
    drawGipDivider(array_, group_) {
      if (group_.groupId < array_.length) {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    ...mapGetters({
      APIData: "project/getAnalytics",
      projectData: "project/getProjectData",
      getScreenHeight: "domprops/getScreenHeight",
      // user: "user/getUser",
    }),

    checkCommons() {
      if (this.projectData)
        return [
          this.projectData.type,
          this.projectData.phase,
          this.projectData.purpose,
          this.projectData.description,
          this.projectData.stage,
          this.projectData.planned_duration,
          this.projectData.actual_duration,
        ].some((el) => el != null);
      return false;
    },

    checkDrivers() {
      if (this.projectData)
        return [
          this.projectData.first_driver,
          this.projectData.second_driver,
          this.projectData.third_driver,
        ].some((el) => el != null);
      return false;
    },

    checkBusiness() {
      if (this.projectData)
        return [
          this.projectData.net_present_value,
          this.projectData.internal_rate_of_return,
          this.projectData.profit_to_investment_ratio,
        ].some((el) => el != null);
      return false;
    },

    checkOthers() {
      if (this.projectData) {
        if (
          [
            this.projectData.cost_profile,
            this.projectData.capital_cost,
            this.projectData.gas_flowrate,
            this.projectData.security_cost,
            this.projectData.team_size,
          ].every((el) => el == null || el.length == 0)
        )
          return false;
        return true;
      }
      return false;
    },

    checkGroups() {
      if (this.APIData) {
        if (this.APIData.groups.length) {
          if (
            this.APIData.groups.every(
              (group) => group.value == 0 || group.value == null
            )
          )
            return false;
          return true;
        }
      }
      return false;
    },

    checkSections() {
      if (this.APIData) {
        if (this.APIData.sections.length) {
          if (
            this.APIData.sections.every(
              (section) => section.value == 0 || section.value == null
            )
          )
            return false;
          return true;
        }
      }
      return false;
    },

    checkCharacteristics() {
      if (this.APIData) {
        if (this.APIData.characteristics.length) {
          if (
            this.APIData.characteristics.every(
              (characteristic) =>
                characteristic.value == 0 || characteristic.value == null
            )
          )
            return false;
          return true;
        }
      }
      return false;
    },

    checkCapex() {
      if (this.projectData)
        return this.projectData.cost_profile.length != 0 ? true : false;
      return false;
    },

    checkInvestments() {
      if (this.projectData)
        return this.projectData.cost_profile.length != 0 ? true : false;
      return false;
    },

    checkConclusions() {
      if (this.projectData)
        return this.projectData.conclusion &&
          this.projectData.is_conclusion_published
          ? true
          : false;
      return false;
    },

    checkGantt() {
      if (this.projectData)
        return this.projectData.gantt_chart.length != 0 ? true : false;
      return false;
    },

    summaryCapitalCost() {
      let sum = 0;
      this.projectData.cost_profile.forEach((el) => {
        let summary_cost = 0;
        Object.keys(el).forEach((key) => {
          if (!["id", "year", "measurement_unit", "project"].includes(key)) {
            summary_cost += el[key];
          }
        });
        sum += summary_cost * el.measurement_unit;
      });
      return sum / 1e9;
    },

    setArrowStyle() {
      return {
        rotate: `${this.arrowAngle}deg`,
      };
    },

    setCanvasStyle() {
      return {
        paddingBottom: `${0}px`,
      };
    },

    setCanvasContentStyle() {
      return {
        height: `${this.canvasContentHeight}px`,
        paddingBottom: `${30}px`,
      };
    },

    // userRole() {
    // 	return this.getUserRole(this.user);
    // },
  },

  watch: {
    APIData(value_) {
      if (value_) {
        value_.sections
          ? (this.setSectionChartOptions(), this.setSectionItemChartOptions())
          : null;
        value_.characteristics ? this.setCharacteristicChartOptions() : null;
        value_.groups ? this.setIndexGIPOptionsDetails() : null;
        this.isLoading = false;
      }
    },

    projectData(value_) {
      if (value_) {
        value_.cost_profile
          ? (this.setCostProfileChartOptions(),
            this.setCostAccumulatedProfileChartOptions())
          : null;
        value_.index_gip ? this.setIndexGIPOptions() : null;
        this.isLoading = false;
      }
    },

    getScreenHeight(value_) {
      this.screenHeight = value_;
    },

    screenHeight(value_) {
      this.canvasHeight = value_ - this.navPaddHeight;
      this.canvasContentHeight = this.canvasHeight - this.analyticsHeaderHeight;
    },

    // user(newUser) {
    // 	this.getUserFullName(newUser, true);
    // }
  },

  beforeUnmount() {
    this.$store.commit("project/SET_PROJECT_DATA", null);
    this.$store.commit("project/SET_ANALYTICS", null);
  },

  mounted() {
    this.isLoading = true;
    let projectId = this.$route.params.id_proj;
    this.$store.dispatch("project/fetchAnalytics", projectId);
    this.$store.dispatch("project/fetchProjectData", projectId);
    this.countCheckboxItems();
    this.checkboxItemsAll =
      this.checkboxItems1.length + this.checkboxItems2.length;
    this.navPaddHeight = getNavPaddHeight(document);
    this.analyticsHeaderHeight = getHeaderHeight(document);
    this.screenHeight = window.innerHeight;
  },
};
</script>

<style lang="scss" scoped>
@charset "utf-8";
@import "@/css/general.scss";
@import "@/css/components/scrollbar.scss";
@import "@/css/components/table.scss";
@import "@/css/components/grid.scss";

/* #region - панель с фильтрами ("Блоков с информацией") */
.board {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  height: 53px;

  display: flex;
  align-items: flex-start;
  gap: 33px;

  padding: 26px 33px;

  & .btn-outline-primary {
    height: 25px;
    padding: 1px 10px;
  }
}

.left_part {
  display: flex;
  align-items: center;
  gap: 10px;
  transition-property: all;
  transition: all ease 0.35s;
  cursor: pointer;

  &:hover {
    opacity: 80%;
    transition-property: all;
    transition: all ease 0.35s;
  }

  &:hover .arrow {
    scale: 110%;
    opacity: 80%;
    transition-property: all;
    transition: all ease 0.35s;
  }
}

.block_info_label {
  min-width: 222px;
}

.arrow {
  filter: invert(21%) sepia(6%) saturate(336%) hue-rotate(202deg) brightness(91%) contrast(94%);
}

.board_full_style {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inner_block {
  display: flex;
  gap: 5px;
}

.board_item {
  user-select: none;
}

.filter_button {
  border: 1px solid rgb(#afafaf, 1);
  border-radius: 20px;
  background-color: white;
  font-size: 13px;
  font-family: "sf-pro-display-light";
  color: #363639;
  padding: 3px 18px;
  cursor: pointer;
  transition-property: all;
  transition: all ease 0.35s;

  &:hover {
    background-color: rgb(230, 230, 230);
    border: 1px solid rgb(201, 206, 209);
    color: black;
    transition-property: all;
    transition: all ease 0.35s;
  }
}

.button_checked {
  background-color: #dcdcdc;
  border: 1px solid rgb(#ffffff, 1);

  &:hover {
    background-color: #ebebeb;
    border: 1px solid rgb(#ffffff, 1);
  }
}

.button__pdf {
  cursor: pointer;

  & .button__pdf__icon {
    transition: all ease 0.3s;
    filter: invert(18%) sepia(6%) saturate(369%) hue-rotate(201deg) brightness(95%) contrast(88%);
  }

  &:hover {
    & .button__pdf__icon {
      opacity: 60%;
    }
  }
}

/* #endregion */

/* #region - анимации */
.buttons_opacity_trns-enter-active {
  transition: all 0.55s ease-out;
  transition-delay: 0.2s;
}

.buttons_opacity_trns-leave-active {
  transition: all 0.35s ease-out;
}

.buttons_opacity_trns-enter-from,
.buttons_opacity_trns-leave-to {
  opacity: 0;
}

.divider_opacity_trns-enter-active {
  transition: all 0.35s ease-out;
  transition-delay: 0.55s;
}

.divider_opacity_trns-leave-active {
  transition: all 0.35s ease-out;
}

.divider_opacity_trns-enter-from,
.divider_opacity_trns-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* #endregion */

/* #region - разделители */
.divider_general {
  height: 100%;
  padding-top: 60px;
  padding-bottom: 4%;
}

.divider {
  width: 1px;
  margin-top: 2px;
}

.big_divider {
  height: 100%;
  background-color: #dfdfdf;
}

.small_divider {
  height: 20px;
  background-color: #afafaf;
}

.others_divider {
  height: 28px;
  background-color: #dfdfdf;
}

.gip_divider {
  height: 115px;
  margin-top: 30px;
  background-color: #dfdfdf;
}

.gip_divider_margin_right {
  margin-right: 30px;
}

/* #endregion */

/* #region - основной контент (плитки с информацией) */
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  gap: 45px;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

// основной ряд с карточками (всего 5 рядов во всей аналитике)
.content_row {
  height: auto;
  max-width: 100%;
  min-width: 30%;
  display: flex;
  width: -webkit-fill-available;
  gap: 60px;
}

// стиль содержания body у плиток, которые находятся справа (начиная с Полноты проработки)
.right_cards {
  width: 35%;
}

// три и более карточки в двух рядах, каждый ряд, в свою очередь, содержит столбцы с карточками
.card_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 47%;
}

// две и более карточки в одном ряду
.card_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

// группа блоков (label + content) друг под другом внутри card_body
.card_body_inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;
}

.inner_right {
  width: 30%;
}

// центрируем контент по правой границе
.right_side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card_fullwidth {
  min-width: 100%;
}

.info_label {
  color: #55555a;
  font-family: "sf-pro-display-light";
  font-size: 13px;
}

.text_right {
  text-align: end;
}

.info_content {
  font-size: 15px;
  line-height: normal;
}

.card_drivers {
  width: 45%;
}

.card_business {
  width: 45%;
}

.others_cell_group {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.colmplicated_cell {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}

/* #endregion */

/* #region - блок "Интегральное заключение" */
.conclusion_group {
  width: 100%;
  height: 100%;
}

.conclusion_text_container {
  width: 100%;
  max-height: 320px;
  padding: 14px 20px;
  background-color: #ecf8ff;
}

.conclusion_text {
  font-family: "sf-pro-display-medium";
  font-size: 14px;
  color: #55555a;
  overflow-y: scroll;
}

.conclusion_author {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.author_initials {
  font-size: 16px;
  margin-top: -2px;
}

.author_jobtitle {
  font-size: 13px;
  color: #bfbfbf;
  margin-top: -3px;
}

/* #endregion */

/* #region - разное - раскидать */
.col {
  gap: 2px;
}

.index_gip_chart {
  padding: 0px;
}

.info_block_duratrion {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.info_aux {
  font-size: 16px;
  color: gray;
  align-self: center;
}

.info_stat {
  font-family: $sf-medium;
  font-size: 28px;
  line-height: 0.9em;
}

.gip_group_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.gip_group {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  height: 100%;
}

.gip_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
  height: 100%;
}

.gip_group_label {
  font-family: "sf-pro-display-light";
  font-size: 13px;
  color: #363639;
  line-height: normal;
  margin-bottom: 7px;
}

.label_progress_addit {
  margin-bottom: 0px;
  width: 75%;
}

.gip_group_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.gip_block {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-bottom: 30px;
}

.chart_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* #endregion */
</style>
