<template>
	<div class="tb-background">
		<Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>
		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :showSearch="true" v-model:searchQuery="searchText" />
		<Transition name="fade">
			<div class="table table__wrapper" v-if="!isLoading && APIDataFiltered.length != 0">
				<div class="row row__header">
					<div class="col__header number">#</div>
					<div class="col__header name" :class="this.currentSortField === 'name' ? 'selected' : ''"
						@click="sortTable('name')">
						Название проекта
						<img :class="['imm_array_sort', getSortClasses('name')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col__header manager" :class="this.currentSortField === 'manager' ? 'selected' : ''"
						@click="sortTable('manager')">
						Руководитель проекта
						<img :class="['imm_array_sort', getSortClasses('manager')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col__header stage" :class="this.currentSortField === 'stage' ? 'selected' : ''"
						@click="sortTable('stage')">
						Этап
						<img :class="['imm_array_sort', getSortClasses('stage')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col__header answers"
						:class="this.currentSortField === 'answer_percentage' ? 'selected' : ''"
						@click="sortTable('answer_percentage')">
						Ответов, ед.
						<img :class="['imm_array_sort', getSortClasses('answer_percentage')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col__header index_gip" :class="this.currentSortField === 'index_gip' ? 'selected' : ''"
						@click="sortTable('index_gip')">
						Индекс ГИП
						<img :class="['imm_array_sort', getSortClasses('index_gip')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
					<div class="col__header updated_at"
						:class="this.currentSortField === 'updated_at' ? 'selected' : ''"
						@click="sortTable('updated_at')">
						Последнее изменение
						<img :class="['imm_array_sort', getSortClasses('updated_at')]"
							src="@/assets/icons/arrow_filter.svg" />
					</div>
				</div>
				<div class="table__body">
					<transition-group name="list">
						<div class="row row__content selection " v-for="(row, rowIndex) in APIDataFiltered"
							:key="row.id" :disabled="projectPermission(row)" @click="projectClickHandler(row)">
							<div class="col__content number">{{ rowIndex + 1 }}</div>
							<div class="col__content name">{{ row.name }}</div>
							<div class=" col__content manager">
								<sip-avatar :user="row.user" :style="{ marginRight: '15px' }" :size="30" />
								{{ row.manager }}
							</div>
							<div class=" col__content stage">
								<sip-badge :color="row.stage.color" :style="settingBadge(row.stage.name)">{{
									getSliceOfString(row.stage.name, 50)
								}}</sip-badge>
							</div>
							<div class="col__content  answers" style="gap: 4px">
								<img class="flag" :src="responseStatus(row.answers, row.questions)"
									:style="setFlagColor(row.answers, row.questions)" />
								<span :style="setColorTotalAnswers(row.answers, row.questions)">{{ row.answers }}</span>
								<span class="muted">/</span>
								<span class="muted">{{ row.questions }}</span>
							</div>
							<div class="col__content index_gip">{{ row.index_gip }} %</div>
							<div class="col__content updated_at">{{ getDateDuration(row.updated_at) }}</div>
						</div>
					</transition-group>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import { mDate } from "@/utils/date";
import { mColor } from "@/utils/color";
import { mText } from "@/utils/text";
import { mPermissions } from "@/utils/permissions";
import { mFilters } from "@/utils/filters";
import { mAnswer } from "@/utils/answer.js"
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { push } from 'notivue'

export default {
	name: "TableProjects",
	mixins: [mDate, mColor, mText, mPermissions, mFilters, mAnswer],
	components: { HeaderComp },
	data() {
		return {
			APIData: [],
			isLoading: false,
			searchText: "",
			searchNoContentMessage: "😅 ничего не нашлось...",
			sortDirection: -1,
			currentSortField: "updated_at",
			navLinks: [{ id: "", name: "Доступный перечень проектов", show: true }],
			totalAnswers: null,
			totalQuestions: null,
		};
	},
	async created() {
		this.isLoading = true;
		localStorage.removeItem("projectName");
		this.getProjectList();
	},
	computed: {
		APIDataFiltered() {
			let data = this.APIData;

			// фильтруем посредством ввода символов в текстовое поле "Поиска"
			if (this.searchText.trim()) {
				data = data.filter((project) => project.name.toLowerCase().includes(this.searchText.toLowerCase()));
			}

			// сортировка массива
			data.sort((a, b) => {
				let a_ = 0;
				let b_ = 0;
				const MINIMAL_VALUE = -1000000;

				if (this.currentSortField == "stage") {
					a_ = a[this.currentSortField].name;
					b_ = b[this.currentSortField].name;
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				} else if (this.currentSortField == "index_gip") {
					a_ = parseFloat(a[this.currentSortField]);
					b_ = parseFloat(b[this.currentSortField]);
					if (isNaN(a_)) a_ = MINIMAL_VALUE;
					if (isNaN(b_)) b_ = MINIMAL_VALUE;
					return (a_ - b_) * this.sortDirection;
				} else if (this.currentSortField == "updated_at") {
					a_ = new Date(a[this.currentSortField]);
					b_ = new Date(b[this.currentSortField]);
					return (a_ - b_) * this.sortDirection;
				} else {
					a_ = a[this.currentSortField];
					b_ = b[this.currentSortField];
					if (a_ == b_) return 1;
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				}
			});
			return data;
		},
	},
	methods: {
		/**  Обработчик клика на проект
		 * @param {object} project_data
		 * @returns {none}
		 */
		projectClickHandler(project_data) {
			if (this.projectPermission(project_data)) return;
			localStorage.setItem("projectName", project_data.name);
			this.$router.push({ name: "project", params: { id_proj: project_data.id } });
		},

		/** Нормализация полученных данных по проектам
		 * @param {object} data_
		 * @returns {object}
		 */
		normalizeAPIdata(data_) {
			let new_data = [];
			data_.forEach((el) => {
				new_data.push({
					id: el.id,
					name: el.name,
					stage: el.stage,
					phase: el.phase,
					index_gip: el.index_gip,
					manager: this.getFullName(el.manager),
					image: el.manager.image,
					updated_at: el.updated_at,
					user: el.manager,
					section_experts: el.section_experts,
					answers: el.answer_count,
					questions: el.question_count ? el.question_count : "-",
					answer_percentage: el.answer_count / el.question_count,
				});
			});
			this.isLoading = false;
			return new_data;
		},

		/** Нормализация полученных данных по проектам
		 * @returns {none}
		 */
		async getProjectList() {
			try {
				this.$store.dispatch("project/fetchList", "projects").then((response) => {
					this.APIData = this.normalizeAPIdata(response);
				});
			} catch (error) {
				console.log(error);
			}
		},

		/** Нормализация полученных данных по проектам
		 * @param {object} object
		 * @returns {none}
		 */
		async deleteObject(object) {
			try {
				await this.$api.sip.delete(this.projectForDeleteId);
				this.APIData = [...this.APIData.filter((el) => el.id != this.projectForDeleteId)];
			} catch (error) {
				push.error(`Ошибка удаления ${object}!`)
			}
		},

		settingBadge(row) {
			return {
				padding: row == "Выбор" ? "5px 20px" : "5px 17px"
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";
@import "@/css/pages/tableProject.scss";
@import "@/css/pages/authCard.scss";


.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.col__accent {
	font-size: 16px;
	font-family: $sf-bold;
}

.table__wrapper {
	height: 75vh;
	z-index: 3;
}

.table__body {
	height: 100%;
	width: calc(100% + 7px);
}

.flag {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	margin-right: 5px;
	display: flex;
}

.muted {
	color: $color-gray-3;
}

.row__header {
	& .col__header {

		&:first-child,
		&.sm {
			justify-content: flex-start;
			margin-left: 1%;
			max-width: 50px;
		}
	}
}

.col__content {

	&:first-child,
	&.sm {
		justify-content: flex-start;
		margin-left: 1%;
		max-width: 50px;
	}
}
</style>
