<template>
	<div class="badge" :style="getColor">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "sip-badge",
	props: {
		color: {
			type: String,
		},
		isOutline: {
			type: Boolean,
			default: true
		},
	},
	// data() {
	// 	return {
	// 		isSourceAvaliable: false,
	// 	};
	// },
	methods: {
		getBGColor(hex_) {
			return hex_;
		},
	},
	computed: {
		getColor() {
			if (this.isOutline) {
				return {
					color: this.color,
					borderColor: this.color,
				}
			}
			return {
				color: '#fff',
				backgroundColor: this.color,
				borderColor: '#fff'
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";

.badge {
	border: 1px solid $color-blue-5;
	background-color: transparent;
	font-family: "sf-pro-display-light";
	color: $color-white;
	font-size: 12px;
	transition: all ease 200ms;
	border-radius: 12px;
	padding: 5px 10px;
	line-height: 100%;
}

// .badge__color {
// 	background-color: $color-red-2;
// 	margin-right: 10px;
// }

// .footer-badge {
// 	min-height: 24px;
// 	min-width: 24px;
// 	border-radius: 50%;
// 	background-color: $color-blue-1;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	color: $color-gray-5;

// 	&[active="true"] {
// 		background-color: $color-blue-5;
// 		color: $color-white;
// 	}
// }

// .filter-badge {
// 	height: 15px;
// 	width: 15px;
// 	font-size: 10px;
// 	text-align: center;
// 	border-radius: 50%;
// 	color: white;
// 	background: red;
// 	text-decoration: none;
// 	position: absolute;
// 	margin-left: 15px;
// 	margin-top: -28px;
// }

.fullfilled {
	display: flex;
	border: none;
	background-color: rgba($color-gray-1, .5);
	width: fit-content;
	height: fit-content;
	font-size: 8px;
	text-align: center;
	vertical-align: center;
	line-height: normal;
}
</style>
