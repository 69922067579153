<template>
    <div ref="myModal" class="modal noselect" id="spinner" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" v-if="show">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <img :src="require(`@/assets/logos/logo_full.svg`)" alt="mdo" class="modal-content spinner-grow text-light spinner"
                role="status" />
        </div>
    </div>
</template>
  

<script>
export default {
    name: 'modal-spinner',
    data: function () {
        return {
            show: true
        }
    },
    methods: {
        closeModal: function () {
            this.show = false
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-window {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.6);
    position: fixed;
    display: flex;
    z-index: 100;
}

.spinner {
    background: none;
    border: none;
    width: 5rem;
    height: 5rem;
    margin: auto;
}

.modal-window__content {
    margin: auto;
    background: rgb(0, 0, 0);
    min-height: 50px;
    min-width: 300px;
}

.spinner-grow {
    --bs-spinner-animation-speed: 1s;
}

@keyframes spinner-grow {
    0% {
        opacity: 0.2;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}
</style>