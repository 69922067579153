export default function (instance) {
  let apiModule = 'users';
  return {
    // Блок запросов для PageProjectList
    list(params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/`);
      } else {
        return instance.get(`api/${apiModule}/`, {
          params: { ordering: params },
        });
      }
    },
    getMe() {
      return instance.get(`api/${apiModule}/me/`);
    },
    updateMe(payload) {
      return instance.patch(`api/${apiModule}/me/`, payload);
    },
    update(id_, payload) {
      return instance.patch(`api/${apiModule}/${id_}/`, payload);
    },
    retrieve(id_) {
      return instance.get(`api/${apiModule}/${id_}/`);
    },
    create(payload) {
      return instance.postForm(
        `api/${apiModule}/`,
        payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    deleteUserImage(id_) {
      return instance.post(`api/profile/delete-image/${id_}/`);
    },

  };
}
