<template>
    <div v-if="page == 'capex'">
        <div class="row row__header">
            <div class="col col__header" :class="this.currentSortField === 'sections_order' ? 'selected' : ''"
                @click="sortTable('sections_order')">#
                <img :class="['imm_array_sort', getSortClasses('sections_order')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="col col__header" style="margin-left: 20px"
                :class="this.currentSortField === 'sections_section' ? 'selected' : ''"
                @click="sortTable('sections_section')">Область экспертизы
                <img :class="['imm_array_sort', getSortClasses('sections_section')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div class="col-4 col__header" :class="this.currentSortField === 'sections_expert' ? 'selected' : ''"
                @click="sortTable('sections_expert')">
                Эксперт
                <img :class="['imm_array_sort', getSortClasses('sections_expert')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
        </div>
        <div class="table__body">
            <div class="row row__content" v-for="(row, index) in sectionsFiltered" :key="row.id">
                <div class="col col__content">{{ index + 1 }}</div>
                <div class="col col__content" style="margin-left: 20px">{{ row.section }}</div>
                <div class="col-4 col__content">
                    <sip-avatar :user="showUserImage(row)" :style="{ marginRight: '15px' }" :size="30" />
                    <sip-select style="width: auto;" name="sections" :options="getSelectOptionsFromUser(row.users)"
                        v-model="row.expert" @change="getSelectSectionsValue($event.target.value, row.id, rowIndex)"
                        class="select__table" :disabled="expertPermission()" />
                </div>
            </div>
        </div>
    </div>
    <div class="table table__wrapper" v-if="page == 'userPage'">
        <div class="row row__header">
            <div class="col-1 col__header">#</div>
            <div class="col col__header">Область экспертизы</div>
            <div class="col-2 col__header"></div>
        </div>
        <div class="table__body">
            <div class="row row__content" v-for="(row, rowIndex) in sectionsArray" :key="row.id">
                <div class="col-1 col__content">{{ rowIndex + 1 }}</div>
                <div class="col col__content">{{ row.name }}</div>
                <div class="col-2 col__content">
                    <sip-checkbox disabled :modelValue="isUserSectionsId(row)" />
                </div>
            </div>
        </div>
    </div>
    <div class="table table__wrapper" v-if="page == 'userCreate'">
        <div class="row row__header">
            <div class="col-1 col__header">#</div>
            <div class="col col__header">Область экспертизы</div>
            <div class="col-2 col__header" style="margin-right: 9px">
                <sip-checkbox :modelValue="allSelected" @change="changeSelection" />
                <span>{{ allSelected ? "Снять выделение" : "Выбрать все" }}</span>
            </div>
        </div>
        <div class="table__body">
            <div class="row row__content" v-for="(row, rowIndex) in sectionsArray" :key="row.id">
                <div class="col-1 col__content">{{ rowIndex + 1 }}</div>
                <div class="col col__content">{{ row.name }}</div>
                <div class="col-2 col__content">
                    <sip-checkbox v-model="row.selected" />
                </div>
            </div>
        </div>
    </div>
    <div class="sections-create-project" v-if="page == 'projectCreate'">
        <div class="block">
            <label>Этап проекта<span class="form-label-required" v-if="creatingData[0].required">*</span></label>
            <sip-select v-model="tempCreationCommonData.stage" :options="stage" @change="updateSectionObjArrayFiltered"
                required />
        </div>
        <div class="table table__wrapper" v-if="tempCreationCommon()">
            <div class="row row__header">
                <div class="col col__header">
                    <sip-checkbox @change="e => e.target.checked ? selectAll() : unselectAll()" />
                </div>
                <div class="col-6 col__header">Область экспертизы</div>
                <div class="col col__header">Эксперт</div>
            </div>
            <div class="table__body">
                <div class="row row__content" v-for="row in sectionsObjArrayFiltered" :key="row.id"
                    :class="checkAndGetSectionUserBGClass(row)" :disabled="!row.selected">
                    <div class="col col__content">
                        <sip-checkbox v-model="row.selected" :modelValue="row.selected" />
                    </div>
                    <div class="col-6 col__content">{{ row.name }}</div>
                    <div class="col col__content">
                        <sip-avatar v-if="row.user" :user="row.user" :style="{ marginRight: '15px' }" :size="30" />
                        <sip-select style="padding-right:40px; width: auto;" :isNullAllowed="true" class="select__table"
                            :options="getSelectOptionsFromUser(row.users)" :disabled="!row.selected"
                            v-model="tempCreationExpertsData[row.id]" @change="showUserImageProject($event, row)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { mPermissions } from "@/utils/permissions";
import { mText } from "@/utils/text";
import { push } from 'notivue'

export default {
    name: "TableProjectDetail",
    mixins: [mDate, mFile, mPermissions, mFilters, mText],
    props: {
        sectionsArray: {
            type: Object,
            default: null,
        },
        page: {
            type: String,
            default: 'capex'
        },
        userSectionsIdArray: {
            type: Object,
            default: null,
        },
        creatingDataArray: {
            type: Object,
            default: null,
        },
        commonsData: {
            type: Object,
            default: null,
        },
        projectStage: {
            type: Object,
            default: null,
        },
        tempCreationSectionExpertsData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            tempCreationExpertsData: this.tempCreationSectionExpertsData,
            tempCreationCommonData: this.commonsData,
            creatingData: this.creatingDataArray,
            sectionsObjArray: this.sectionsArray,
            stage: this.projectStage,
            sortDirection: 1,
            currentSortField: "",
            userSectionsId: this.userSectionsIdArray,
            sectionsObjArrayFiltered: null,
        };
    },
    computed: {
        allSelected() {
            return this.sectionsObjArray.map(el => el.selected).filter(el => el == true).length == this.sectionsObjArray.length
        },
        sectionsFiltered() {
            let data = this.sectionsObjArray;
            if (this.currentSortField.startsWith("sections_")) {
                let sortField = this.currentSortField.replace('sections_', '')
                data.sort((a, b) => {
                    let a_ = 0;
                    let b_ = 0;
                    a_ = a[sortField];
                    b_ = b[sortField];
                    if (a_ == b_) return 1;
                    let comp = a_ < b_ ? -1 : 1;
                    return comp * this.sortDirection;
                });
            }
            return data;
        },
        ...mapGetters({
            getCurrentUser: "user/getUser",
        }),
    },
    methods: {
        updateSectionObjArrayFiltered(e) {
            this.sectionsObjArrayFiltered = this.sectionsObjArray.filter((el) => {
                return Object.values(el.stages).includes(Number(e.target.value));
            });
        },
        tempCreationCommon() {
            if (this.tempCreationCommonData == null) {
                return false
            } else {
                if (this.tempCreationCommonData.stage !== null) {
                    return true
                } else {
                    return false
                }
            }
        },
        checkAndGetSectionUserBGClass(row_) {
            if (row_.selected) {
                if (this.tempCreationExpertsData[row_.id] != "Выберите значение") {
                    return "row-green";
                }
                return "row-yellow";
            }
            return "";
        },
        getSelectOptionsFromUser(users_) {
            let options = [];
            users_.forEach((el) => {
                options.push({
                    id: el.id,
                    name: this.getFullName(el),
                });
            });
            return options;
        },
        changeSelection(e) {
            this.sectionsArray.forEach(el => el.selected = e.target.checked)
        },
        selectAll() {
            this.sectionsObjArray.forEach((el) => (el.selected = true));
        },
        unselectAll() {
            this.sectionsObjArray.forEach((el) => (el.selected = false));
        },
        showUserImage(row) {
            const user = row.users.find(user => user.id === row.expert);
            return user !== undefined ? user : null
        },
        showUserImageProject(event, row) {
            let tmp = row.users.find((user) => user.id === Number(event.target.value))
            let gg = this.sectionsObjArrayFiltered.findIndex((sectionsID) => sectionsID.id === row.id)
            this.sectionsObjArrayFiltered[gg] = { ...this.sectionsObjArrayFiltered[gg], user: tmp }
            return tmp
        },
        /** Доступ к checkbox "Руководитель проекта"  */
        isUserSectionsId(row) {
            return this.userSectionsId.findIndex((el) => el == row.id) != -1 ? true : false
        },

        getSelectSectionsValue(event, sectionId_) {
            this.updateSectionExpert(event, sectionId_);
        },
        async updateObject(apiSection, objectId, updates, successMessage, errorMessage) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };

            try {
                await this.$store.dispatch("project/patchObject", payload);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },
        /** Обновление "Области экспертизы"  */
        async updateSectionExpert(value_, sectionId_) {
            await this.updateObject(
                "sections",
                sectionId_,
                { expert: value_ },
                "Эксперт для секции успешно обновлен!",
                "Ошибка обновления эксперта для секции!"
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";
@import "@/css/pages/formProjectCreate.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.table__wrapper {
    min-width: 50%;
    max-width: 25% !important;
    width: 5%;
}


.page {
    height: 100%;
    z-index: 3;
    position: relative;
}

.select {
    &:hover {
        background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
        background-position-x: calc(100% - 15px) !important;
    }
}



.select__table {
    color: #363639 !important;

    &.short {
        width: 75% !important;
    }

    &:has(> option[value="null"]:checked) {
        color: $color-gray-2 !important;
    }
}

.select_table input[type=date] {
    font-family: "sf-pro-display-medium";
}

textarea {
    width: 100%;
    background: transparent;
}



.row.row__content.row__disabled {
    background: $color-gray-0;

    & .col__content {
        color: $color-gray-3;
    }

    & .col__content {
        color: $color-gray-3;
    }
}



.col__header {
    &.roadmap {
        padding: 10px;
    }
}

.content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    z-index: 3;
    position: relative;
}

.block {
    width: 15%;
}

.block>.text {
    font-family: $sf-light;
    font-size: 12px;

    &>span.bold {
        font-family: $sf-bold;
    }
}

.table__body {
    height: fit-content;
}

.row__content[disabled=true] {
    background-color: transparent !important;
}

select[disabled] {
    background-color: transparent !important;
    border: none !important;
}

.table__menu__btns .red,
.gray {
    width: 23px;
}

input {
    background-color: rgba(255, 255, 255, 0.5);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

.row__header .col__header:first-child,
.row__header .col__header.sm,
.col__content:first-child,
.col__content.sm {
    max-width: 40px !important;
    margin-left: 15px;
}

.sections-create-project {
    gap: 50px;
    display: flex;
    flex-direction: column;
}
</style>
