<template>
    <sip-modal-delete v-model:show="modalDeleteGanttVisible" :text="textForDelete"
        @deleteObject="deleteGantt(objectIdToDelete)" />
    <sip-modal-delete v-model:show="modalDeleteAllGanttVisible" :text="textForDelete"
        @deleteObject="deleteAllGantt()" />
    <sip-modal-import-gantt v-model:show="modalImportGanttVisible" :data="ganttData" @updateGantt="updateAllGantt" />

    <!-- Форма для добавления нового элемента дорожной карты -->
    <div class="block-group file-picker" v-if="!expertPermission()">
        <div class="sip-form">
            <div class="btn-sample" @click="getGanttTemplate">
                <div class="btn-sample-layout">
                    <img class="img-sample" src="@/assets/buttons/download.svg">
                    <div class="innerText-sample">
                        Скачать шаблон
                    </div>
                </div>
            </div>
            <div class="btn-import" @click="$refs.file.click()">
                <div class="btn-import-layout">
                    <input type="file" accept="text/csv" id="file" ref="file"
                        @change="handleCsvFileUpload($event.target.files, importGantt, Object.keys(this.ganttChartNewItem), $event)"
                        style="display: none" placeholder=" Импорт" />
                    <img class="img-import" src="@/assets/buttons/import_map.svg">
                    <div class="innerText-import">
                        Импорт карты
                    </div>
                </div>
            </div>
            <div class="btn-delete" @click="showDeleteModal('ganttAll', 'Всю дорожную карту')" type="button"
                :disabled="expertPermission() || ganttChart.length == 0">
                <div class="btn-delete-layout">
                    <img class="img-delete" src="@/assets/icons/trash.svg">
                    <div class="innerText-delete">
                        Удалить карту
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="display: grid; grid-template-columns: repeat(2, 47.5%); gap: 5%; ">
        <div style="width: auto;">
            <div class="row row__header">
                <div class="index col__header" :class="this.currentSortField === 'gantt_id' ? 'selected' : ''"
                    @click="sortTable('gantt_id')">#</div>
                <div class="gantt_name col__header roadmap"
                    :class="this.currentSortField === 'gantt_name' ? 'selected' : ''" @click="sortTable('gantt_name')">
                    Название
                    <img :class="['imm_array_sort', getSortClasses('gantt_name')]"
                        src="@/assets/icons/arrow_filter.svg" />
                </div>

                <div class="gantt_start_date col__header roadmap"
                    :class="this.currentSortField === 'gantt_start_date' ? 'selected' : ''"
                    @click="sortTable('gantt_start_date')">Дата начала
                    <img :class="['imm_array_sort', getSortClasses('gantt_start_date')]"
                        src="@/assets/icons/arrow_filter.svg" />
                </div>
                <div class="gantt_end_date col__header roadmap"
                    :class="this.currentSortField === 'gantt_end_date' ? 'selected' : ''"
                    @click="sortTable('gantt_end_date')">Дата окончания
                    <img :class="['imm_array_sort', getSortClasses('gantt_end_date')]"
                        src="@/assets/icons/arrow_filter.svg" />
                </div>
                <div class="duration col__header">Длительность</div>
                <div class="delete col__header"></div>
            </div>
            <div id="gantt_table" class="table__body custom-scrollbar">
                <transition-group name="list">
                    <div class="row row__content" v-for="(row, index) in ganttChartFiltered" :key="row.id">
                        <div class="index col__content">
                            {{ index + 1 }}
                        </div>
                        <div class="gantt_name col__content">
                            <sip-input type="text" class="input__table" style="min-width: 100%" v-model.trim="row.name"
                                @blur="getInputDate($event, row.id)" @keyup.enter="$event.target.blur()"
                                :disabled="expertPermission()" />
                        </div>
                        <div class="gantt_start_date col__content">
                            <sip-input name="start_date" type="date" class="input__table"
                                @blur="getInputDate($event, row.id)" :max="row.end_date" :disabled="expertPermission()"
                                :value="row.start_date" />
                        </div>
                        <div class="gantt_end_date col__content">
                            <sip-input name="end_date" type="date" @blur="getInputDate($event, row.id)"
                                class="input__table" :min="row.start_date" :disabled="expertPermission()"
                                :value="row.end_date" />
                        </div>
                        <div class="duration col__content">{{
                            getDifferenceInDays(row.start_date,
                                row.end_date) }} дней</div>
                        <div class="delete col__content">
                            <a class="table__menu__btns" v-if="!expertPermission()"
                                @click="showDeleteModal('gantt', row.name, row.id)">
                                <img src="@/assets/icons/trash.svg" :class="!expertPermission() ? 'gray' : ''" /></a>
                        </div>
                    </div>
                </transition-group>
                <div class="row row__create" v-if="!expertPermission()">
                    <div class="index col__content">
                    </div>
                    <div class="gantt_name col__content">
                        <sip-input class="input__table" type="text" :placeholder="'Введите значение'"
                            v-model.trim="ganttChartNewItem.name" :disabled="expertPermission()" />
                    </div>
                    <div class="gantt_start_date col__content">
                        <sip-input class="input__table" type="date" v-model.trim="ganttChartNewItem.start_date"
                            :max="ganttChartNewItem.end_date" :disabled="expertPermission()" />
                    </div>
                    <div class="gantt_end_date col__content">
                        <sip-input class="input__table" type="date" v-model.trim="ganttChartNewItem.end_date"
                            :min="ganttChartNewItem.start_date" :disabled="expertPermission()" />
                    </div>
                    <div class="duration col__content">-</div>
                    <div class="delete col__content">
                        <a class="table__menu__btns" href="#" @click="createGantt">
                            <img src="@/assets/icons/plus.svg" class="blue" /></a>
                    </div>
                </div>
            </div>
        </div>
        <sip-gantt v-if="ganttChart !== null" :isAssignShow="false" :showTooltip="false" :data="ganttChartFiltered" />
    </div>
</template>

<script>
import moment from "moment";
import { Modal } from "bootstrap";
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { mPermissions } from "@/utils/permissions";
import { push } from 'notivue'

export default {
    name: "RoadMapPage",
    mixins: [mDate, mFile, mPermissions, mFilters],
    props: {
        gantChartData: {
            type: Array,
            default: null,
        },
        isTempResult: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            ganttChart: this.gantChartData,
            ganttChartNewItem: {
                name: "",
                start_date: null,
                end_date: null,
            },
            currentSortField: 'gantt_id',
            ganttHeads: [
                'name', 'start_date', 'end_date',
            ],
            modalDeleteGanttVisible: false,
            modalImportGanttVisible: false,
            modalDeleteAllGanttVisible: false,
            ganttData: {},
            isFirstRowAHead: true,
            csvSeparator: ";",
            textForDelete: "",
        };
    },
    computed: {
        ganttChartFiltered() {
            let data = this.ganttChart;
            if (this.currentSortField.startsWith("gantt_")) {
                let sortField = this.currentSortField.replace('gantt_', '')
                data.sort((a, b) => {
                    let a_ = 0;
                    let b_ = 0;
                    if (['start_date', 'end_date'].includes(sortField)) {
                        a_ = new Date(a[sortField]);
                        b_ = new Date(b[sortField]);
                        return (a_ - b_) * this.sortDirection;
                    } else {
                        a_ = a[sortField];
                        b_ = b[sortField];
                        if (a_ == b_) return 1;
                        let comp = a_ < b_ ? -1 : 1;
                        return comp * this.sortDirection;
                    }
                });
            }
            return data
        },
    },
    methods: {

        getInputDate(event, id_) {
            if (this.tempInputNumberValue == event.target.value) {
                return;
            }

            let field = event.target.name;
            let value = event.target.value;

            this.updateGanttChart(field, value, id_);
            let item = this.ganttChart.find((el) => el.id == id_);
            item[field] = value
            item.duration = this.getDifferenceInDays(item.start_date, item.end_date);
        },
        showDeleteModal(modalType_, objectName_, objectId_ = null) {
            let user = JSON.parse(localStorage.getItem("user"));
            if (!user.is_project_manager & !user.is_admin) return;

            switch (modalType_) {
                case "gantt":
                    this.modalDeleteGanttVisible = true;
                    break;
                case "ganttAll":
                    this.modalDeleteAllGanttVisible = true;
                    break;
            }

            this.textForDelete = `Вы уверены, что хотите удалить ${objectName_}?`;
            this.objectIdToDelete = objectId_;
        },
        updateAllGantt(gantt_) {
            if (gantt_) {
                this.ganttChart = [...gantt_]
            }
        },
        handleGanttTempUpload(data) {
            this.ganttChart = [...data]
        },

        // [-]
        async handleCsvFileUpload(targetFiles_, callback_, headsRequired = [], $event) {
            if (!this.isTempResult) {
                this.handleGanttUpload($event)
                return
            }
            if (!targetFiles_ || targetFiles_.length === 0) return;
            let headsRequired_ = JSON.stringify(headsRequired);
            const file = targetFiles_[0];
            const reader = new FileReader();
            const dateRegexp = /^\d{2}.\d{2}.\d{4}$/;
            const dateFromFormat = "DD.MM.YYYY";
            const dateToFormat = "YYYY-MM-DD";
            reader.onload = (e) => {
                const content = e.target.result;
                let rows = content
                    .split("\n")
                    .map((row) => row.trim())
                    .filter((row) => row != "");
                let data = [];
                if (this.isFirstRowAHead) {
                    let heads = rows[0].split(this.csvSeparator);
                    rows.splice(0, 1);
                    rows.forEach((row) => {
                        let row_splitted = row.split(this.csvSeparator);
                        let tmp = Object.fromEntries(heads.map((_, i) => [heads[i], row_splitted[i].replace(",", ".")]));
                        data.push(tmp);
                    });
                    data.forEach((row, indx) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                        row["id"] = indx;
                    });

                    try {
                        if (JSON.stringify(heads) != headsRequired_) {
                            throw 'Структура файла не соответствует требуемой.'
                        }
                    } catch (err) {
                        push.error(`Ошибка загрузки файлов!`)
                        return
                    }
                } else {
                    data = rows.map((row) => {
                        return row.split(this.csvSeparator); // Удаляем пробелы и символы возврата каретки в каждом столбце
                    });
                    data.forEach((row, indx) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                        row["id"] = indx;
                    });
                }
                callback_(data);
            };
            reader.readAsText(file);
            $event.target.value = null;
        },

        importGantt(origin_data_) {
            if (origin_data_) Array.prototype.push.apply(this.ganttChart, origin_data_);
        },

        async handleGanttUpload(e) {
            if (!e.target.files || e.target.files.length === 0) return;
            const file = e.target.files[0];
            const reader = new FileReader();
            e.target.value = null;
            const dateRegexp = /^\d{2}.\d{2}.\d{4}$/;
            const dateFromFormat = "DD.MM.YYYY";
            const dateToFormat = "YYYY-MM-DD";

            reader.onload = (e) => {
                const content = e.target.result;
                let rows = content
                    .split("\n")
                    .map((row) => row.trim())
                    .filter((row) => row != "");
                let data = [];
                if (this.isFirstRowAHead) {
                    let heads = rows[0].split(this.csvSeparator);
                    rows.splice(0, 1);
                    rows.forEach((row) => {
                        let row_splitted = row.split(this.csvSeparator);
                        data.push(Object.fromEntries(heads.map((_, i) => [heads[i], row_splitted[i].replace(',', '.')])));
                    });
                    data.forEach((row) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                    });
                } else {
                    data = rows.map((row) => {
                        return row.split(this.csvSeparator); // Удаляем пробелы и символы возврата каретки в каждом столбце
                    });
                    data.forEach((row) => {
                        Object.keys(row).forEach((k) => {
                            if (row[k].match(dateRegexp)) {
                                row[k] = moment(row[k], dateFromFormat).format(dateToFormat);
                            }
                        });
                    });
                }
                if (this.isTempResult) {
                    this.handleGanttTempUpload(data)
                    return
                }

                this.ganttData = data;
                this.modalImportGanttVisible = true;
            };
            reader.readAsText(file);
        },

        // [-]
        async handleWithModal(action) {
            const spinner = document.getElementById("spinner");
            if (!spinner) {
                console.error("Modal element does not exist in the DOM.");
                return;
            }

            const pendingModal = new Modal(spinner);
            try {
                pendingModal.show();
                await action();
            } catch (error) {
                console.error(error);
                push.error("Произошла ошибка при выполнении операции!");
            } finally {
                pendingModal.hide();
            }
        },

        // [-]
        formatDatesAndSetProject(data, dateFields) {
            const formatter = new Intl.DateTimeFormat("en-ca", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
            const dateFormat = "DD.MM.YYYY";
            const projectId = this.$route.params.id_proj;

            data.forEach(el => {
                dateFields.forEach(field => {
                    const date = moment(el[field], dateFormat);
                    if (date.isValid()) {
                        el[field] = formatter.format(date.toDate());
                    }
                });
                el.project = projectId;
            });
        },

        async deleteAllGantt() {
            if (this.isTempResult) {
                this.ganttChart = [];
                push.success("Элементы дорожной карты удалены успешно!");
                return
            }
            const data = { project: this.$route.params.id_proj };
            await this.handleWithModal(async () => {
                await this.$api.sip.deleteGantt(data);
                push.success("Элементы дорожной карты удалены успешно!");
                this.ganttChart = [];
            });
        },

        addGanttChartItem(object_) {
            object_["duration"] = this.getDifferenceInDays(object_.start_date, object_.end_date);
            this.ganttChart = [...this.ganttChart, object_];
            this.ganttChartNewItem = {
                name: "",
                start_date: null,
                end_date: null,
            };
        },

        addGanttChartTempItem() {
            let emptyItem = {
                id: Date.now(),
                name: this.ganttChartNewItem.name,
                start_date: this.ganttChartNewItem.start_date,
                end_date: this.ganttChartNewItem.end_date,
            };
            this.ganttChart = [...this.ganttChart, emptyItem];
            this.ganttChartNewItem = {
                name: "",
                start_date: null,
                end_date: null,
            };
            this.$emit('update:tempCreationGanttChartData', this.ganttChart)
        },

        // [-]
        /** Функция скачивания шаблона для диаграммы Ганта  */
        async getGanttTemplate() {
            try {
                this.$store.dispatch("project/getTemplate", 'gantt').then((response) => {
                    this.forceDownload(
                        response,
                        'gantt_template.csv',
                        "", false, true
                    );
                });
            } catch (error) {
                push.error(`Ошибка выгрузки данных!`)
            }
        },

        /**  Создание элемента диаграммы Ганта */
        async createGantt() {
            if (this.isTempResult) {
                this.addGanttChartTempItem()
                return
            }
            let payload = {};
            Object.keys(this.ganttChartNewItem).forEach((key) => {
                if (this.ganttChartNewItem[key] != null && this.ganttChartNewItem[key] != "") {
                    payload[key] = this.ganttChartNewItem[key];
                }
            });
            payload["project"] = this.$route.params.id_proj;
            try {
                const data = (await this.$api.sip.create("gantt", payload)).data;
                this.addGanttChartItem(data);
            } catch (error) {
                push.error(`Ошибка создания диаграммы Ганта!`)
            }
        },

        // [-]
        /** Блок запросов на удаление объектов  */
        updateArray(array, id) {
            return array.filter((el) => el.id !== id);
        },

        // [-]
        /**  Удаление элемента */
        async deleteItem(id, type, arrayName, successMessage, errorMessage) {
            try {
                await this.$api.sip.delete(id, type);
                this[arrayName] = this.updateArray(this[arrayName], id);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        // [-]
        /**  Удаление элмента диаграммы Ганта */
        async deleteGantt(id) {
            if (this.isTempResult) {
                this.ganttChart = [...this.ganttChart.filter((el) => el.id != id)];
                this.$emit('update:tempCreationGanttChartData', this.ganttChart)
                return
            }
            await this.deleteItem(
                id,
                "gantt",
                "ganttChart",
                "Элемент таблицы дорожной карты успешно удален!",
                "Ошибка удаления элемента таблицы дорожной карты!"
            );
        },

        // [-]
        /** Общая функция для обновления объектов  */
        async updateObject(apiSection, objectId, updates, successMessage, errorMessage) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };

            try {
                await this.$store.dispatch("project/patchObject", payload);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        // [-]
        /** Обновление элемента диаграммы Ганта  */
        async updateGanttChart(field_, value_, itemId_) {
            await this.updateObject(
                "gantt",
                itemId_,
                { [field_]: value_ },
                "Значение дорожной карты успешно обновлено!",
                "Ошибка обновления дорожной карты!"
            );
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";


.file-picker {
    // max-width: 410px;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.6%;
    position: relative;
}

.row__header .col__header:first-child,
.row__header .col__header.sm,
.col__content:first-child,
.col__content.sm {
    max-width: 40px !important;
    justify-content: flex-start;
    margin-left: 15px;
}

.sip-form {
    gap: 10px;
    justify-content: flex-start;
}

.input__table {
    padding: 0px;
}


.table__body {
    overflow-y: auto;
    height: 62vh;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 0px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}


.table__menu__btns .red {
    width: 18px;
}

.select {
    &:hover {
        background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
        background-position-x: calc(100% - 15px) !important;
    }
}

.select__table:has(> option[value=null]:checked) {
    background: url("@/assets/icons/select_arrow_blue.svg") no-repeat right !important;
    background-position-x: calc(100% - 15px) !important;
    color: #2F9ADB !important;
}

.input__table {
    font-size: 13px;

    &::placeholder {
        font-size: 13px;
        color: #2F9ADB;
        font-family: "sf-pro-display-medium";
    }

    &.short {
        max-width: fit-content !important;
        width: 75% !important;
    }

    &input[type=date]::placeholder {
        color: #2f99db8a;
    }
}

.select__table {
    font-size: 13px;

    &.short {
        width: 75% !important;
    }
}

.row__create {
    background-color: #E8F6FF;

    &:hover {
        background-color: #E8F6FF;
    }

    select,
    input {
        color: #0c69a2;
    }

    input[type=date] {
        color: #2F9ADB;
    }

    &.duration {
        color: #2F9ADB;
    }

    input[type=date]::-webkit-calendar-picker-indicator {
        filter: invert(51%) sepia(99%) saturate(381%) hue-rotate(161deg) brightness(87%) contrast(96%);
    }
}

input[type=date] {
    font-family: "sf-pro-display-medium";
}

.row.row__content.row__disabled {
    background: $color-gray-0;

    & .col__content {
        color: $color-gray-3;
    }

    & .col__content {
        color: $color-gray-3;
    }
}
</style>