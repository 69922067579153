<template>
    <transition name="fade">
        <div class="modal-window" v-if="show" @click="hideModalWindow">
            <div class="modal-window__content">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'sip-modal-base',
    props: {
        show: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        hideModalWindow() {
            this.$emit('update:show', false);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.bg-red {
	background-color: #fe6259;
	color: white;
}
// .bg-blue {
// 	background-color: $color-blue-4;
// 	color: white;
// }
.filter-white {
	filter: $filter-white;
}

.modal-window {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgb(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	z-index: 100;
}

.modal-window__content {
	margin: auto;
	background: white;
	min-height: 100px;
	min-width: 450px;
	border-radius: 5px;
	box-shadow: 0px 20px 20px 0px rgba(black, 0.1);
	overflow: hidden
}

.header {
	padding: 10px;
	border-bottom: 1px solid #d9d9d9;
	display: flex;
	gap: 10px;
	font-size: 13px;

	&>img {
		height: 20px;
	}
}

.content {
	padding: 20px 10px;
	margin: 0;
}

.footer {
	padding: 10px;
	gap: 10px;
	display: flex;
}

.block {
	margin: 2%;
	width: auto;
}

.bg-red {
	width: 100px !important;
	background-color: transparent;
	border: 1px solid #fe6259;
	color: #fe6259;
}

.sip-form {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	margin: 0 2% 0 2%;
}

.form-label-required {
	margin-left: 3px;
	color: red;
}

.fade-enter-active,
.fade-leave-active {
	opacity: 1;
	transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	transition: opacity 0.1s ease-in-out;
	opacity: 0;
}
</style>