import '@/css/customStyleNotivue.scss';

const defaultNotificationOptions = {
    success: {
        title: '',
        message: '',
        duration: 2000,
        ariaLive: 'polite',
        ariaRole: 'status'
    }
};

export const defaultOptionsCreateNotivue = {
    position: 'top-right',
    limit: 4,
    enqueue: true,
    avoidDuplicates: true,
    pauseOnTabChange: true,
    teleportTo: 'body',
    animations: {
        enter: 'Notivue__enter',
        leave: 'Notivue__leave',
        clearAll: 'Notivue__clearAll'
    },
    transition: 'transform 0.35s cubic-bezier(0.5, 1, 0.25, 1)',
    notifications: defaultNotificationOptions
};