<template>
    <div class="progress_wrapper" :style="setWrapperStyle">
        
        <div class="progress" :style="setPsBarStyle">
            <div :class="getPsColor(value)" :style="setPsBarColoredStyle" 
                :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100" role="progressbar">
            </div>
        </div>

        <div class="progress_bar_caption" :class="getTextColor(value)" :style="setCaptionStyle">
            <div v-if="type == 'nav'" class="text_gip">{{ textValue }}</div>
            <div :style="setFontSize">
                {{ value }}
                <span v-if="showPercent">%</span>
            </div>
        </div>

    </div>
</template>


<script>
    export default {
        name: 'sip-progress',

        props: {
            type: {
                type: [Number, String],
                required: false,
            },
            textValue: {
                type: [Number, String],
                default: "",
                required: false,
            },
            value: {
                type: [Number, String, Object, null],
                default: 0,
                required: true,
            },
            height: {
                type: Number,
                default: 50,
            },
            width: {
                type: Number,
                default: 50,
            },
            showPercent: {
                type: Boolean,
                default: false
            },
            justifyContent: {
                type: String,
                default: 'flex-start'
            },
            percentFontSize: {
                type: Number,
                default: 14,
            }
        },

        methods: {
            // /**  красим полоску прогресс-бара в зависимости от поступающего значения */
            getPsColor(value_) {
                if (value_ < 25) {
                    return 'bg-red';
                } else if (value_ < 50) {
                    return 'bg-yellow';
                } else if (value_ < 100) {
                    return 'bg-blue';
                } else {
                    return 'bg-blue';
                }
            },

            /**  красим текстовку процентов в зависимости от поступающего значения */
            getTextColor(value_) {
                if (value_ < 25) {
                    return 'font-color-red';
                } else if (value_ < 50) {
                    return 'font-color-yellow';
                } else if (value_ < 100) {
                    return 'font-color-blue';
                } else {
                    return 'font-color-blue';
                }
            },
        },

        computed: {
            setWrapperStyle() {
                let myAlignType = this.type == 'analytics' ? "flex-end" : "center";
                let myMarginTop = this.type == 'analytics' ? "-5px" : "";
                return {
                    justifyContent: `${this.justifyContent}`,
                    alignItems: `${myAlignType}`,
                    marginTop: myMarginTop,
                }
            },
            
            setFontSize() {
                return {
                    fontSize: `${this.percentFontSize}px`,
                    lineHeight: 'normal',
                }
            },
            
            setPsBarStyle() {
                return {
                    maxWidth: `${this.width}%`, 
                    height: `${this.height}px`, 
                    marginBottom: `${this.type == 'analytics' ? 5 : ''}px`,
                }
            },
            
            setPsBarColoredStyle() {
                return {
                    width: `${this.value}%`,
                    borderRadius: `${6}px`,
                }
            },

            setCaptionStyle() {
                let myPadding = this.justifyContent !== 'flex-start' ? '0 2%' : '0 7%';
                let myWidth = this.type == 'analytics' ? '' : '100%';
                return {
                    justifyContent: this.justifyContent,
                    padding: myPadding,
                    width: myWidth,
                }
            },
        },
    }
</script>


<style lang="scss" scoped>
    @import "@/css/components/progressbar.scss";
</style>
