<template>
	<transition name="fade">
		<div class="modal-window" v-if="show">
			<div class="modal-window__content">
				<div class="header bg-red">
					<img class="filter-white" src="@/assets/icons/info.svg" alt="">
					<span>Удаление</span>
				</div>
				<div class="content">
					{{ text }}
				</div>
				<div class="footer">
					<sip-button class="bg-red" @click="deleteObject">Да</sip-button>
					<sip-button @click="hideModalWindow">Отмена</sip-button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "sip-modal-delete",
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: "",
		},
	},
	watch: {
		show() {
			if (this.show === false) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
			} else {
				window.addEventListener("keyup", this.onEscapeKeyUp);
			}
		},
	},
	methods: {
		onEscapeKeyUp(event) {
			if (event.which === 27) {
				this.hideModalWindow();
			} else if (event.which === 13) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
				this.deleteObject();
				this.hideModalWindow();
			}
		},
		hideModalWindow() {
			this.$emit("update:show", false);
		},
		deleteObject() {
			this.$emit("deleteObject", true);
			this.hideModalWindow();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

.bg-red {
	background-color: #fe6259;
	color: white;
}

.color-red {
	color: #fe6259;
}

.filter-white {
	filter: $filter-white;
}

.modal-window {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgb(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	z-index: 100;
}

.modal-window__content {
	margin: auto;
	background: white;
	min-height: 50px;
	min-width: 300px;
	border-radius: 5px;
	box-shadow: 0px 20px 20px 0px rgba(black, 0.1);
	overflow: hidden
}


.header {
	padding: 10px;
	border-bottom: 1px solid #d9d9d9;
	display: flex;
	gap: 10px;
	font-size: 16px;
}

.content {
	padding: 20px 10px;
}

.footer {
	padding: 10px;
	gap: 10px;
	display: flex;
}

.fade-enter-active,
.fade-leave-active {
	opacity: 1;
	transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	transition: opacity 0.1s ease-in-out;
	opacity: 0;
}
</style>
