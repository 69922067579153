// import { sidebar as sidebar } from '@/database/generalBase.js'
import { table as table, projectInfo as importedProjectInfo } from '@/database/generalBase.js'

export function getTBodyWidth(document_) {
    let domElement = document_.getElementsByClassName('big-block');
    let tableWidth = domElement[0].offsetWidth - table.paddings * 2 - table.margins * 2;
    return tableWidth;
}

export function getTBodyHeight(document_) {
    let domElement;

    domElement = document_.getElementsByClassName('big-block');
    let bigBlockHeight = domElement[0].offsetHeight;
    domElement = document_.getElementsByClassName('navbar');
    let navBarHeight = domElement[0].offsetHeight;

    // получаем данные по высоте и паддингу нужных элементов
    domElement = document_.getElementsByClassName('page-second-screen');
    let pageSecScreenPadding = window.getComputedStyle(domElement[0], null).getPropertyValue('padding-left')
    domElement = document_.getElementsByClassName('t-head');
    let tHeadHeight = domElement[0].offsetHeight;

    // // избавляем паддинг от подстроки "px"
    let padd = pageSecScreenPadding;
    pageSecScreenPadding = Number(padd.slice(0, padd.indexOf("px")))

    // // считаем высоту элемента tBody
    return bigBlockHeight - (navBarHeight + pageSecScreenPadding + tHeadHeight);
}

export function getTableHeight(document_) {
    let domElement;
    domElement = document_.getElementsByClassName('big-block');
    let bigBlockHeight = domElement[0].offsetHeight;
    domElement = document_.getElementsByClassName('navbar');
    let navBarHeight = domElement[0].offsetHeight;
    return bigBlockHeight - navBarHeight
}

export function setLocalStorage(varName_, pageType_, rowObject_) {
    let tempProjectInfo;

    if (rowObject_) {
        tempProjectInfo = JSON.parse(localStorage.getItem(varName_));
        tempProjectInfo[pageType_] = rowObject_;

        // for (let key in tempProjectInfo) {
        //     if (key != 'tableStatus' && key != pageType_) {
        //         tempProjectInfo[key] = null;
        //     }
        // }

        if (pageType_ != 'section') {
            tempProjectInfo.section = null;
        }
    } else {
        tempProjectInfo = { ...importedProjectInfo };
    }

    tempProjectInfo.tableStatus = pageType_;
    localStorage.setItem(varName_, JSON.stringify(tempProjectInfo));
}

// получаем высоту суммы элементов: Navbar + padding у 'page-second-screen'
export function getNavPaddHeight(document_) {
    let domElement;

    domElement = document_.getElementsByClassName('navbar');
    let navBarHeight = domElement[0].offsetHeight;

    // получаем данные по высоте и паддингу нужных элементов
    domElement = document_.getElementsByClassName('page-second-screen');
    let pageSecScreenPadding = window.getComputedStyle(domElement[0], null).getPropertyValue('padding-top')
    
    // // избавляем паддинг от подстроки "px"
    let padd = pageSecScreenPadding;
    pageSecScreenPadding = Number(padd.slice(0, padd.indexOf("px")))

    // считаем суммарную высоту
    return navBarHeight + pageSecScreenPadding;
}

// получаем высоту header канваса (белого поля)
export function getHeaderHeight(document_) {
    let domElement;

    domElement = document_.getElementsByClassName('header');
    let headerHeight = domElement[0].offsetHeight;

    // получаем данные по высоте и паддингу нужных элементов
    domElement = document_.getElementsByClassName('tb-background');
    let pageCanvasPadding = window.getComputedStyle(domElement[0], null).getPropertyValue('padding-top')
    
    // // избавляем паддинг от подстроки "px"
    let padd = pageCanvasPadding;
    pageCanvasPadding = Number(padd.slice(0, padd.indexOf("px")))

    // считаем суммарную высоту
    return headerHeight + pageCanvasPadding;
}