export default function (instance) {
  let apiModule = 'question';
  let apiSections = {
    questions: 'questions',
    groups: 'groups',
    categories: 'categories',
    characteristics: 'characteristics',
    sections: 'sections',
    stages: 'stages',
  };
  return {
    list(section_ = 'questions', params = null) {
      if (params === null) {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/`);
      } else {
        return instance.get(`api/${apiModule}/${apiSections[section_]}/`, {
          params: { ordering: params },
        });
      }
    },
  };
}
