<template>
    <div class="preloader">
        <!-- <div class="spinner-grow text-secondary" role="status"></div> -->
        <img class="img-middle-buttons" src="@/assets/logos/logo_full.svg" alt="" />
        <!-- <span class="preloader__text">Загрузка данных...</span> -->
    </div>
</template>

<script>
export default {
    name: 'sip-preloader',
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-middle-buttons {
    filter: invert(73%) sepia(16%) saturate(938%) hue-rotate(159deg) brightness(84%) contrast(95%);
    animation: shooting-star 1700ms ease-in-out infinite, delay-animation 2000ms linear infinite;
}

@keyframes shooting-star {
    from {
        transform: scale(0.9);
        opacity: 0;
    }

    85% {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(1);
        opacity: 0;
    }

}

.preloader__text {
    margin-left: 20px;
    color: $color-gray-4;
}
</style>
