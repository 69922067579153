<template>
	<nav class="navbar">
		<div class="main-container">
			<div class="breadcrumb-layout">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item"
							:class="item.class">
							<a v-if="item.route" href="#" @click="$router.push({ name: item.route })">{{ item.label
								}}</a>
							<span v-else>{{ item.label }}</span>
						</li>
					</ol>
				</nav>
			</div>
			<div class="account-layout">
				<div class="col__content" v-if="checkIsNavbarProgressShow()">
					<img class="flag" :src="responseStatus(totalAnswers, totalQuestions)"
						:style="setFlagColor(totalAnswers, totalQuestions)" />
					<span :style="setColorTotalAnswers(totalAnswers, totalQuestions)">{{ totalAnswers }}</span>
					<span class="muted">/</span>
					<span class="muted">{{ totalQuestions }}</span>
				</div>
				<div class="navbar-divider" v-if="checkIsNavbarProgressShow()"></div>
				<div class="navbar-progress-layuot">
					<sip-progress v-if="checkIsNavbarProgressShow()" class="navbar-progress"
						style="flex-direction: column-reverse; gap: 0.3em" :value="indexGIP" :type="'nav'"
						:textValue="`ГИП ${navbarProgressType}`" :width="200" :height="6" :showPercent="true"
						:justifyContent="'space-between'" 
					/>
				</div>
				<div class="navbar-divider" v-if="checkIsNavbarProgressShow()"></div>
				<div @click="routeToCurrentUser()" class="navbar-avatar" style="cursor: pointer;">
					<div class="button-text button-text-avatar">{{ userName }}
						<div class="fullfilled" :style="roleColor">{{ userRole }}</div>
					</div>
					<sip-avatar class="navbar__avatar" :user="user" :size="40" />
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { mPermissions } from "@/utils/permissions";
import { mapGetters } from "vuex";
import { mAnswer } from "@/utils/answer.js"

export default {
	name: "NavBar",
	emits: ["setProjectInfo"],
	mixins: [mPermissions, mAnswer],
	props: {
		projectInfo: Object,
	},
	data() {
		return {
			userName: null,
			isUserName: false,
			breadcrumbs: [],
			routesMapping: {
				project: "проект",
				section: "область",
				details: "проект",
				analytics: "проект",
			}
		};
	},
	watch: {
		$route(val_, oldVal_) {
			if (val_ !== oldVal_) {
				this.updateBreadcrumbs();
				this.$store.dispatch("project/fetchIndexGIP", val_);
				this.$store.dispatch("project/fetchAnswerQuestions", val_);
			}
		},
		activeViewUser() {
			this.updateBreadcrumbs();
		},
		user(newUser) {
			this.getUserFullName(newUser, true);
		}
	},
	created() {
		this.userFullName(this.user, true);
		this.updateBreadcrumbs();
		this.$store.dispatch("project/fetchIndexGIP", this.$route);
		this.$store.dispatch("project/fetchAnswerQuestions", this.$route);
	},
	computed: {
		...mapGetters({
			user: "user/getUser",
			indexGIP: "project/getIndexGIP",
			activeViewUser: "domprops/getActiveViewUser",
			totalAnswers: "project/getTotalAnswers",
			totalQuestions: "project/getTotalQuestions",
		}),

		checkProductionMode() {
			return process.env.NODE_ENV === "production";
		},

		userRole() {
			return this.getUserRole(this.user);
		},

		roleColor() {
			return {
				color: this.checkAdmin(this.user) ? '#439DDB' : 'AFAFB9'
			}
		},

		navbarProgressType() {
			if (!Object.keys(this.routesMapping).includes(this.$route.name)) return "";
			return `(${this.routesMapping[this.$route.name]})`;
		},
	},

	methods: {
		userFullName(user, isActualNameProfile) {
			this.getUserFullName(user, isActualNameProfile);
		},

		/** Переход к юзеру  */
		routeToCurrentUser() {
			this.$router.push({ name: "user", params: { id_user: this.user.id } });
		},
		/** Получение имени и фамилии с точкой  */
		getUserFullName(user, isActualNameProfile) {
			if (user) {
				let first_name = '';
				let last_name = '';
				if (user.first_name) first_name = user.first_name.slice(0, 1) + ".";
				if (user.last_name) last_name = user.last_name;
				if (first_name == '' && last_name == '') return 'Эрнст Неизвестный';
				if (isActualNameProfile) {
					this.userName = `${last_name} ${first_name}`
					return
				}
				return `${last_name} ${first_name}`
			}
		},
		/** Получение url картнки  */
		getUserImageURL() {
			if (this.user) {
				return this.user.image;
			}
			return;
		},
		/** Получение id user  */
		getCurrentUserID() {
			if (this.user) {
				return this.user.id;
			}
			return;
		},
		/** Проверка отображается ли прогресс на панели навигации  */
		checkIsNavbarProgressShow() {
			let projectRoutes = ["project", "section", "details", "analytics"];
			return projectRoutes.includes(this.$route.name) ? true : false;
		},
		/** Получение имени проекта  */
		getProjectName(len_ = null) {
			if (len_) {
				if (localStorage.getItem("projectName")?.count > len_) {
					return localStorage.getItem("projectName").slice(0, len_).concat("...");
				}
				return localStorage.getItem("projectName")?.slice(0, len_);
			}
			return localStorage.getItem("projectName");
		},
		/** Получение имени секции  */
		getSectionName(len_ = null) {
			if (len_) {
				if (localStorage.getItem("sectionName")?.count > len_) {
					return localStorage.getItem("sectionName").slice(0, len_).concat("...");
				}
				return localStorage.getItem("sectionName").slice(0, len_);
			}
			return localStorage.getItem("sectionName");
		},
		/** Обновление хлебных крошек  */
		updateBreadcrumbs() {
			const routeName = this.$route.name;
			this.breadcrumbs = [];

			if (["projectList", "createProject", "project", "section", "analytics", "details"].includes(routeName)) {
				this.breadcrumbs.push({
					label: "Проекты",
					route: ["createProject", "project", "section", "analytics", "details"].includes(routeName)
						? "projectList"
						: null,
					class: routeName === "projectList" ? "text-gray" : "text-blue",
				});
			}

			if (["userList", "user", "profile", "createUser"].includes(routeName)) {
				this.breadcrumbs.push({
					label: "Пользователи",
					route: ["user", "profile", "createUser"].includes(routeName) ? "userList" : null,
					class: routeName === "userList" ? "text-gray" : "text-blue",
				});
			}

			if (routeName === "createProject") {
				this.breadcrumbs.push({ label: "Создание проекта", class: "text-gray" });
			}

			if (routeName === "createUser") {
				this.breadcrumbs.push({ label: "Регистрация пользователя", class: "text-gray" });
			}

			if (["project", "section", "analytics", "details"].includes(routeName)) {
				this.breadcrumbs.push({
					label: this.getProjectName(64),
					route: ["section", "analytics", "details"].includes(routeName) ? "project" : null,
					class: routeName === "project" ? "text-gray" : "text-blue",
				});
			}
			if (["user"].includes(routeName)) {
				this.breadcrumbs.push({
					label: this.activeViewUser !== null ? this.getUserFullName(this.activeViewUser, false) : null,
					route: ["user", "profile", "createUser"].includes(routeName) ? "userList" : null,
					class: routeName === "userList" ? "text-gray" : "text-blue",
				});
			}
			if (routeName === "section") {
				this.breadcrumbs.push({ label: this.getSectionName(), class: "text-gray" });
			}

			if (routeName === "analytics") {
				this.breadcrumbs.push({ label: "Аналитика", class: "text-gray" });
			}

			if (routeName === "details") {
				this.breadcrumbs.push({ label: "О проекте", class: "text-gray" });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@charset "utf-8";
@import "@/css/navbar.scss";

.breadcrumb {
	display: flex;
	align-items: center;
}

.breadcrumb-item+.breadcrumb-item::before {
	content: "\2022";
	margin: 0 8px;
	color: var(--breadcrumb-divider-color);
	font-size: 0.75em;
	align-items: center;
	display: flex;
}

.breadcrumb-item::before {
	height: 74px;
}

.text-blue {
	--breadcrumb-divider-color: #439ddb;
}

.text-gray {
	--breadcrumb-divider-color: #b0adb9;
}

.nav-divider {
	height: 20px;
	width: 1px;
	background-color: #afafaf75;
}

.sidebar-buttonlist-inner {
	width: 100%;
	margin: 0px;
	padding: 0px;
}

.sidebar-button {
	width: 100%;
	height: 74px;
	display: flex;
	align-items: center;
	padding-right: 27px;
	gap: 3em;
}

.button-text {
	color: #363639;
	margin: 0;
	font-size: 14px;
	text-decoration: none;
	letter-spacing: 0.5px;
}

/* #endregion */
/* #region - avatar-logo */
.avatar-block {
	width: 100%;
	display: flex;
	align-items: flex-end;
}

.button-text-avatar {
	font-size: 15px;
	text-decoration: none;
	color: #6d6d72;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-transform: capitalize;
	line-height: normal;
}


.flag {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	margin-right: 5px;
	display: flex;
}

.muted {
	color: $color-gray-3;
}

.col__content {
	display: flex;
	font-size: 13px;
	width: 95px;
	align-items: center;
	gap: 4px;
}

.fullfilled {
	font-size: 10px;
}

.navbar-progress-layuot {
	width: 205px;
}
</style>
