import { createApp } from "vue"
import App from "./App.vue"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import router from "@/router/router";
import store from "@/store/index";
import apiPlugin from "@/plugins/api";
import components from '@/components/UI/index';
import Notifications from '@kyvg/vue3-notification';
import { createNotivue } from 'notivue'
import VueApexCharts from "vue3-apexcharts";

import { defaultOptionsCreateNotivue } from "@/utils/defaultNotificationOptions.js"

const notivue = createNotivue({
    ...defaultOptionsCreateNotivue
})

const app = createApp(App);

app.use(notivue)
app.use(router);
app.use(store);
app.use(apiPlugin);
app.use(Notifications);

app.use(VueApexCharts);
components.forEach(component => {
    app.component(component.name, component);
})
app.mount("#app");

app.config.productionTip = false

router.beforeEach((to, from, next) => {
    let user
    if (!store.getters['user/getUser']) store.dispatch("user/fetchUser")
    user = store.getters['user/getUser']
    // let user = JSON.parse(localStorage.getItem('user'));
    if (to.matched.some(record => record.meta.requiresLogin)) {
        if (!localStorage.getItem('refresh')) {
            next({ name: 'login' })
        } else {
            if (to.name == "userList" && !user.is_project_manager && !user.is_admin) next({ name: 'home' })
            if (to.name == "createProject" && !user.is_project_manager && !user.is_admin) next({ name: 'home' })
            if (to.name == "createUser" && !user.is_admin) next({ name: 'home' })
            next()
        }
    } else {
        next()
    }
})