<template>
    <textarea class="textarea__detail" placeholder="Введите значение" @input="updateTextarea" :value="modelValue"
        autocomplete="on"></textarea>
</template>

<script>
export default {
    name: 'sip-textarea',
    props: {
        modelValue: [String, Number],
    },
    methods: {
        updateTextarea(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/css/components/textarea.scss';
</style>