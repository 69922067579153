<template>
	<transition name="fade">
		<div class="modal-window" v-if="show">
			<div class="modal-window__content">
				<div class="header bg-blue">
					<img class="filter-white" src="@/assets/buttons/upload_file.svg" alt="">
					<span>Импорт профиля капитальных вложений</span>
				</div>
				<div class="content">
					<div class="table table__wrapper" v-if="validatedDataFiltered.length != 0">
						<div class="row row__header">
							<div class="col-1 col__header" style="cursor: default">#</div>
							<div class="col col__header" :class="this.currentSortField === 'name' ? 'selected' : ''"
								@click="sortTable('name')">Название<img
									:class="['imm_array_sort', getSortClasses('name')]"
									src="@/assets/icons/arrow_filter.svg" />
							</div>
							<div class="col col__header"
								:class="this.currentSortField === 'start_date' ? 'selected' : ''"
								@click="sortTable('start_date')">Дата начала<img
									:class="['imm_array_sort', getSortClasses('start_date')]"
									src="@/assets/icons/arrow_filter.svg" /></div>
							<div class="col col__header" :class="this.currentSortField === 'end_date' ? 'selected' : ''"
								@click="sortTable('end_date')">Дата окончания<img
									:class="['imm_array_sort', getSortClasses('end_date')]"
									src="@/assets/icons/arrow_filter.svg" /></div>
							<div class="col col__header" :class="this.currentSortField === 'status' ? 'selected' : ''"
								@click="sortTable('status')">Статус<img
									:class="['imm_array_sort', getSortClasses('status')]"
									src="@/assets/icons/arrow_filter.svg" /></div>
						</div>
						<div class="table__body">
							<div class="row row__content" v-for="(row, index) in validatedDataFiltered"
								:class="getStatusRowClass(row.status)" :key="row">
								<div class="col-1 col__content">{{ index + 1 }}</div>
								<div class="col col__content" :class="row.name.status != 'ok' ? 'input__error' : ''">
									<sip-input type="text" class="input__table"
										:class="{ 'input__changed': row.name.changed }"
										@change="markAsChanged(row, 'name')" v-model="row.name.value" precision="2" />
								</div>
								<div class="col col__content"
									:class="row.start_date.status != 'ok' ? 'input__error' : ''">
									<sip-input type="date" class="input__table" v-model="row.start_date.value"
										:placeholder="row.start_date.status"
										:class="{ 'input__changed': row.start_date.changed }"
										@change="markAsChanged(row, 'start_date')" />
								</div>
								<div class="col col__content"
									:class="row.end_date.status != 'ok' ? 'input__error' : ''">
									<sip-input type="date" class="input__table" v-model="row.end_date.value"
										:placeholder="row.end_date.status"
										:class="{ 'input__changed': row.end_date.changed }"
										@change="markAsChanged(row, 'end_date')" />
								</div>
								<div class="col col__content">
									<sip-badge :color="getStatusColor(row.status)">{{ row.status
										}}</sip-badge>
									<sip-badge v-if="row.changed"
										:color="getStatusColor(row.status)">Изменено</sip-badge>
								</div>
							</div>
						</div>
					</div>
					<error-list v-else-if="errors.length != 0" :errors="errors" />
					<no-content-inline v-else :message="`Нет данных`" />

				</div>
				<div class="footer">
					<sip-button class="bg-blue" v-if="!checkNotToSave" @click="saveData">Импорт</sip-button>
					<sip-button class="bg-yellow" v-else @click="revalidateData">Валидация</sip-button>

					<sip-button @click="hideModalWindow">
						Отмена
					</sip-button>
					<label class="data__warning" v-if="isDataChanged">Данные были изменены. Требуется повторная
						валидация.</label>
					<label class="data__ready">Готово к импорту:
						<div>{{ getReadyRows }} / {{ validatedData.length }}</div>
					</label>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { Modal } from "bootstrap";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { measurementUnits } from "@/database/utils";
import { push } from 'notivue'

export default {
	name: "sip-modal-import-gantt",
	mixins: [mFile, mFilters],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
	},
	emits: ["updateGantt", "update:show"],
	data() {
		return {
			errors: [],
			validatedData: [],
			isDataChanged: false,
			measurementUnits: Object.keys(measurementUnits),
			measurementUnitOptions: [],
			currentSortField: 'status',
			sortDirection: 1,
		};
	},
	watch: {
		data(newValue_) {
			if (newValue_) {
				this.validateData(newValue_);
			}
		},
		show() {
			if (this.show === false) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
			} else {
				window.addEventListener("keyup", this.onEscapeKeyUp);
			}
		},
	},
	methods: {
		onEscapeKeyUp(event) {
			if (event.which === 27) {
				this.hideModalWindow();
			} else if (event.which === 13) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
				this.hideModalWindow();
			}
		},
		hideModalWindow() {
			this.validatedData = [];
			this.errors = [];
			this.$emit("update:show", false);
		},
		getStatusRowClass(status) {
			if (!status) return "";
			if (status == "to_create") return "row__create";
			if (status == "to_update") return "row__update";
			if (status == "existed") return "row__existed";
			if (status == "error") return "row__error";
			return "";
		},
		getStatusColor(status) {
			if (!status) return "";
			if (status == "existed") return "#9C9C9C";
			if (status == "to_create") return "#2FAD30";
			if (status == "to_update") return "#E4AA40";
			if (status == "error") return "#E34B41";
			return "";
		},
		getStatusClass(status) {
			if (!status) return "";
			if (status == "existed") return "status__existed";
			if (status == "to_create") return "status__create";
			if (status == "to_update") return "status__update";
			if (status == "error") return "status__error";
			return "status__create";
		},
		markAsChanged(row, field) {
			row[field].changed = true;
			row.changed = true;
			this.isDataChanged = true;
		},

		/** Валидация данных на бэкенде (повторная)  */
		async revalidateData() {
			let toValidate = this.validatedData.filter(row => row.changed);
			let notValidate = this.validatedData.filter(row => !row.changed);
			let payload = toValidate.map((row) => {
				return Object.fromEntries(
					Object.entries(row)
						.filter(([key]) => !key.includes("status"))
						.map(([key, value]) => [key, value.value])
				);
			});
			this.validateData(payload, notValidate);
		},
		/** Валидация данных на бэкенде  */
		async validateData(payload, existedList = []) {
			const spinner = document.getElementById("spinner");
			if (!spinner) {
				console.error("Modal element does not exist in the DOM.");
				return;
			}
			const pendingModal = new Modal(spinner);
			try {
				pendingModal.show();
				const { data } = await this.$api.sip.importValidate({ data: payload, project: this.$route.params.id_proj }, 'gantt');
				this.validatedData = [...existedList, ...data];
			} catch (error) {
				this.errors.push(error.response.data)
				push.error(`Валидации данных!`)
			} finally {
				pendingModal.hide();
				this.isDataChanged = false;
				this.currentSortField = 'status'
			}
		},
		/** Сохранение валидных объектов в базу данных  */
		async saveData() {
			const spinner = document.getElementById("spinner");
			if (!spinner) {
				console.error("Modal element does not exist in the DOM.");
				return;
			}
			const pendingModal = new Modal(spinner);

			const payload = this.validatedData.filter((el) => el.status == "to_create" || el.status == "to_update");

			try {
				pendingModal.show();
				await this.$api.sip.importSave({
					data: JSON.stringify(payload),
				}, 'gantt');
				this.validatedData = [];

				const params = new URLSearchParams({ project: this.$route.params.id_proj })
				const { data } = (await this.$api.sip.list('gantt', params));
				this.$emit("updateGantt", data);
				this.hideModalWindow();
			} catch (error) {
				this.errors.push(error.message)
				push.error(`Ошибка импорта данных!`)
			} finally {
				pendingModal.hide();
			}
		},
	},
	computed: {
		validatedDataFiltered() {
			let data = [...this.validatedData];

			data.sort((a, b) => {
				let a_ = 0;
				let b_ = 0;
				if (['start_date', 'end_date'].includes(this.currentSortField)) {
					a_ = new Date(a[this.currentSortField].value);
					b_ = new Date(b[this.currentSortField].value);
					return (a_ - b_) * this.sortDirection;
				} else {
					a_ = a[this.currentSortField];
					b_ = b[this.currentSortField];
					if (a_ == b_) return 1;
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				}
			});

			return data;
		},
		checkNotToSave() {
			return this.isDataChanged || this.getReadyRows == 0;
		},
		getReadyRows() {
			return this.validatedData.filter((el) => el.status == "to_create" || el.status == "to_update").length
		}
	},
	created() {
		Object.entries(measurementUnits).forEach((el) => {
			this.measurementUnitOptions.push({
				id: el[1],
				name: el[0],
			});
		});
	}
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/baseModal.scss";

.table__wrapper {
	height: fit-content;
}

.table__body {
	max-height: 50vh;
}

.row,
.col {
	background: none;
}


.status {
	text-transform: uppercase;
	width: 80px;
}

.status__create {
	font: 300 12px/24px $sf-bold, sans-serif;
	color: green;
}

.status__update {
	font: 300 12px/24px $sf-bold, sans-serif;
	color: orange;
}

.status__existed {
	font: 300 12px/24px $sf-bold, sans-serif;
	color: $color-yellow-2;
}

.row__create {
	background: rgba($color-green-1, .1);
	color: $color-green-1 !important;
}

.row__update {
	background: rgba($color-yellow-2, .1);
	color: $color-yellow-2 !important;
}

.row__existed {
	background: rgba($color-gray-5, .1);
	color: $color-gray-5 !important;
}

.row__error {
	background: rgba($color-red-2, .1);
	color: $color-red-2 !important;
}

.status__error {
	font: 300 12px/24px $sf-bold, sans-serif;
	color: $color-red-2;
}

.input__error {
	background: rgba($color-red-2, 0.2) !important;

	&::placeholder {
		font-size: 10px;
		color: $color-red-2 !important;
	}
}

.data__warning {
	font: 300 12px/24px $sf-medium, sans-serif;
	color: $color-yellow-2;
	margin: 0;
}

.data__ready {
	display: flex;
	gap: 5px;
	margin-left: auto;
	font: 300 12px/24px $sf-medium, sans-serif;
}

.input__changed {
	background-color: $color-yellow-2;
}

.select__table {
	width: fit-content;
}

.header>img {
	height: 18px;
}
</style>
