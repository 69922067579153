<template>
	<div class="tb-background">
		<!-- #region Модальные окна -->
		<sip-modal-delete v-model:show="modalDeleteProject" :text="textForDelete" @deleteObject="deleteProject()" />
		<!-- #endregion -->

		<Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>
		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :currentPage="currentPage"
			@changePage="(page) => (currentPage = page)" />
		<Transition name="fade">
			<div class="content" v-if="!isLoading">
				<div class="content-block commons-page" v-if="currentPage == 'commons'">
					<commons-page :commonsData="APIdata" :finExpenses="costProfile" :projectStage="stagesObjArray"
						:objectType="typesObjArray" :isBlockChange=true :isNullAllowed=false />
				</div>
				<div class="table table__wrapper" style="width: 50%;" v-if="currentPage == 'sections'">
					<expertise-page :sectionsArray="sectionsFiltered" :page='"capex"' />
				</div>
				<div class="content-block row" v-if="currentPage == 'files'">
					<files-page :listFiles="filesArray" :sectionExperts="sectionExperts" :tableSize="'large'" />
				</div>
				<div class="content-block row" v-if="currentPage == 'roadmap'">
					<road-map-page :gantChartData="ganttChart" />
				</div>
				<div class="content-block row" v-if="currentPage == 'capex'">
					<capex-page @update:costProfile="updateCostProfileData" :costData="costProfile"
						:unitOptions="measurementUnitOptions" />
				</div>
				<div class="content-block" v-if="currentPage == 'conclusion'">
					<div class="block-group" style="max-width: 100%; gap: 30px">
						<conclusion-page :APIdata="APIdata" :isProject=true />
					</div>
				</div>

				<div class="content-block" v-if="currentPage == 'settings'">

					<div class="block-group settings_block">
						<div class="subtitle">Экспорт результатов экспертизы</div>
						<div class="settings_text">Получите полную информацию о результатах экспертизы в удобном для Вас
							формате</div>
						<div class="settings_buttons_block">
							<div class="btn-import btn_correction" @click="getTempProjectResults">
								<img class="img-import img_correction" src="@/assets/buttons/import_map.svg">
								<div class="innerText-import">
									Таблица
								</div>
							</div>
							<div class="btn-import btn_correction" @click="downloadPDF" style="opacity: 0.4;"
								:disabled="true">
								<img class="img-import img_correction" src="@/assets/buttons/analytics.svg">
								<div class="innerText-import">
									Аналитика
								</div>
							</div>
						</div>
					</div>

					<div class="block-group settings_block">
						<div class="subtitle">Инструменты работы с проектом</div>
						<div class="settings_text">Эффективно управляйте Вашим проектом до, во время, а также после
							прохождения экспертизы</div>
						<div class="settings_buttons_big_block">
							<div class="settings_buttons_block">
								<div class="btn-import btn_correction large_size" style="opacity: 0.4;"
									:disabled="true">
									<img class="img-import img_correction_2" src="@/assets/icons/pause_project.svg">
									<div class="innerText-import">
										Оставить на текущ. этапе
									</div>
								</div>
								<div class="btn-import btn_correction large_size" style="opacity: 0.4;"
									:disabled="true">
									<img class="img-import img_correction_2"
										src="@/assets/icons/next_level_project.svg">
									<div class="innerText-import">
										Переход на след. этап
									</div>
								</div>
							</div>
							<div class="btn-delete btn_correction large_size" @click="showProjectDeleteModal"
								:disabled="expertPermission()">
								<img class="img-import img_correction" src="@/assets/icons/trash.svg">
								<div class="innerText-import">
									Удалить проект
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { measurementUnits } from "@/database/utils";
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { mPermissions } from "@/utils/permissions";
import { mText } from "@/utils/text";
import { push } from 'notivue'

import FilesPage from "@/components/pages/components/FilesPage.vue"
import ConclusionPage from "@/components/pages/components/ConclusionPage.vue"
import CapexPage from "@/components/pages/components/CapexPage.vue";
import CommonsPage from "@/components/pages/components/CommonsPage.vue";
import RoadMapPage from "@/components/pages/components/RoadMapPage.vue";
import ExpertisePage from "@/components/pages/components/ExpertisePage.vue";

import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

export default {
	name: "TableProjectDetail",
	mixins: [mDate, mFile, mPermissions, mFilters, mText],
	components: { HeaderComp, FilesPage, ConclusionPage, CapexPage, CommonsPage, RoadMapPage, ExpertisePage },

	data() {
		return {
			navLinks: [
				{ id: "commons", name: "Общие сведения", show: true },
				{ id: "sections", name: "Области экспертизы", show: true },
				{ id: "roadmap", name: "Дорожная карта", show: true },
				{ id: "capex", name: "Капитальные вложения", show: true },
				{ id: "files", name: "Файлы", show: true },
				{ id: "conclusion", name: "Заключение", show: true },
				{ id: "settings", name: "Настройки проекта", show: true },
			],
			currentPage: "commons",
			isLoading: false,

			// Импортируемые объекты
			measurementUnits: Object.keys(measurementUnits),
			measurementUnitOptions: [],

			// Временные объекты под новые айтемы
			ganttChartNewItem: {
				name: "",
				start_date: null,
				end_date: null,
			},
			ganttHeads: [
				'name', 'start_date', 'end_date',
			],

			// Флаги для модалок
			modalDeleteFileVisible: false,
			modalDeleteGanttVisible: false,
			modalDeleteAllGanttVisible: false,
			modalDeleteCostVisible: false,
			modalDeleteAllCostVisible: false,
			modalDeleteProject: false,

			modalImportCostProfileVisible: false,
			modalImportGanttVisible: false,

			// Параметры для удаления айтемов
			objectIdToDelete: null,
			textForDelete: "",

			// Переменная для хранения временного значения инпута перед обновлением поля
			tempInputValue: "undefined",
			tempInputNumberValue: null,

			// Пустые массивы и объекты для заполнения таблиц и селектов
			APIdata: {},
			ganttChart: [],
			filesArray: [],
			typesObjArray: [],
			stagesObjArray: [],
			projectSections: [],
			sectionsObjArray: [],
			usersObjArray: [],
			costProfile: [],

			isFirstRowAHead: true,
			csvSeparator: ";",
			costProfileData: null,

			searchText: "",
			searchNoContentMessage: "😅 ничего не нашлось...",
			sortDirection: 1,
			currentSortField: "",

			freqChoices: [
				{ id: 'year', name: 'Год' },
				{ id: 'quarter', name: 'Квартал' },
				{ id: 'month', name: 'Месяц' },
				{ id: 'day', name: 'День' },
			],
			freq: 'month',
			ganttData: {},
		};
	},
	watch: {
		currentPage(val_) {
			this.sortDirection = 1
			if (val_ == 'sections') this.currentSortField = 'sections_order'
			if (val_ == 'capex') this.currentSortField = 'cost_year'
			if (val_ == 'files') this.currentSortField = 'file_name'
		}
	},
	computed: {
		sectionsFiltered() {
			let data = this.sectionsObjArray;
			("this.sectionsObjArray:", this.sectionsObjArray);
			if (this.currentSortField.startsWith("sections_")) {
				let sortField = this.currentSortField.replace('sections_', '')
				data.sort((a, b) => {
					let a_ = 0;
					let b_ = 0;
					a_ = a[sortField];
					b_ = b[sortField];
					if (a_ == b_) return 1;
					let comp = a_ < b_ ? -1 : 1;
					return comp * this.sortDirection;
				});
			}
			return data;
		},
		...mapGetters({
			getCurrentUser: "user/getUser",
			projectData: "project/getProjectData",
		}),
	},
	methods: {
		updateCostProfileData(newData) {
			this.costProfile = newData
		},
		showProjectDeleteModal() {
			this.modalDeleteProject = true;
			this.textForDelete = `Вы уверены, что хотите удалить текущий проект?`;
		},
		async getTempProjectResults() {
			const params = new URLSearchParams({ project_id: this.$route.params.id_proj });
			try {
				this.$store.dispatch("project/getResults", params).then((response) => {
					this.forceDownload(
						response,
						`${String(this.APIdata.name).replace(" ", "_")}-${new Date().toISOString()}.csv`,
						"", true
					);
				});
			} catch (error) {
				push.error(`Ошибка выгрузки данных!`)
			}
		},

		showUserImage(row) {
			const user = row.users.find(user => user.id === row.expert);
			return user !== undefined ? user : null
		},

		async handleWithModal(action) {
			const spinner = document.getElementById("spinner");
			if (!spinner) {
				console.error("Modal element does not exist in the DOM.");
				return;
			}

			const pendingModal = new Modal(spinner);
			try {
				pendingModal.show();
				await action();
			} catch (error) {
				console.error(error);
				push.error("Произошла ошибка при выполнении операции!");
			} finally {
				pendingModal.hide();
			}
		},

		/**  Выгрузка аналитики в формате PDF */
		async downloadPDF() {

			const notification = push.promise("Подготовка аналитики для выгрузки, пожалуйста подождите...")
			let content = this.$el.getElementsByClassName('print')

			let pageOrientation = 'l'
			let pdfUnit = 'px'
			let pdfFormat = 'a3'
			let pdfOption = {
				orientation: pageOrientation,
				unit: pdfUnit,
				format: pdfFormat
			};

			// offsets
			let dy = 132;
			let dx = 20;
			let sx = 0;
			let sy = 0;

			let scale = 1.0;
			let canvasTransformURLFormat = "image/jpeg";
			let imageFormat = "JPEG";
			let heightLimit = 700;

			let pdf = new jsPDF(pdfOption);
			let pdfHeight = pdf.internal.pageSize.getHeight();
			let pdfWidth = pdf.internal.pageSize.getWidth();
			let canvasWidth = pdfWidth - dx * 2;
			let canvasHeight = pdfHeight - dy * 2;


			(this.projectData);
			(1);
			// Создаем экран для отображения начальной страницы
			let titleCanvas = document.createElement('canvas');
			titleCanvas.width = canvasWidth;
			titleCanvas.height = canvasHeight;
			let titleContext = titleCanvas.getContext("2d");
			titleContext.fillStyle = "rgb(255, 255, 255)";
			titleContext.fillRect(0, 0, titleCanvas.width, titleCanvas.height);
			titleContext.font = "40px sf-pro-display-regular";
			titleContext.fillStyle = "rgb(0, 0, 0)";
			titleContext.textAlign = 'center';
			titleContext.fillText(`Аналитика по проекту\n "${this.projectData.name}"`, titleCanvas.width / 2, titleCanvas.height / 2);
			let croppedDataUrl = titleCanvas.toDataURL(canvasTransformURLFormat, scale);
			pdf.addImage(croppedDataUrl, imageFormat, dx, dy, canvasWidth, canvasHeight);
			pdf.addPage(pdfFormat, pageOrientation)


				(2);
			// Итерируемся по табличкам и преобразовываем их в canvas => JPEG => PDF
			for (let tmp of content) {
				await html2canvas(tmp, {
					allowTaint: true,
					imageTimeout: 0,
					onclone: (clonedEl) => {
						// Обнуляем BoxShadow для корректного отображения и добавляем границу
						let tmp = clonedEl.getElementsByClassName('card')
						for (let t of tmp) {
							t.style.boxShadow = 'none'
							t.style.border = '1px solid grey'
							t.querySelector(".card_header_controls").remove()
						}
					}
				}).then(canvas => {
					let imgData = canvas.toDataURL(canvasTransformURLFormat, scale);
					if (canvas.height > heightLimit) {
						let offScreenCanvas = document.createElement('canvas');
						offScreenCanvas.width = canvas.width;
						offScreenCanvas.height = pdfHeight;

						let offScreenContext = offScreenCanvas.getContext("2d");
						offScreenContext.fillStyle = "rgb(255, 255, 255)";
						offScreenContext.fillRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
						offScreenContext.drawImage(canvas, sx, sy, canvas.width, pdfHeight, 0, 0, canvas.width, pdfHeight);

						let croppedDataUrl = offScreenCanvas.toDataURL(canvasTransformURLFormat, scale);
						pdf.addImage(croppedDataUrl, imageFormat, dx, dy, canvasWidth, canvasHeight);

						while (sy < canvas.height) {
							sy += canvasHeight
							offScreenContext.fillRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
							offScreenContext.drawImage(canvas, sx, sy, canvas.width, pdfHeight, 0, 0, canvas.width, pdfHeight);
							croppedDataUrl = offScreenCanvas.toDataURL(canvasTransformURLFormat, scale);
							pdf.addPage(pdfFormat, pageOrientation)
							pdf.addImage(croppedDataUrl, imageFormat, dx, dy, canvasWidth, canvasHeight);
						}
					} else {
						pdf.addImage(imgData, imageFormat, dx, dy, canvasWidth, canvasHeight);
						pdf.addPage(pdfFormat, pageOrientation)
					}
				})
			}
			('все графики добавлены')
			notification.resolve('Файл успешно загружен!')
			pdf.save("download.pdf")
		},

		formatDatesAndSetProject(data, dateFields) {
			const formatter = new Intl.DateTimeFormat("en-ca", {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			});
			const dateFormat = "DD.MM.YYYY";
			const projectId = this.$route.params.id_proj;

			data.forEach(el => {
				dateFields.forEach(field => {
					const date = moment(el[field], dateFormat);
					if (date.isValid()) {
						el[field] = formatter.format(date.toDate());
					}
				});
				el.project = projectId;
			});
		},

		getSelectOptionsFromUser(users_) {
			let options = [];
			users_.forEach((el) => {
				options.push({
					id: el.id,
					// name: `${el.first_name} ${el.last_name}`,
					name: this.getFullName(el),
				});
			});
			return options;
		},

		showDeleteModal(modalType_, objectName_, objectId_ = null) {
			let user = JSON.parse(localStorage.getItem("user"));
			if (!user.is_project_manager & !user.is_admin) return;

			switch (modalType_) {
				case "gantt":
					this.modalDeleteGanttVisible = true;
					break;
				case "ganttAll":
					this.modalDeleteAllGanttVisible = true;
					break;
			}

			this.textForDelete = `Вы уверены, что хотите удалить ${objectName_}?`;
			this.objectIdToDelete = objectId_;
		},

		getInputDate(event, id_) {
			let field = event.target.name;
			let value = event.target.value;

			this.updateGanttChart(field, value, id_);
			let item = this.ganttChart.find((el) => el.id == id_);
			item[field] = value
			item.duration = this.getDifferenceInDays(item.start_date, item.end_date);
		},

		getSelectSectionsValue(event, sectionId_) {
			this.updateSectionExpert(event, sectionId_);
		},

		// Блок запросов на удаление объектов
		updateArray(array, id) {
			return array.filter((el) => el.id !== id);
		},

		async deleteItem(id, type, arrayName, successMessage, errorMessage) {
			try {
				await this.$api.sip.delete(id, type);
				this[arrayName] = this.updateArray(this[arrayName], id);
				push.success(successMessage);
			} catch (error) {
				push.error(errorMessage);
			}
		},

		async deleteProject() {
			try {
				await this.$api.sip.delete(this.$route.params.id_proj);
				push.success("Проект успешно удален!");
			} catch (error) {
				push.error("Ошибка удаления проекта!");
			} finally {
				this.$router.push({ name: "projectList" });
			}
		},

	},
	async created() {
		if (this.expertPermission()) {
			let navItem = this.navLinks.findIndex((el) => el.id == "settings");
			this.navLinks[navItem].show = false;
		}

		this.isLoading = true;

		let projectId = this.$route.params.id_proj;
		let user = JSON.parse(localStorage.getItem("user"));

		let params = new URLSearchParams();
		params.append("project", projectId);

		try {
			const data = (await this.$api.sip.retrieve(projectId)).data;
			this.$store.dispatch("project/fetchList", "types").then((response) => {
				this.typesObjArray = response.map((el) => {
					return { name: el.name, id: el.id };
				});
			});
			const stages = (await this.$api.question.list("stages")).data;
			const sections = (await this.$api.question.list("sections")).data;
			const users = (await this.$api.user.list()).data;

			this.APIdata = { ...data };
			if (!user.is_project_manager && !user.is_admin) {
				this.APIdata.section_experts = this.APIdata.section_experts.filter((section) => section.expert == user.id);
			}

			this.stagesObjArray = stages.map((el) => {
				return { name: el.name, id: el.id };
			});

			// создаем болванку под юзеров

			this.APIdata.section_experts.forEach((section) => {
				let userSections = {
					users: users.filter((user) => {
						let s_ids = [];
						if (user.sections) {
							if (user.sections.length == 0) return false;
							user.sections.forEach((el) => {
								s_ids.push(el);
							});
						}
						return s_ids.includes(section.section);
					}),
				};
				let userSectionOptions = [];
				userSections.users.forEach((user) => {
					userSectionOptions.push(user);
				});
				let newSection = sections.find((el) => el.id == section.section)
				userSectionOptions = userSectionOptions.sort((a, b) => { return (a.last_name <= b.last_name ? -1 : 1) })
				let item = Object.assign({ users: userSectionOptions }, section, {
					section: newSection.name,
					order: newSection.order,
				});
				this.sectionsObjArray.push(item);
			});

			this.sectionExperts = [];
			this.APIdata.section_experts.forEach((section) => {
				this.sectionExperts.push({
					name: sections.find((el) => el.id == section.section).name,
					id: section.section,
				});
			});

			this.ganttChart = [...this.APIdata.gantt_chart];
			this.costProfile = [...this.APIdata.cost_profile];
			this.usersObjArray = [...users];
			this.filesArray = [];
			this.APIdata.files.forEach((file) => {
				this.filesArray.push({
					id: file.id,
					name: file.name,
					section: file.section,
					type: file.type,
					size: file.size,
					file: file.file,
					author: this.usersObjArray.find((el) => el.id == file.author),
				});
			});

			Object.entries(measurementUnits).forEach((el) => {
				this.measurementUnitOptions.push({
					id: el[1],
					name: el[0],
				});
			});
		} catch (error) {
			push.error(`Ошибка обновления!`)
		} finally {
			this.isLoading = false;
		}
	},
	mounted() {
		let projectId = this.$route.params.id_proj;
		this.$store.dispatch("project/fetchAnalytics", projectId);
		this.$store.dispatch("project/fetchProjectData", projectId);
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.row__header .col__header:first-child,
.row__header .col__header.sm,
.col__content:first-child,
.col__content.sm {
	max-width: 40px !important;
	justify-content: flex-start;
	margin-left: 15px;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.page {
	height: 100%;
	z-index: 3;
	position: relative;
}

.settings_block {
	border: 1px solid rgba(#DBDBDB, 1);
	padding: 25px 35px;
	min-width: 525px;
	gap: 0px;
}

.subtitle {
	font-size: 15px;
	width: 100%;
	padding-bottom: 0px;
	font-family: "sf-pro-display-light";
}

.settings_text {
	margin-top: 10px;
	font-size: 13px;
	font-family: "sf-pro-display-light";
	color: #818181;
	width: 390px;
}

.settings_buttons_big_block {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.settings_buttons_block {
	margin-top: 25px;
	display: flex;
	gap: 12px;
	width: 100%;
	height: 100%;
}

.btn_correction {
	min-width: 144px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.large_size {
	min-width: 222px;
}

.img_correction {
	margin: 0px !important;
	scale: 90%;
}

.img_correction_2 {
	margin: 0px !important;
	scale: 110%;
}

.table__wrapper {
	&.cost {
		width: 64%;
	}

	&.file {
		width: 85%;
	}

	min-width: 30vw;
}


.select {
	&:hover {
		background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
		background-position-x: calc(100% - 15px) !important;
	}
}


input[type=date] {
	font-family: "sf-pro-display-medium";
}

textarea {
	width: 100%;
	background: transparent;
}

.row.row__content.row__disabled {
	background: $color-gray-0;

	& .col__content {
		color: $color-gray-3;
	}

	& .col__content {
		color: $color-gray-3;
	}
}

.col__header {
	&.roadmap {
		padding: 10px;
	}
}

.content {
	margin-top: 15px;
	padding-left: 0px;
	padding-right: 0px;
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	z-index: 3;
	position: relative;
}

.block>.text {
	font-family: $sf-light;
	font-size: 12px;

	&>span.bold {
		font-family: $sf-bold;
	}
}

.content-block {
	padding: 0 15px;
	overflow-y: hidden !important;

	&.commons-page {
		align-items: center;
		padding: 0;
	}
}

.content-block.row {
	gap: 20px;
}

input {
	background-color: rgba(255, 255, 255, 0.5);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: transparent;
}
</style>
