export const mFile = {
    data() {
        return {
            fileMimeTypes: {
                // Microsoft Office
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
                'application/vnd.ms-excel.sheet.macroEnabled.12': 'xlsm',
                'application/vnd.ms-excel': 'xls',

                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
                'application/vnd.ms-word.document.macroEnabled.12': 'docm',
                'application/msword': 'doc',

                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
                'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'pptm',
                'application/vnd.ms-powerpoint': 'ppt',

                // Archives
                'application/vnd.rar': 'rar',
                'application/zip': 'zip',
                'application/x-7z-compressed': '7z',

                // Adobe Acrobat
                'application/pdf': 'pdf',

                // Images
                'image/png': 'png',
                'image/jpeg': 'jpeg',
                'image/bmp': 'bmp',
                'image/tiff': 'tiff',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',

                // Text files
                'text/plain': 'txt',
                'text/csv': 'csv',
                'application/json': 'json',
                'application/rtf': 'rtf',
            }
        };
    },
    methods: {
        getFileExtension(mimeType) {
            return this.fileMimeTypes[mimeType] || 'unknown';
        },
        /**  Выгрузка файла */
        async forceDownload(response, filename, mimeType, isResult = false, isTemplate = false) {
            let blob
            const encoding_type = 'utf-8'
            const BOM = '\uFEFF';
            try {
                if (isResult) {
                    blob = new Blob([response], { type: `text/csv;charset=${encoding_type}` });
                } else if (isTemplate) {
                    blob = new Blob([BOM + response], { type: `text/csv;charset=${encoding_type}` });
                } else {
                    blob = await fetch(
                        response,
                    ).then(r => r.blob());
                    blob = new Blob([await blob.arrayBuffer()], { type: `${mimeType};charset=${encoding_type}` });
                }
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.log(error);
            }
        }
    }
}