export const mColor = {
    methods: {
        getColor(value_) {
            if (value_ < 25) {
                return 'font-color-red';
            } else if (value_ < 50) {
                return 'font-color-yellow';
            } else if (value_ < 75) {
                return 'font-color-blue';
            }
            return 'font-color-green';
        },
        getBGBadgeColor(hex_) {
            return `${hex_}40`
        },
        getBGColor() {
            return {
                backgroundColor: `${this.APIData.color}`,
            };
        },
    }
}
