<template>
	<div class="page_auth_screen">

		<div class="card">
			<form v-on:submit.prevent="login">
				<div class="card_content">
					<div class="upper_content">
						<img class="img_logo_class" src="@/assets/logos/logo_full.svg" />
						<div class="block_group">
	
							<!-- поле ввода логина -->
							<div class="block">
								<label for="input1">Логин</label>
								<sip-input type="text" placeholder="Введите логин" v-model="username" />
							</div>
	
							<!-- поле ввода пароля -->
							<div class="block">
								<label for="input2">Пароль</label>
								<div class="input_content" style="display: flex">
									<sip-input
										:type="isButtonEyeActive ? 'text' : 'password'"
										placeholder="Введите пароль"
										v-model="password"
									/>
									<div class="img_container">
										<img
											v-if="isButtonEyeActive"
											class="img_show_pass"
											src="@/assets/icons/eye_open.svg"
											@click="() => (isButtonEyeActive = false)"
										/>
										<img
											v-else
											class="img_show_pass"
											src="@/assets/icons/eye_close.svg"
											@click="() => (isButtonEyeActive = true)"
										/>
									</div>
								</div>

								<!-- сообщение об ошибке при входе -->
								<div v-if="errors.length != 0" class="error_message">
									Введен некорректный логин или пароль!
								</div>
							</div>
	
						</div>

						<!-- кнопка входа -->
						<sip-button
							type="submit"
							class="btn_login" :class="!checkRequired() ? 'bg-gray' : 'bg-light-blue'"
							:disabled="!checkRequired()"
						>
							Войти
						</sip-button>
					</div>
					
					<div class="lower_content">
						<div class="left_word">2024 © &nbsp;</div>
						<a href="https://gazpromcps.ru/" class="right_word">ООО «Газпром ЦПС»</a>
					</div>
				</div>
			</form>
		</div>

		<div class="water_mark_container">
			<img src="@/assets/logos/logo_water_mark.svg" class="water_mark">
		</div>
			
	</div>
</template>


<script>
	import { mapActions } from "vuex";
	export default {
		name: "PageAuth",
		data() {
			return {
				username: "",
				password: "",
				errors: [],
				isButtonActive: false,
				isButtonEyeActive: false,
				
				authCard: {
					login: "Введите логин",
					password: "Введите пароль",
					rememberMe: false,
				},
			};
		},
		methods: {
			...mapActions({
				setUser: "user/setUser",
			}),
			checkRequired() {
				if (this.username == "" || this.password == "") {
					return false;
				}
				return true;
			},
			async login() {
				try {
					let data;
					let user_data;
					Promise.race([
						await this.$api.auth.signIn({
							username: this.username,
							password: this.password,
						}),
					]).then(async (e) => {
						data = e.data;
						localStorage.setItem("access", data.access);
						localStorage.setItem("refresh", data.refresh);
						return Promise.race([this.$api.user.getMe()]).then(async (response) => {
							user_data = response.data;
							this.setUser(user_data);
							localStorage.setItem("user", JSON.stringify(user_data));
							if (localStorage.getItem("access") && localStorage.getItem("refresh")) {
								this.$router.push({ name: "projectList" });
							}
						});
					});
				} catch (error) {
					this.errors.push({ message: error.message, id: Date.now() });
				}
			},
			loginInput(value_) {
				// анализ введенной инфы с поля "Логин"
				this.username = value_;
				this.checkBothTextBoxFields();
			},
			passwordInput(value_) {
				// анализ введенной инфы с поля "Пароль"
				this.password = value_;
				this.checkBothTextBoxFields();
			},
			checkBothTextBoxFields() {
				// открытие активного состояния кнопки "Войти"
				if (this.username !== "" && this.password !== "") {
					this.isButtonActive = true;
				} else {
					this.isButtonActive = false;
				}
			},
		},
		created() {
			localStorage.clear();
		},
	};
</script>


<style lang="scss" scoped>
	@import "@/css/general.scss";
	@import "@/css/pages/authCard.scss";
</style>
