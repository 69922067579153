export const mFilters = {
    data() {
        return {
            currentSortField: '',
            sortDirection: 1,
        }
    },

    methods: {
        sortTable(field) {
            if (this.currentSortField === field) {
                this.sortDirection *= -1;
            } else {
                this.currentSortField = field;
                this.sortDirection = -1;
            }
        },
        getSortClasses(type) {
            return {
                "arrow-up": this.currentSortField === type && this.sortDirection === -1,
                "arrow-down": this.currentSortField === type && this.sortDirection === 1,
            };
        },
    }
}