<template>
    <!-- <div class="select-wrapper"> -->
    <select :data-allow-clear="isNullAllowed" v-model="innerValue" @focus="onFocus" @blur="onBlur">
        <option :disabled="!isNullAllowed" value="null">{{ placeholder || 'Выберите значение' }}</option>
        <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
        </option>
    </select>
    <!-- <img class="arrow-select" src="@/assets/icons/select_arrow.svg" alt=""> -->
    <!-- </div> -->
</template>

<script>
export default {
    name: 'sip-select',
    emits: ['update:modelValue'],
    data() {
        return {
            innerValue: this.modelValue,
            isFocused: false,
        };
    },
    watch: {
        innerValue(newValue) {
            this.$emit('update:modelValue', newValue)
        },
        modelValue(newValue) {
            this.innerValue = newValue;
        }
    },
    props: {
        modelValue: {
            type: undefined,
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: null,
        },
        isNullAllowed: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onFocus() {
            this.isFocused = true;
        },
        onBlur() {
            this.isFocused = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/css/components/select.scss';
</style>