import { createWebHistory, createRouter } from "vue-router";
// -------
import PageAuth from "@/views/PageAuth.vue";
import TableProjects from "@/components/pages/TableProjects.vue";

import TableSections from "@/components/pages/TableSections.vue";
import TableQuestions from "@/components/pages/TableQuestions.vue";
import FormProjectCreate from "@/components/pages/FormProjectCreate.vue";
import FormUserCreate from "@/components/pages/FormUserCreate.vue";
import TableProjectDetail from "@/components/pages/TableProjectDetail.vue";
import TableProjectAnalytics from "@/components/pages/TableProjectAnalytics.vue";
import PageHome from "@/views/PageHome.vue";

import TableUsers from "@/components/pages/TableUsers.vue";
import TableUserPage from "@/components/pages/TableUserPage.vue";

// конфигурируем router
export default new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            name: "home",
            path: "/",
            redirect: { name: 'projectList' },
        },
        {
            name: "login",
            path: "/login",
            component: PageAuth,
        },
        {
            name: "projects",
            path: "/projects",                                  // http://localhost:8080/projects/ => http://localhost:8000/api/pages/projects/
            component: PageHome,
            meta: { requiresLogin: true },
            props: true,
            children: [
                {
                    name: "projectList",
                    path: "",
                    component: TableProjects,
                    meta: { requiresLogin: true },
                    props: true
                },
                {
                    name: "project",
                    path: ":id_proj",                          // http://localhost:8080/projects/{id} => http://localhost:8000/api/pages/sections/?project={id}
                    component: TableSections,
                    meta: { requiresLogin: true },
                    props: true
                },
                {
                    name: "details",
                    path: ":id_proj/details",                   // http://localhost:8080/projects/{id}/details => http://localhost:8000/api/pages/projects/{id}/
                    component: TableProjectDetail,
                    meta: { requiresLogin: true },
                    props: true,
                },
                {
                    name: "analytics",
                    path: ":id_proj/analytics",                 // http://localhost:8080/projects/{id}/analytics => http://localhost:8000/api/pages/projects/{id}/analytics/
                    component: TableProjectAnalytics,
                    meta: { requiresLogin: true },
                    props: true,
                },
                {

                    name: "section",
                    path: ":id_proj/sections/:id_sect",                  // http://localhost:8080/projects/{id}/sections/{id} => http://localhost:8000/api/pages/questions/?project={id_proj}&section={id_sect}
                    component: TableQuestions,
                    meta: { requiresLogin: true },
                    props: true,
                    // children: [
                    //     {
                    //         name: "analytics",
                    //         path: "/analytics",      // http://localhost:8080/projects/{id}/sections/{id}/analytics => http://localhost:8000/api/pages/sections/{id_sect}/analytics/
                    //     },
                    // ]
                },

                {
                    name: "createProject",
                    path: "create",
                    component: FormProjectCreate,
                    meta: { requiresLogin: true },
                    props: true
                },
            ]
        },
        {
            name: "users",
            path: "/users",                                  // http://localhost:8080/users/ => http://localhost:8000/api/pages/users/
            component: PageHome,
            meta: { requiresLogin: true },
            props: true,
            children: [
                {
                    name: "userList",
                    path: "",
                    component: TableUsers,
                    meta: { requiresLogin: true },
                    props: true
                },
                {
                    name: "user",
                    path: ":id_user",                          // http://localhost:8080/projects/{id} => http://localhost:8000/api/pages/sections/?project={id}
                    component: TableUserPage,
                    meta: { requiresLogin: true },
                    props: true,
                },
                {
                    name: "createUser",
                    path: "create",
                    component: FormUserCreate,
                    meta: { requiresLogin: true },
                    props: true
                },
                // {
                //     name: "profile",
                //     path: "profile",                          // http://localhost:8080/projects/{id} => http://localhost:8000/api/pages/sections/?project={id}
                //     component: TableUserPage,
                //     meta: { requiresLogin: true },
                //     props: true,
                // },
            ],
        },

    ]
});
