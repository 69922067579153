<template>
    <textarea contenteditable="true" rows="1" class="textarea__table" placeholder="Введите значение"
        @input="updateTextarea" autocomplete="on" :value="modelValue">
    </textarea>
</template>

<script>
export default {
    name: 'sip-table-textarea',
    props: {
        modelValue: [String, Number],
    },
    methods: {
        updateTextarea(event) {
            this.$emit('update:modelValue', event.target.value);

        },
        resizeTextarea(event) {
            event.target.style.height = "auto";
            event.target.style.height = event.target.scrollHeight + "px";
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$el.setAttribute(
                "style",
                "height:" + this.$el.scrollHeight + "px;overflow-y:hidden;",
            );
            this.$el.addEventListener("input", this.resizeTextarea);
        });
    },
    beforeUnmount() {
        this.$el.removeEventListener("input", this.resizeTextarea);
    },

}
</script>

<style lang="scss" scoped>
@import '@/css/components/textarea.scss';
</style>