// import Vue3Autocounter from 'vue3-autocounter';
import SIPAvatar from '@/components/UI/avatar/SIPAvatar';
import SIPBadge from '@/components/UI/badge/SIPBadge';
import SIPInput from '@/components/UI/input/SIPInput';

import SIPSelect from '@/components/UI/select/SIPSelect';
import SIPSelectCustom from '@/components/UI/select/SIPSelectCustom';
import SIPSelectUser from '@/components/UI/select/SIPSelectUser';

import SIPTextarea from '@/components/UI/textarea/SIPTextarea';
import SIPTableTextarea from '@/components/UI/textarea/SIPTableTextarea';
import SIPButton from '@/components/UI/button/SIPButton';
import SIPProgress from '@/components/UI/progress/SIPProgress';
import NoContent from '@/components/UI/nocontent/NoContent';
import SIPPreloader from '@/components/UI/preloader/SIPPreloader';
import SIPTime from '@/components/UI/time/SIPTime';
import NoContentInline from '@/components/UI/nocontent/NoContentInline';
import SIPSkeletonLoaderTable from '@/components/UI/skeletonLoader/SIPSkeletonLoaderTable';
import SIPGantt from '@/components/UI/gantt/SIPGantt';
import SIPCard from '@/components/UI/card/SIPCard';
import GanttBlock from '@/components/UI/analytics/GanttBlock';

import ModalSpinner from '@/components/UI/modal/ModalSpinner';
import SIPModalBase from '@/components/UI/modal/SIPModalBase';
import SIPModalDelete from '@/components/UI/modal/SIPModalDelete';
import SIPModalLogout from '@/components/UI/modal/SIPModalLogout';
import SIPModalImport from '@/components/UI/modal/SIPModalImport';
import SIPModalImportCostProfile from '@/components/UI/modal/SIPModalImportCostProfile';
import SIPModalImportGantt from '@/components/UI/modal/SIPModalImportGantt';
import SIPCheckbox from '@/components/UI/checkbox/SIPCheckbox.vue'
import ErrorList from '@/components/UI/error/ErrorList';

export default [
    // Vue3Autocounter
    SIPCheckbox,
    SIPAvatar,
    SIPBadge,
    SIPInput,
    SIPSelect,
    SIPSelectCustom,
    SIPSelectUser,
    SIPTextarea,
    SIPTableTextarea,
    SIPButton,
    SIPModalBase,
    SIPModalDelete,
    SIPModalLogout,
    SIPModalImport,
    SIPProgress,
    NoContent,
    SIPPreloader,
    SIPTime,
    NoContentInline,
    ModalSpinner,
    SIPSkeletonLoaderTable,
    SIPGantt,
    SIPCard,
    GanttBlock,
    SIPModalImportCostProfile,
    SIPModalImportGantt,
    ErrorList,
]