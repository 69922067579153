<template>
    <sip-modal-import :projectFile="projectFileNewItem" @create-project-file="createProjectFile"
        @expert-permission="expertPermission"></sip-modal-import>
    <sip-modal-delete v-model:show="modalDeleteFileVisible" :text="textForDelete"
        @deleteObject="deleteFile(objectIdToDelete)" />

    <div class="block-group file-picker" v-if="!expertPermission()">
        <div class="sip-form">
            <div class="btn-import" @click="$refs.file.click()">
                <div class="btn-import-layout">
                    <input id="file" type="file" ref="file" @change="handleFileUpload" style="display: none"
                        :disabled="expertPermission()" />
                    <img class="img-import" src="@/assets/buttons/upload_file.svg" />
                    <div class="innerText-import">Добавить файл</div>
                </div>
            </div>
        </div>
    </div>

    <div class="table table__wrapper file" :style="setTableWidth" v-if="filesFiltered && filesFiltered.length != 0">
        <div class="row row__header">
            <div class="index col__header" style="cursor: default">#</div>
            <div v-if="isFileName" class="file-name col__header"
                :class="[currentSortField === 'file_name' ? 'selected' : '', sizeClass]"
                @click="sortTable('file_name')">
                Файл
                <img :class="['imm_array_sort', getSortClasses('file_name')]" src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div v-if="isFileSection" class="file-section col__header"
                :class="[currentSortField === 'file_section' ? 'selected' : '', sizeClass]"
                @click="sortTable('file_section')">
                Область экспертизы
                <img :class="['imm_array_sort', getSortClasses('file_section')]"
                    src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div v-if="isFileAuthor" class="file-author col__header"
                :class="[currentSortField === 'file_author' ? 'selected' : '', sizeClass]"
                @click="sortTable('file_author')">
                Автор
                <img :class="['imm_array_sort', getSortClasses('file_author')]" src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div v-if="isFileType" class="file-type col__header"
                :class="[currentSortField === 'file_type' ? 'selected' : '', sizeClass]"
                @click="sortTable('file_type')">
                Тип
                <img :class="['imm_array_sort', getSortClasses('file_type')]" src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div v-if="isFileSize" class="file-size col__header"
                :class="[currentSortField === 'file_size' ? 'selected' : '', sizeClass]"
                @click="sortTable('file_size')">
                Размер
                <img :class="['imm_array_sort', getSortClasses('file_size')]" src="@/assets/icons/arrow_filter.svg" />
            </div>
            <div :class="['file-button', 'col__header', sizeClass]"></div>
        </div>

        <div class="table__body">
            <transition-group name="list">
                <div class="row row__content" v-for="(row, index) in filesFiltered" :key="row.id">
                    <div class="index col__content">{{ index + 1 }}</div>
                    <div v-if="isFileName" :class="['file-name', sizeClass]" class="col__content">{{ row.name }}</div>
                    <div v-if="isFileSection" :class="['file-section', sizeClass]" class="col__content">
                        <sip-select name="sections" :isNullAllowed="true" :options="sectionExperts"
                            v-model:modelValue="row.section" class="select__table" style="width: 90%"
                            @change="getSelectFileSectionValue($event.target.value, row.id, rowIndex)" />
                    </div>
                    <div v-if="isFileAuthor" :class="['file-author', sizeClass]" class="col__content">
                        {{ getFullName(row.author) }}
                    </div>
                    <div v-if="isFileType" :class="['file-type', sizeClass]" class="col__content">
                        {{ fileMimeTypes[row.type] }}
                    </div>
                    <div v-if="isFileSize" :class="['file-size', sizeClass]" class="col__content">
                        {{ Math.round(row.size / 1024) }} kB
                    </div>
                    <div v-if="isFileButton" :class="['file-button', sizeClass]" class="col__content">
                        <div class="block-row">
                            <a class="table__menu__btns" @click="forceDownloadFile(row)">
                                <img src="@/assets/buttons/download.svg" class="gray-2" />
                            </a>
                            <a class="table__menu__btns" :hidden="!fileAuthorPermission(row)"
                                @click="showDeleteModal(row.name, row.id)">
                                <img src="@/assets/icons/trash.svg" class="gray" />
                            </a>
                        </div>
                    </div>
                </div>
            </transition-group>

        </div>
    </div>
    <div v-else class="no_files">
        Загруженные файлы отсутствуют
    </div>
</template>

<script>
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mColor } from "@/utils/color";
import { mText } from "@/utils/text";
import { mPermissions } from "@/utils/permissions";
import { mFilters } from "@/utils/filters";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import { push } from "notivue";

export default {
    name: "TableQuestions",
    mixins: [mDate, mColor, mText, mPermissions, mFilters, mFile],
    props: {
        listFiles: {
            type: Object,
            default: null,
        },
        isFileName: {
            type: Boolean,
            default: true,
        },
        isFileSection: {
            type: Boolean,
            default: true,
        },
        isFileAuthor: {
            type: Boolean,
            default: true,
        },
        isFileType: {
            type: Boolean,
            default: true,
        },
        isFileSize: {
            type: Boolean,
            default: true,
        },
        isFileButton: {
            type: Boolean,
            default: true,
        },
        widthTable: {
            type: String,
            default: "85%",
        },
        sectionData: {
            type: Object,
            default: null,
        },
        sectionExperts: {
            type: Object,
            default: null,
        },
        tableSize: {
            type: String,
            default: "large",
        },
    },
    data() {
        return {
            isLoading: false,
            filesArray: this.listFiles,
            projectFileNewItem: null,
            modalDeleteFileVisible: false,
            objectIdToDelete: null,
            textForDelete: "",
            currentSortField: "file_name",
            sortDirection: 1,
        };
    },
    methods: {
        //  #region - Блок работы с файлом
        /** Подфункция скачивания файла  */
        async forceDownloadFile(row) {
            let nameFile = `${String(row.name)}`;
            this.forceDownload(row.file, nameFile, row.type, false);
        },

        /** Удаление файла  */
        async deleteFile(id_) {
            const spinner = document.getElementById("spinner");
            if (!spinner) {
                console.error("Modal element does not exist in the DOM.");
                return;
            }
            const pendingModal = new Modal(spinner);
            try {
                pendingModal.show();
                await this.$api.sip.delete(id_, "files");
                this.deleteFileItem(id_);
                push.success(`Файл успешно удален!`);
            } catch (error) {
                push.error(`Ошибка удаления файла!`);
            } finally {
                pendingModal.hide();
            }
        },

        /** Добавление файлов в проект  */
        async createProjectFile() {
            let payload = new FormData();
            payload.append("file", this.projectFileNewItem);
            payload.append("name", this.projectFileNewItem.name);
            payload.append("type", this.projectFileNewItem.type);
            payload.append("size", this.projectFileNewItem.size);
            payload.append("project", this.$route.params.id_proj);
            if (this.sectionData !== null) {
                payload.append("section", this.sectionData.section.id);
            }
            let user = JSON.parse(localStorage.getItem("user"));
            payload.append("author", user.id);

            const spinner = document.getElementById("spinner");
            if (!spinner) {
                console.error("Modal element does not exist in the DOM.");
                return;
            }
            const pendingModal = new Modal(spinner);
            try {
                pendingModal.show();
                const data = (await this.$api.sip.create("files", payload)).data;
                this.addProjectFileItem(data);
                push.success(`Файл "${data.name}" успешно добавлен!`);
            } catch (error) {
                push.error(`Ошибка добавления файла!`);
            } finally {
                pendingModal.hide();
            }
        },

        /** Подфункция добавления файлов в проект */
        addProjectFileItem(object_) {
            this.filesArray = [...this.filesArray, object_];
            this.projectFileNewItem = null;
        },

        /** Удаление файлов в проекте  */
        deleteFileItem(id_) {
            this.filesArray = [...this.filesArray.filter((el) => el.id != id_)];
        },

        /** Запустить выбор файла  */
        triggerFileInput() {
            this.$refs.file.click();
            this.$refs.file.value = null;
        },

        /** Логика добавления файла  */
        handleFileUpload(event) {
            const targetFiles = event.target.files;
            if (!targetFiles || targetFiles.length === 0) return;
            const file = targetFiles[0];
            this.projectFileNewItem = file;
            event.target.value = null;
        },
        //#endregion

        //  #region - Блок подфункций функция

        /** Показать модальное окно удаления файлов */
        showDeleteModal(objectName_, objectId_) {
            this.modalDeleteFileVisible = true;
            this.textForDelete = `Вы уверены, что хотите удалить ${objectName_}?`;
            this.objectIdToDelete = objectId_;
        },

        /** Главная функция обновления объектов  */
        async updateObject(apiSection, objectId, updates, successMessage, errorMessage) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };

            try {
                await this.$store.dispatch("project/patchObject", payload);
                push.success(successMessage);
            } catch (error) {
                push.error(errorMessage);
            }
        },

        /** Общая подфункция обновления секции  */
        async updateFileSection(value_, fileId_) {
            await this.updateObject(
                "files",
                fileId_,
                { section: value_ === "null" ? null : value_ },
                "Область экспертизы для файла успешно обновлена!",
                "Ошибка обновления области экспертизы для файла!"
            );
        },

        /** Триггер функция вызова обновления секции  */
        getSelectFileSectionValue(event, fileId_) {
            this.updateFileSection(event, fileId_);
        },

        //#endregion
    },
    computed: {
        //  #region - Блок работы со store
        ...mapGetters({ userId: "user/getUserId" }),
        //#endregion

        //  #region - Блок работы со style в template
        setTableWidth() {
            return {
                width: this.widthTable,
            };
        },
        sizeClass() {
            return this.tableSize === "large" ? "large" : "small";
        },
        //#endregion

        //  #region - Блок работы с фильтрацией
        filesFiltered() {
            let data = this.filesArray;
            if (this.currentSortField.startsWith("file_")) {
                let sortField = this.currentSortField.replace("file_", "");
                data.sort((a, b) => {
                    let a_ = 0;
                    let b_ = 0;
                    const MINIMAL_VALUE = -1000000;
                    if (["size"].includes(sortField)) {
                        a_ = parseFloat(a[sortField]);
                        b_ = parseFloat(b[sortField]);
                        if (isNaN(a_)) a_ = MINIMAL_VALUE;
                        if (isNaN(b_)) b_ = MINIMAL_VALUE;
                        return (a_ - b_) * this.sortDirection;
                    } else {
                        a_ = a[sortField];
                        b_ = b[sortField];
                        if (a_ == b_) return 1;
                        let comp = a_ < b_ ? -1 : 1;
                        return comp * this.sortDirection;
                    }
                });
            }
            return data;
        },
        //#endregion
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.table__wrapper {
    &.file {
        width: 85%;
    }

    min-width: 30vw;
}

.sip-form>.btn-file {
    width: 110px;
}

.sip-form {
    gap: 10px;
    justify-content: flex-start;
}

.btn-file,
.btn-image {
    font-size: 13px !important;
    width: max-content;
}

.select {
    &:hover {
        background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
        background-position-x: calc(100% - 15px) !important;
    }
}

.select__table:has(> option[value="null"]:checked) {
    background: url("@/assets/icons/select_arrow_blue.svg") no-repeat right !important;
    background-position-x: calc(100% - 15px) !important;
    color: #2f9adb !important;
}

.input__table {
    font-size: 13px;

    &::placeholder {
        font-size: 13px;
        color: #2f9adb;
        font-family: "sf-pro-display-medium";
    }

    &.short {
        max-width: fit-content !important;
        width: 75% !important;
    }

    &input[type="date"]::placeholder {
        color: #2f99db8a;
    }
}

.select__table {
    font-size: 13px;

    &.short {
        width: 75% !important;
    }
}

.row__create {
    background-color: #e8f6ff;

    &:hover {
        background-color: #e8f6ff;
    }

    select,
    input {
        color: #0c69a2;
    }

    input[type="date"] {
        color: #2f9adb;
    }

    &.duration {
        color: #2f9adb;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        filter: invert(51%) sepia(99%) saturate(381%) hue-rotate(161deg) brightness(87%) contrast(96%);
    }
}

.row.row__content.row__disabled {
    background: $color-gray-0;

    & .col__content {
        color: $color-gray-3;
    }

    & .col__content {
        color: $color-gray-3;
    }
}

.file-picker {
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.6%;
    position: relative;
}

.content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    z-index: 3;
    position: relative;
}

.block>.text {
    font-family: $sf-light;
    font-size: 12px;

    &>span.bold {
        font-family: $sf-bold;
    }
}

.table__body {
    height: 62vh;
}

.table__menu__btns .red,
.gray {
    width: 23px;
}

.no_files {
    color: $color-gray-3;
    display: flex;
    font-size: 21px;
    height: 62vh;
    justify-content: center;
    align-items: center;
}

.row__header .col__header:first-child,
.row__header .col__header.sm,
.col__content:first-child,
.col__content.sm {
    max-width: 40px !important;
    justify-content: flex-start;
    margin-left: 15px;
}
</style>
