import store from '@/store/index'
export const mPermissions = {
    data() {
        return {
            userMe: null,
        }
    },
    created() {
        this.userMe = store.getters['user/getUser']
    },
    methods: {

        getUserRole(user) {
            if (this.checkAdmin(user)) return 'Администратор';
            if (this.checkProjectManager(user)) return 'Руководитель проекта';
            if (this.checkExpert(user)) return 'Эксперт';
            return '-';
        },

        checkAdmin(user) {
            if (user) return user.is_admin;
            return false;
        },
        checkProjectManager(user) {
            if (user) return user.is_project_manager;
            return false;
        },
        checkExpert(user) {
            if (user) return !(this.checkAdmin(user) && this.checkProjectManager(user));
            return false;
        },

        fileAuthorPermission(file) {
            if (this.checkAdmin(this.userMe)) return true
            if (file.author == this.userMe.id) return true
            return false
        },
        expertPermission() {
            if (this.checkAdmin(this.userMe)) return false
            if (this.userMe.is_project_manager) return false
            return true
        },
        adminPermission() {
            return this.checkAdmin(this.userMe)
        },
        projectManagerPermission() {
            return this.checkProjectManager(this.userMe)
        },
        projectPermission(project) {
            if (this.checkAdmin(this.userMe)) return false
            if (this.userMe.id == project.user.id || project.section_experts.find((section) => section.expert == this.userMe.id) !== undefined) return false
            return true
        },
        sectionPermission(section) {
            if (this.checkAdmin(this.userMe)) return true
            if (this.userMe.id == section.expert.id) return true
            return false
        },
    }
}