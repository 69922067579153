<template>
	<div class="tb-background">
		<Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>
		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :showSearch="false"
			@changePage="(page) => (currentPage = page)" />
		<div class="table">
			<div class="row row__header">
				<div class="col-1 col__header col-sm-2" :class="this.currentSortField === 'order' ? 'selected' : ''"
					@click="sortTable('order')">#
					<img :class="['imm_array_sort', getSortClasses('order')]" src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-3 col__header" style="margin-left: 15px"
					:class="this.currentSortField === 'name' ? 'selected' : ''" @click="sortTable('name')">
					Область экспертизы
					<img :class="['imm_array_sort', getSortClasses('name')]" src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-2 col__header" :class="this.currentSortField === 'expert_name' ? 'selected' : ''"
					@click="sortTable('expert_name')">
					Эксперт
					<img :class="['imm_array_sort', getSortClasses('expert_name')]"
						src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-1 col__header col-sm" :class="this.currentSortField === 'files' ? 'selected' : ''"
					@click="sortTable('files')">
					Файлов, ед.
					<img :class="['imm_array_sort', getSortClasses('files')]" src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-1 col__header col-sm"
					:class="this.currentSortField === 'answer_percentage' ? 'selected' : ''"
					@click="sortTable('answer_percentage')">
					Ответов, ед.
					<img :class="['imm_array_sort', getSortClasses('answer_percentage')]"
						src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-2 col__header" :class="this.currentSortField === 'index_gip' ? 'selected' : ''"
					@click="sortTable('index_gip')">
					Индекс ГИП
					<img :class="['imm_array_sort', getSortClasses('index_gip')]"
						src="@/assets/icons/arrow_filter.svg" />
				</div>
				<div class="col-2 col__header" :class="this.currentSortField === 'updated_at' ? 'selected' : ''"
					@click="sortTable('updated_at')">
					Последнее изменение
					<img :class="['imm_array_sort', getSortClasses('updated_at')]"
						src="@/assets/icons/arrow_filter.svg" />
				</div>
			</div>
			<div class="table__body">
				<transition-group name="list">
					<div class="row row__content selection" v-for="(row) in APIDataFiltered" :key="row.id"
						:disabled="row.questions == '-'"
						@click="row.questions != '-' ? goToSection(row.id, row.name, row.answers, row.questions) : ''">
						<div class="col-1 col__content">{{ row.order }}</div>
						<div class="col-3 col__content" style="margin-left: 15px">{{ row.name }}</div>
						<div class="col-2 col__content">
							<sip-avatar :user="row.user" :style="{ marginRight: '15px' }" :size="30" />
							{{ row.expert_name }}
						</div>
						<div class="col-1 col__content col-sm">{{ row.files }}</div>
						<div class="col-1 col__content col-sm" style="margin-left: 40px; gap: 4px">
							<img class="flag" :src="responseStatus(row.answers, row.questions)"
								:style="setFlagColor(row.answers, row.questions)" />
							<span :style="setColorTotalAnswers(row.answers, row.questions)">{{ row.answers }}</span>
							<span class="muted">/</span>
							<span class="muted">{{ row.questions }}</span>
						</div>
						<div class="col-2 col__content" style="margin-left: -16px;">
							<sip-progress class="navbar-progress" :value="row.index_gip" :type="'nav'" :width="35"
								:height="6" :showPercent="true" :justifyContent="'flex-start'"
							/>
						</div>
						<div class="col-2 col__content">{{ getDateDuration(row.updated_at) }}</div>
					</div>
				</transition-group>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { mText } from "@/utils/text";
import { mapGetters } from "vuex";
import { mFilters } from "@/utils/filters";
import { mDate } from "@/utils/date";
import { mAnswer } from "@/utils/answer.js"

export default {
	name: "TableSections",
	mixins: [mText, mFilters, mDate, mAnswer],
	components: { HeaderComp },
	data() {
		return {
			APIData: [], // база данных с сервера
			navLinks: [{ id: "", name: "Перечень областей экспертизы", show: true }],
			totalAnswers: null,
			totalQuestions: null,
			isLoading: false,
			currentSortField: 'order',
			sortDirection: 1,
		};
	},
	computed: {
		...mapGetters({
			sections: "project/getProjectSections",
		}),
		APIDataFiltered() {
			let data = this.APIData;
			// сортировка массива
			data.sort((a, b) => {
				let a_ = 0;
				let b_ = 0;
				a_ = a[this.currentSortField];
				b_ = b[this.currentSortField];
				if (a_ == b_) return 1;
				let comp = a_ < b_ ? -1 : 1;
				return comp * this.sortDirection;

			});

			return data;
		},
	},
	watch: {
		sections(value_) {
			if (value_) {
				this.APIData = this.normalizeAPIdata(value_);
				this.totalAnswers = this.APIData.reduce((total, item) => {
					return total + (typeof item.answers === 'number' ? item.answers : 0);
				}, 0);
				this.totalQuestions = this.APIData.reduce((total, item) => {
					return total + (typeof item.questions === 'number' ? item.questions : 0);
				}, 0);
				this.$store.commit("project/SET_TOTAL_ANSWER", this.totalAnswers);
				this.$store.commit("project/SET_TOTAL_QUESTIONS", this.totalQuestions);
			}
		},
	},
	mounted() {
		this.isLoading = true;
		localStorage.removeItem("sectionName");
		let params = new URLSearchParams();
		let projectId = this.$route.params.id_proj;
		params.append("project", projectId);
		this.$store.dispatch("project/fetchProjectSections", params);
	},
	beforeUnmount() {
		this.$store.commit("project/SET_PROJECT_SECTIONS", null);
	},
	methods: {
		goToSection(id_, name_, answersCount_, questionCount_) {
			this.$store.commit("project/SET_TOTAL_ANSWER", answersCount_);
			this.$store.commit("project/SET_TOTAL_QUESTIONS", questionCount_);
			if (questionCount_ != "") {
				let proj = this.$route.params.id_proj;
				localStorage.setItem("sectionName", name_);
				this.$router.push({
					name: "section",
					params: {
						id_proj: proj,
						id_sect: id_,
					},
				});
			}
		},
		normalizeAPIdata(data_) {
			let new_data = [];
			data_.forEach((el) => {
				new_data.push({
					// id: el.section.id ? el.section.id : '-',
					id: el.id ? el.id : "-",
					order: el.order ? el.order : "-",
					name: el.section.name ? el.section.name : "-",
					expert_name: el.expert ? this.getFullName(el.expert) : "-",
					expert_image_url: el.expert.image ? el.expert.image : "",
					expert_color: el.expert.color ? el.expert.color : "-",
					files: el.file_count ? el.file_count : "-",
					answers: el.answer_count,
					questions: el.question_count ? el.question_count : "-",
					answer_percentage: el.answer_count / el.question_count,
					index_gip: el.index_gip ? Number(el.index_gip) : 0,
					user: el.expert,
					updated_at: el.updated_at,
				});
			});
			this.isLoading = false;
			return new_data;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableSection.scss";
@import "@/css/animation/lists.scss";

.flag {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	margin-right: 5px;
	display: flex;
}

.muted {
	color: $color-gray-3;
}
</style>
