<template>
    <div class="no__content">
        <h3 v-if="message">{{ message }}</h3>
        <h3 v-else>Нет данных</h3>
    </div>
</template>

<script>
export default {
    name: 'no-content',
    props: {
        message: String,
    },
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

.no__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1rem;
    color: $color-gray-3;
}
</style>
