<template>
    <input placeholder="Введите значение" @input="updateInput" :value="modelValue" @keyup.enter="defocus($event)" />
</template>

<script>
export default {
    name: 'sip-input',
    props: {
        modelValue: [String, Number, Boolean, Object],
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        defocus(event) {
            event.target.blur();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

input {
    padding: 10px;
    // margin: 0;
    border: 1px solid rgba(#004269, 0.28);
    color: #363639;
    font-family: $sf-medium;
    font-size: 14px;
    height: 40px;
    min-width: auto;
    width: 100%;

    &::placeholder {
        font-family: $sf-light;
        color: #BABBBC;
        font-size: 14px;
    }

    &:hover {
        background-color: $color-blue-1;
        cursor: text;
    }

    &:focus {
        outline: 1px solid rgb(#004269, 0.3);
        background-color: $color-blue-1;
    }

    &[disabled] {
        cursor: default;
        background-color: $color-gray-0;
        border: 1px solid $color-gray-0;
    }

    &[disabled]:hover {
        cursor: default;
    }
}

input[type=checkbox] {
    height: 1.1em;
    width: 1.1em;
    transition: all ease 0.2s;
    cursor: pointer;

    &:focus {
        outline: none;
        background-color: $color-blue-1;
    }
}

input[type=checkbox]::before {
    background-color: transparent;
}

// input[type=email] {
//     padding: 0 10px;
// }

input[type=color] {
    cursor: pointer;
}

input[type=text] {
    // padding: 0 10px;

    &:hover {
        cursor: text;
    }
}

input[type=password] {
    // padding: 0 10px;

    &:hover {
        cursor: text;
    }
}

// input[type=number] {
//     padding: 5px;
// }

input[type=date] {
    // padding: 5px;
    color: $color-gray-4;
    max-width: 80%;

    &:hover::-webkit-calendar-picker-indicator {
        filter: invert(51%) sepia(99%) saturate(381%) hue-rotate(161deg) brightness(87%) contrast(96%);
    }

    &::-webkit-calendar-picker-indicator {
        opacity: 1;
        display: block;
        background: url('@/assets/icons/calendar.svg') no-repeat;
        filter: invert(99%) sepia(0%) saturate(1%) hue-rotate(134deg) brightness(89%) contrast(92%);
        width: 16px;
        height: 16px;
    }
}


input[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 50%;
    border: none;
    border-radius: 0;
    width: 3.3vw;
    height: 32px;
    padding: 0;
    margin: 0;
    border-top: 1px solid #D7DCE0;
    border-bottom: 1px solid #D7DCE0;

    // transition: all ease 0.5s;

    &:checked::after {
        color: rgb(255, 255, 255)
    }

    &:focus {
        outline: none;
        // background-color: $color-blue-1;
    }
}

$bg-opacity: 0.8;
$bg-opacity-hover: 0.4;


// @mixin checkbox-animation {
//     animation: checked-appearing;
//     animation-duration: 0.5s;
//     animation-timing-function: ease-in-out;
// }

@keyframes checked-appearing {
    0% {
        background: rgba($color-gray-0, 0.5);
        box-shadow: none;
    }
}

input[status="red"] {
    transition: all ease 0.5s;

    &:checked {
        background-color: rgba($color-red-1, $bg-opacity);
        border: 1px rgba($color-red-1, $bg-opacity);

        &:disabled {
            background-color: rgba($color-red-1, $bg-opacity-hover);
        }
    }

    &:hover {
        cursor: pointer;
        background-color: rgba($color-red-1, $bg-opacity-hover);
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

// region Статусы
input[status="green"] {
    transition: all ease 0.5s;


    &:checked {
        background-color: rgba($color-green-1, $bg-opacity);
        border: 1px rgba($color-green-1, $bg-opacity);

        &:disabled {
            background-color: rgba($color-green-1, $bg-opacity-hover);
        }
    }

    &:hover {
        cursor: pointer;
        background-color: rgba($color-green-1, $bg-opacity-hover);
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

.col__content__inputs {
    display: flex;
    align-items: center;
}

.col__content__inputs>div {
    position: relative;
    display: flex;
    align-items: center;
}

.radio-wrapper>input::before {
    content: '';
    position: absolute;
    // display: inline-block;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    // display: flex;
    // align-items: center;
    width: 1px;
    height: 15px;
    background-color: #D7DCE0;
}

.radio-wrapper>input:last-child {
    border-right: 1px solid #D7DCE0;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}

.radio-wrapper>input:first-child {
    border-left: 1px solid #D7DCE0;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}

.radio-wrapper>input:first-child::before {
    content: '';
    background: none;
}

.radio-wrapper>input:checked::before {
    content: '';
    background: none;
}

.radio-wrapper>input:checked+input::before {
    content: '';
    background: none;
}


input[status="blue"] {
    transition: all ease 0.5s;

    &:checked {
        background-color: rgba(#B3D040, 1);
        border: 1px rgba(#B3D040, 1);

        &:disabled {
            background-color: rgba(#B3D040, 0.4);
        }
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(#B3D040, 0.4);
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

input[status="yellow"] {
    transition: all ease 0.5s;

    &:checked {
        background-color: rgba($color-yellow-1, $bg-opacity);
        border: 1px rgba($color-yellow-1, $bg-opacity);

        &:disabled {
            background-color: rgba($color-yellow-1, $bg-opacity-hover);
        }
    }

    &:hover {
        cursor: pointer;
        background-color: rgba($color-yellow-1, $bg-opacity-hover);
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

input[type=radio]::after {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: rgba(0, 0, 0, .4)
}


input[status="red"]::after {
    content: "1";

}

input[status="yellow"]::after {
    content: "2";

}

input[status="blue"]::after {
    content: "3";

}

input[status="green"]::after {
    content: "4";

}



input[status="none"] {
    transition: all ease 0.5s;
    background-color: transparent;
}


// endregion


.col__content__inputs {
    // padding: 0 20px 0 0;
    display: flex;
    justify-content: space-around;
}

.input-radio__caption {
    margin-top: -35px;
    margin-left: 10px;
}

.input__table {
    min-height: 100%;
    border: none;
    border-radius: 0;
    background: transparent;

    &:focus {
        border: none;
    }
}

// .uploader {
//     @include 
// }

.sip-form {
    display: flex;
    // flex-grow: 1;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;

    &>.btn-file {
        width: 150px;
    }
}

// .sip-form {
//     display: flex;
//     gap: 10px;
//     align-items: flex-end;
// }

input[type=file] {
    // .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

// input[type=file] + p {
//     // font-size: 1.25em;
//     padding: 5px 10px;
//     font-weight: 700;
//     color: white;
//     background-color: black;
//     display: inline-block;
//     cursor: pointer;
// }

// input[type=file]:focus + p,
// input[type=file] + p:hover {
//     background-color: red;
//     outline: 1px dotted #000;
//     outline: -webkit-focus-ring-color auto 5px;
// }

// &[disabled] {
//     background-color:$color-white;
//     border: none;
// }

// &:hover {
//     background-color: $color-gray-0;
//     cursor: pointer;
// }
// &[disabled]:hover {
//     background-color:$color-white;
//     cursor: default;
//     &::file-selector-button {
//         cursor: default;
//     }
// }

// &:focus {
//     border: 2px solid rgb($color-blue-4, 0.5);
//     background-color: $color-blue-1;
// }</style>