<template>
    <div class="block-group">
        <div class="subtitle-header">Основные данные</div>
        <div class="block">
            <label>Название проекта<span v-if="isTempCreation && creatingData[0].required"
                    class="form-label-required">*</span></label>
            <sip-input name="name" type="text" v-model="APIdata.name" @blur="getInputValue($event, APIdata.name)"
                @keyup.enter="getInputValue($event, APIdata.name)" @focus="saveInitialValue($event.target.value)"
                :disabled="expertPermission()" />
        </div>
        <div class="block-row">
            <div class="block">
                <label>Фаза проекта</label>
                <sip-input name="phase" type="text" v-model="APIdata.phase" @blur="getInputValue($event, APIdata.phase)"
                    @keyup.enter="getInputValue($event, APIdata.phase)" @focus="saveInitialValue($event.target.value)"
                    :disabled="expertPermission()" />
            </div>
            <div class="block" v-if="isBlockChange">
                <label>Этап проекта</label>
                <sip-select :isNullAllowed="isNullAllowed" name="stage" :options="stagesObjArray"
                    v-model="APIdata.stage" :disabled="true" @change="getSelectValue($event, APIdata.stage)" />
            </div>
        </div>
        <div class="block">
            <label>Краткая характеристика проекта</label>
            <sip-textarea name="description" type="text" v-model="APIdata.description"
                @blur="getInputValue($event, APIdata.purpose)" @focus="saveInitialValue($event.target.value)"
                :disabled="expertPermission()" />
        </div>
        <div class="block">
            <label>Тип объекта / актива</label>
            <sip-select :isNullAllowed="isNullAllowed" name="type" :options="typesObjArray" v-model="APIdata.type"
                @change="getSelectValue($event, APIdata.type, 'type')" :disabled="expertPermission()" />
        </div>
        <div class="block">
            <label>Цели проекта</label>
            <sip-textarea name="purpose" type="text" v-model="APIdata.purpose"
                @blur="getInputValue($event, APIdata.purpose)" @keyup.enter="getInputValue($event, APIdata.purpose)"
                @focus="saveInitialValue($event.target.value)" :disabled="expertPermission()" />
        </div>
    </div>
    <div class="block-group" v-for="group in inputGroups" :key="group.title">
        <div :class="['subtitle-header', group.title == 'Показатели бизнес-кейса' ? 'indent' : '']">{{ group.title }}
        </div>
        <div class="block" v-for="input in group.inputs" :key="input.name">
            <label>{{ input.label }}</label>
            <sip-input v-if="input.name === 'capital_cost' && isCostProfileExist" :name="input.name" :type="input.type"
                :modelValue="summaryCapitalCost" min="0" disabled />
            <sip-input v-else :name="input.name" :type="input.type" v-model="APIdata[input.model]" min="0"
                :disabled="expertPermission()" @blur="getInputValue($event, APIdata[input.model])"
                @keyup.enter="getInputValue($event, APIdata[input.model])"
                @focus="saveInitialValue($event.target.value)" />
        </div>
    </div>
</template>

<script>
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mFilters } from "@/utils/filters";
import { mPermissions } from "@/utils/permissions";
import { mText } from "@/utils/text";
import { push } from "notivue";

export default {
    name: "TableProjectDetail",
    mixins: [mDate, mFile, mPermissions, mFilters, mText],
    props: {
        commonsData: {
            type: Object,
            default: null,
        },
        finExpenses: {
            type: Object,
            default: null,
        },
        projectStage: {
            type: Object,
            default: null,
        },
        objectType: {
            type: Object,
            default: null,
        },
        isTempCreation: {
            type: Boolean,
            default: false,
        },
        creatingDataArray: {
            type: Object,
            default: null,
        },
        isBlockChange: {
            type: Boolean,
            default: false,
        },
        isNullAllowed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tempInputValue: "undefined",
            APIdata: this.commonsData,
            costProfile: this.finExpenses,
            stagesObjArray: this.projectStage,
            typesObjArray: this.objectType,
            creatingData: this.creatingDataArray,
            inputGroups: [
                {
                    title: 'Показатели длительности',
                    inputs: [
                        { name: 'planned_duration', label: 'Плановая продолжительность проекта, мес.', type: 'number', model: 'planned_duration' },
                        { name: 'actual_duration', label: 'Фактический / прогнозный срок, мес.', type: 'number', model: 'actual_duration' },
                    ],
                },
                {
                    title: 'Финансовые показатели',
                    inputs: [
                        { name: 'capital_cost', label: 'Капитальные вложения, млрд.руб.', type: 'number', model: 'capital_cost' },
                        { name: 'security_cost', label: 'Затраты на безопасность, тыс.руб.', type: 'number', model: 'security_cost' },
                    ],
                },
                {
                    title: 'Технологические показатели',
                    inputs: [
                        { name: 'gas_flowrate', label: 'Объем добычи, млрд м³/год', type: 'number', model: 'gas_flowrate' },
                        { name: 'team_size', label: 'Проектная команда, человек', type: 'number', model: 'team_size' },
                    ],
                },
                {
                    title: 'Драйверы проекта',
                    inputs: [
                        { name: 'first_driver', label: 'Главный драйвер', type: 'text', model: 'first_driver' },
                        { name: 'second_driver', label: 'Второстепенный драйвер', type: 'text', model: 'second_driver' },
                        { name: 'third_driver', label: 'Третий драйвер по приоритету', type: 'text', model: 'third_driver' },
                    ],
                },
                {
                    title: 'Показатели бизнес-кейса',
                    inputs: [
                        { name: 'net_present_value', label: 'NPV, млн.руб.', type: 'number', model: 'net_present_value' },
                        { name: 'internal_rate_of_return', label: 'IRR, %', type: 'number', model: 'internal_rate_of_return' },
                        { name: 'profit_to_investment_ratio', label: 'PI, индекс', type: 'number', model: 'profit_to_investment_ratio' },
                    ],
                },
            ],
        };
    },
    computed: {
        isCostProfileExist() {
            return this.costProfile.length != 0;
        },
        summaryCapitalCost() {
            if (this.costProfile.length == 0) {
                return;
            }
            let sum = 0;
            this.costProfile.forEach((el) => {
                let summary_cost = 0;
                Object.keys(el).forEach((key) => {
                    if (!["id", "year", "measurement_unit", "project"].includes(key)) {
                        summary_cost += this.isNumeric(el[key]) ? Number(el[key]) : 0;
                    }
                });
                sum += summary_cost * el.measurement_unit;
            });
            return sum / 1e9;
        },
    },
    methods: {
        isNumeric(str) {
            if (typeof str == "number") return true
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        //  #region - Блок работы с значениями и инпутами

        /** Запоминаем временное значение  */
        saveInitialValue(value_) {
            this.tempInputValue = value_;
        },

        /** Сохранение данных в инпуте  */
        getInputValue(event, val) {
            if (this.tempInputValue == event.target.value) return;
            if (this.isTempCreation) {
                this.$emit("update:tempCreationCostProfileData", this.APIdata);
                return;
            }
            const field = event.target.name;
            const value = event.target.value == "" ? null : event.target.value;
            this.updateCommonProjectData(field, value)
                .then(() => {
                    this.tempInputValue = value;
                    push.success(`Параметр проекта "${field}" успешно обновлен!`);
                }).catch(() => {
                    this.APIdata[field] = this.tempInputValue;
                    push.error(`Ошибка обновления параметра "${field}"!`);
                });
            document.activeElement.blur();
            if (field == "name") {
                localStorage.setItem("projectName", value);
            }
        },

        /** Выбор значения из селекта  */
        getSelectValue(event, oldValue_, field_ = null) {
            if (this.isTempCreation) {
                this.$emit("update:tempCreationCostProfileData", this.APIdata);
                return;
            }
            this.updateCommonProjectData(field_, event.target.value);
        },

        //#endregion

        // #region - Блок запросов на обновление объектов
        /** Общая функция для обновления объектов  */
        async updateObject(
            apiSection, objectId, updates,
            // successMessage, errorMessage
        ) {
            const payload = {
                apiSection,
                objectId,
                ...updates,
            };
            return await this.$store.dispatch("project/patchObject", payload);
            // .then(() => push.success(successMessage))
            // .catch(() => push.error(errorMessage));
            // try {
            //     // push.success(successMessage);
            // } catch (error) {
            //     push.error(errorMessage);
            // }
        },

        /** Обновление "Общие сведения"  */
        async updateCommonProjectData(field_, value_) {
            const projectId = this.$route.params.id_proj;
            await this.updateObject(
                "projects",
                projectId,
                { [field_]: value_ },
                // `Параметр проекта "${field_}" успешно обновлен!`,
                // `Ошибка обновления параметра "${field_}"!`
            );
        },
        //#endregion
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.block-group {
    max-width: 28%;
}

.page {
    height: 100%;
    z-index: 3;
    position: relative;
}

.subtitle {
    font-size: 14px;
    width: 100%;
    padding-bottom: 0px;
    font-family: "sf-pro-display-light";
}

.subtitle-header {
    font-size: 18px;
    width: 100%;
    padding-bottom: 10px;
    font-family: "sf-pro-display-light";

    &.indent {
        margin-top: 80px;
    }
}

.select {
    &:hover {
        background: url("@/assets/icons/select_arrow.svg") no-repeat right !important;
        background-position-x: calc(100% - 15px) !important;
    }
}

.select__table:has(> option[value="null"]:checked) {
    background: url("@/assets/icons/select_arrow_blue.svg") no-repeat right !important;
    background-position-x: calc(100% - 15px) !important;
    color: #2f9adb !important;
}

textarea {
    width: 100%;
    background: transparent;
}

.content {
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    z-index: 3;
    position: relative;
}

.block>.text {
    font-family: $sf-light;
    font-size: 12px;

    &>span.bold {
        font-family: $sf-bold;
    }
}

.content-block {
    padding: 0 15px;
    overflow-y: hidden !important;
}

.content-block.row {
    gap: 20px;
}

input {
    background-color: rgba(255, 255, 255, 0.5);
}
</style>
