export const mAnswer = {
    methods: {
        /** Установка цвета для ответов  */
        setColorTotalAnswers(answers, questions) {
            return {
                color: answers == questions ? "#2FAD30" : "#363639",
                fontFamily: answers == questions ? "sf-pro-display-semibold" : "sf-pro-display-medium",
            }
        },
        /** Установка цвета флага  */
        setFlagColor(answers, questions) {
            let myFilter;
            if (answers == questions) {
                myFilter = "invert(50%) sepia(69%) saturate(539%) hue-rotate(71deg) brightness(95%) contrast(89%)"
            } else {
                myFilter = "invert(99%) sepia(0%) saturate(15%) hue-rotate(343deg) brightness(88%) contrast(91%)"
            }
            return {
                filter: myFilter
            }
        },
        /** Установка флага для ответов  */
        responseStatus(answers, questions) {
            return answers == questions ? require("@/assets/icons/check_mark.svg") : require("@/assets/icons/cross_mark.svg")
        },
    }
}
