import moment from 'moment';

export const mDate = {
    methods: {
        getDateDuration(date_) {
            let now = Date.now()
            let date = Date.parse(date_)
            let diff = now - date

            let seconds = diff / 1000
            if (seconds < 60) {
                let roundedValue = Math.round(seconds);
                let timeDesc = 'секунд';
                if (
                    roundedValue == 1 || roundedValue == 21 || roundedValue == 31 ||
                    roundedValue == 41 || roundedValue == 51
                ) {
                    timeDesc = timeDesc + 'у';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4 ||
                    roundedValue == 22 || roundedValue == 23 || roundedValue == 24 ||
                    roundedValue == 32 || roundedValue == 33 || roundedValue == 34 ||
                    roundedValue == 42 || roundedValue == 43 || roundedValue == 44 ||
                    roundedValue == 52 || roundedValue == 53 || roundedValue == 54
                ) {
                    timeDesc = timeDesc + 'ы';
                }
                return `${roundedValue} ${timeDesc} назад`;
            }

            let minutes = seconds / 60
            if (minutes < 60) {
                let roundedValue = Math.round(minutes)
                let timeDesc = 'минут';
                if (
                    roundedValue == 1 || roundedValue == 21 || roundedValue == 31 ||
                    roundedValue == 41 || roundedValue == 51
                ) {
                    timeDesc = timeDesc + 'у';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4 ||
                    roundedValue == 22 || roundedValue == 23 || roundedValue == 24 ||
                    roundedValue == 32 || roundedValue == 33 || roundedValue == 34 ||
                    roundedValue == 42 || roundedValue == 43 || roundedValue == 44 ||
                    roundedValue == 52 || roundedValue == 53 || roundedValue == 54
                ) {
                    timeDesc = timeDesc + 'ы';
                }
                return `${roundedValue} ${timeDesc} назад`
            }

            let hours = minutes / 60
            if (hours < 24) {
                let roundedValue = Math.round(hours)
                let timeDesc = 'час';
                if (
                    roundedValue == 1 || roundedValue == 21
                ) {
                    timeDesc = timeDesc + '';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4 ||
                    roundedValue == 22 || roundedValue == 23 || roundedValue == 24
                ) {
                    timeDesc = timeDesc + 'а';
                } else {
                    timeDesc = timeDesc + 'ов';
                }
                return `${roundedValue} ${timeDesc} назад`
            }

            let days = hours / 24
            if (days < 7) {
                let roundedValue = Math.round(days)
                let timeDesc = '';
                if (
                    roundedValue == 1
                ) {
                    timeDesc = 'день';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4
                ) {
                    timeDesc = 'дня';
                } else {
                    timeDesc = 'дней';
                }
                return `${roundedValue} ${timeDesc} назад`
            }

            let weeks = days / 7
            if (weeks < 4) {
                let roundedValue = Math.round(weeks)
                let timeDesc = 'недел';
                if (
                    roundedValue == 1
                ) {
                    timeDesc = timeDesc + 'ю';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4
                ) {
                    timeDesc = timeDesc + 'и';
                }
                return `${roundedValue} ${timeDesc} назад`
            }

            let months = weeks / 4
            if (months < 12) {
                let roundedValue = Math.round(months)
                let timeDesc = 'месяц';
                if (
                    roundedValue == 1
                ) {
                    timeDesc = timeDesc + '';
                } else if (
                    roundedValue == 2 || roundedValue == 3 || roundedValue == 4
                ) {
                    timeDesc = timeDesc + 'а';
                } else {
                    timeDesc = timeDesc + 'ев';
                }
                return `${roundedValue} ${timeDesc} назад`
            }

            let years = months / 12
            let roundedValue = Math.round(years)
            let timeDesc = 'год';
            if (
                roundedValue == 1 || roundedValue == 21 || roundedValue == 31 ||
                roundedValue == 41 || roundedValue == 51 || roundedValue == 61 ||
                roundedValue == 71 || roundedValue == 81 || roundedValue == 91
            ) {
                timeDesc = timeDesc + ''
            } else if (
                roundedValue == 2 || roundedValue == 3 || roundedValue == 4 ||
                roundedValue == 22 || roundedValue == 23 || roundedValue == 24 ||
                roundedValue == 32 || roundedValue == 33 || roundedValue == 34 ||
                roundedValue == 42 || roundedValue == 43 || roundedValue == 44 ||
                roundedValue == 52 || roundedValue == 53 || roundedValue == 54 ||
                roundedValue == 62 || roundedValue == 63 || roundedValue == 64 ||
                roundedValue == 72 || roundedValue == 73 || roundedValue == 74 ||
                roundedValue == 82 || roundedValue == 83 || roundedValue == 84 ||
                roundedValue == 92 || roundedValue == 93 || roundedValue == 94
            ) {
                timeDesc = timeDesc + 'а';
            } else {
                timeDesc = 'лет'
            }
            return `${roundedValue} ${timeDesc} назад`
        },
        dateFormat(date) {
            return moment(date).format('DD.MM.YYYY (HH:mm)');
        },
        getDifferenceInDays(start_date_, finish_date_) {
            return Math.abs(Date.parse(finish_date_) - Date.parse(start_date_)) / 86400000;
        },
    }
}