<template>
	<div class="gantt" v-if="data.length !== 0">
		<!-- Периоды -->
		<div class="gantt__period" :style="getHeadColumnCount">
			<span v-if="isAssignShow"></span>
			<span v-for="el in periods" :key="el">{{ getFormattedPeriod(el.period) }}
			</span>
		</div>

		<!-- Строки с задачами -->
		<div id="gantt_roadmap" class="gantt__wrapper" :class="!isAssignShow ? 'gantt__scroll' : ''">
			<!-- Ряды -->
			<div class="gantt__lines" :style="getHeadColumnCount">
				<div v-if="isAssignShow"></div>
				<div v-for="period in periods" :key="period"></div>
			</div>
			<div class="gantt__row" v-for="task in tasks" :key="task" :style="getRowColumnCount">
				<div v-if="isAssignShow" class="gantt__assign">{{ task.name }}</div>
				<div class="gantt__tasks" :style="getTaskColumnCount">
					<!-- <Transition name="bubble"> -->
					<div class="gantt__task" :style="getColumnDuration(task)">
						<!-- Тултип -->
						<div v-if="showTooltip" class="gantt__tooltip">{{ getTaskPeriods(task) }}</div>
					</div>
					<!-- </Transition> -->
				</div>
			</div>
			<div v-if="!isAssignShow" class="gantt__row">
				<div class="gantt__tasks"></div>
			</div>
		</div>

	</div>
</template>

<script>
const MS_IN_DAY = 86400000
const MAX_PERIODS = 20
const MONTH_TO_QUARTER_MONTHS = {
	0: 0, 1: 0, 2: 0,
	3: 3, 4: 3, 5: 3,
	6: 6, 7: 6, 8: 6,
	9: 9, 10: 9, 11: 9,
}
const MONTH_TO_QUARTERS = {
	0: 1, 1: 1, 2: 1,
	3: 2, 4: 2, 5: 2,
	6: 3, 7: 3, 8: 3,
	9: 4, 10: 4, 11: 4,
}
const QUARTER_TO_MONTH_START = {
	1: 0,
	2: 3,
	3: 6,
	4: 9,
}
const QUARTER_TO_MONTH_END = {
	1: 2,
	2: 5,
	3: 8,
	4: 11,
}

export default {
	name: 'sip-gantt',
	data() {
		return {
			contentWidth: 0,
			ganttCursorPositionX: 0,
			leftCursor: 0,
			topCursor: 0,

			tasks: [],
			periods: [],
			totalPeriods: [],

			minDate: null,
			maxDate: null,
			totalDays: null,

			dayOptions: {
				// year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			},
			quartersOptions: {
				year: 'numeric',
				month: 'numeric',
			},
			monthOptions: {
				year: 'numeric',
				month: 'numeric',
			},
			yearOptions: {
				year: 'numeric',
			},
			innerFreq: 'year',
			freqChoices: [
				{ id: 'year', name: 'Год' },
				{ id: 'quarter', name: 'Квартал' },
				{ id: 'month', name: 'Месяц' },
				{ id: 'day', name: 'День' },
			],
		}
	},
	props: {
		data: Array,
		// freqChoices: Array,
		// freq: {
		// 	type: String,
		// 	default: 'quarter'
		// },
		isAssignShow: {
			type: Boolean,
			default: true,
		},
		assignWidthPercentage: {
			type: Number,
			default: 20,
		},
		showTooltip: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		getColumnDuration(task) {
			return {
				gridColumn: `${task.startPos} / ${task.endPos}`
			}
		},
		updateBoundaries() {
			this.minDate = new Date(Math.min(...this.data.map(el => new Date(el.start_date))));
			this.maxDate = new Date(Math.max(...this.data.map(el => new Date(el.end_date))));
			this.totalDays = (this.maxDate - this.minDate) / MS_IN_DAY
			if (this.totalDays <= MAX_PERIODS) {
				this.innerFreq = 'day'
			} else if (this.totalDays > MAX_PERIODS && this.totalDays <= MAX_PERIODS * 25) {
				this.innerFreq = 'month'
			} else if (this.totalDays > MAX_PERIODS * 25 && this.totalDays <= MAX_PERIODS * 22 * 3) {
				this.innerFreq = 'quarter'
			} else {
				this.innerFreq = 'year'
			}
		},
		setGanttChartProperties() {

			function equalDates(date_1, date_2) {
				return date_1.toLocaleDateString() == date_2.toLocaleDateString()
			}

			const tmpData = [...this.data];

			const minDate = this.minDate;
			const minYear = minDate.getFullYear();
			const minMonth = minDate.getMonth();

			const maxDate = this.maxDate;
			const maxYear = maxDate.getFullYear();
			const maxMonth = maxDate.getMonth();

			const dateModifier = this.innerFreq;

			let tmpPeriods = [];
			let periods = [];
			let tmpStart, tmpEnd;

			// Boundaries overriding
			if (dateModifier == 'day') {
				tmpStart = new Date(minDate);
				tmpEnd = new Date(maxDate);
			} else if (dateModifier == 'month') {
				tmpStart = new Date(minYear, minMonth, 1);
				tmpEnd = new Date(maxYear, maxMonth + 1, 0);
			} else if (dateModifier == 'year') {
				tmpStart = new Date(minYear, 0, 1);
				tmpEnd = new Date(maxYear, 11, 0);
			} else if (dateModifier == 'quarter') {
				tmpStart = new Date(minYear, MONTH_TO_QUARTER_MONTHS[minMonth], 1);
				tmpEnd = new Date(maxYear, QUARTER_TO_MONTH_END[MONTH_TO_QUARTERS[maxMonth]] + 1, 0);
			}
			// Date sequence generation
			while (tmpStart <= tmpEnd) {
				tmpPeriods.push(new Date(tmpStart))
				tmpStart.setDate(tmpStart.getDate() + 1);
			}


			if (dateModifier == 'day') {
				tmpStart = new Date(minDate)
				tmpEnd = new Date(maxDate)
				while (tmpStart <= tmpEnd) {
					periods.push(new Date(tmpStart))
					tmpStart.setDate(tmpStart.getDate() + 1);
				}
				periods.push(new Date(tmpStart))
			} else if (dateModifier == 'month') {
				tmpStart = new Date(minYear, minMonth, 1)
				tmpEnd = new Date(maxYear, maxMonth, 1)
				while (tmpStart <= tmpEnd) {
					periods.push(new Date(tmpStart))
					tmpStart.setMonth(tmpStart.getMonth() + 1);
				}
				periods.push(new Date(tmpStart))
			} else if (dateModifier == 'year') {
				tmpStart = new Date(minYear, 0, 1)
				tmpEnd = new Date(maxYear, 0, 1)
				while (tmpStart <= tmpEnd) {
					periods.push(new Date(tmpStart))
					tmpStart.setFullYear(tmpStart.getFullYear() + 1);
				}
				periods.push(new Date(tmpStart))
			} else if (dateModifier == 'quarter') {
				tmpStart = new Date(minYear, minMonth, 1);
				tmpEnd = new Date(maxYear, QUARTER_TO_MONTH_END[MONTH_TO_QUARTERS[maxMonth]] + 1, 0);
				while (tmpStart <= tmpEnd) {
					periods.push(new Date(tmpStart));
					tmpStart.setMonth(tmpStart.getMonth() + 1);
				}
				periods.push(new Date(tmpStart));
				periods = periods.map(
					el => new Date(el.getFullYear(), QUARTER_TO_MONTH_START[MONTH_TO_QUARTERS[el.getMonth()]], 1)
				).filter(
					(date, i, self) => self.findIndex(d => equalDates(d, date)) === i
				)
			}
			periods = periods.map(el => { return { period: el } });

			let totalDuration = (periods[periods.length - 1].period - periods[0].period) / MS_IN_DAY;
			if (this.isAssignShow) totalDuration /= (1 - this.assignWidthPercentage / 100);

			let periodsDurations = [];
			for (let i = 0; i < periods.length - 1; i++) {
				let startDate = new Date(periods[i].period)
				let endDate = new Date(periods[i + 1].period)
				periodsDurations.push(
					{
						period: periods[i].period,
						fraction: `${((endDate - startDate) / MS_IN_DAY) / totalDuration * 100}%`,
					}
				)
			}
			periods = periods.map(el => el.period)

			// Расчет границ периодов для конкретных задач в рамках общей сетки
			let modifiedData = []
			tmpData.forEach(el => {
				let startDate, endDate, startDateMod, endDateMod, duration;
				let startPos = 1;
				let endPos = 1;
				startDate = new Date(el.start_date)
				endDate = new Date(el.end_date)

				startDateMod = startDate
				endDateMod = endDate
				for (let i = 0; i < tmpPeriods.length; i++) {
					let el = tmpPeriods[i]
					let res = equalDates(el, startDate);
					if (res) {
						startPos = i + 1
						break
					}
				}
				duration = (endDateMod - startDateMod) / MS_IN_DAY + 1
				endPos = startPos + duration

				modifiedData.push({
					name: el.name,
					startDateOriginal: startDate,
					endDateOriginal: endDate,
					startDate: startDateMod,
					endDate: endDateMod,
					duration: duration,
					startPos: startPos,
					endPos: endPos,
					// status: status
				})
			})
			this.tasks = modifiedData
			this.totalPeriods = tmpPeriods
			this.periods = periodsDurations
		},
		setChartCapStyle(yearArray_) {
			return {
				width: `${yearArray_[2]}%`,
			};
		},
		getTaskPeriods(task) {
			let startDate = task.startDateOriginal.toLocaleDateString('ru-RU', this.dayOptions);
			let endDate = task.endDateOriginal.toLocaleDateString('ru-RU', this.dayOptions);
			return `${startDate} - ${endDate}`
		},
		getFormattedPeriod(period) {
			if (this.innerFreq == 'quarter')
				return Object.values(QUARTER_TO_MONTH_START).includes(period.getMonth()) && period.getDate() == 1 ? `${MONTH_TO_QUARTERS[period.getMonth()]} кв. ${period.getFullYear()}` : ''
			return Intl.DateTimeFormat('ru-RU', this.getOptions).format(period)
		},
		synchronizeScrolls() {
			var ignoreScrollEvents = false
			function syncScroll(element1, element2) {
				element1.addEventListener('scroll', (() => {
					var ignore = ignoreScrollEvents;
					ignoreScrollEvents = false;
					if (ignore) return;

					ignoreScrollEvents = true;
					element2.scrollTop = element1.scrollTop;
				}))
			}

			let gantt_table = document.getElementById('gantt_table');
			let gantt_roadmap = document.getElementById('gantt_roadmap');

			if (gantt_table && gantt_roadmap) {
				syncScroll(gantt_table, gantt_roadmap);
				syncScroll(gantt_roadmap, gantt_table);
			}

		},
	},
	computed: {
		getHeadColumnCount() {
			let assignColumn = this.isAssignShow ? `${this.assignWidthPercentage}% ` : '';
			return {
				gridTemplateColumns: assignColumn + this.periods.map(el => el.fraction).join(' '),
			}
		},
		getRowColumnCount() {
			let assignColumn = this.isAssignShow ? `${this.assignWidthPercentage}% ` : '';
			return {
				gridTemplateColumns: assignColumn + `1fr`,
			}
		},
		getColumnCount() {
			return {
				gridTemplateColumns: this.periods.map(el => el.fraction).join(' '),
			}
		},
		getTaskColumnCount() {
			return {
				gridTemplateColumns: `repeat(${this.totalPeriods.length}, 1fr)`,
			}
		},
		getOptions() {
			if (this.innerFreq == 'year') return this.yearOptions;
			if (this.innerFreq == 'month') return this.monthOptions;
			if (this.innerFreq == 'quarter') return this.quartersOptions;
			if (this.innerFreq == 'day') return this.dayOptions;
			return {};
		}
	},
	watch: {
		data: {
			handler() {
				this.updateBoundaries();
				this.setGanttChartProperties();
			},
			deep: true
		}
	},
	mounted() {
		this.data ? this.updateBoundaries() : null;
		this.data ? this.setGanttChartProperties() : null;
		this.synchronizeScrolls();
	}
}
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";
@import "@/css/components/scrollbar.scss";

.gantt {
	position: relative;
	width: 100%;
	height: min-content;
}

.gantt__lines {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 1fr;
	grid-auto-flow: column;
}

.gantt__lines div {

	&:nth-child(2n) {
		background: rgba(#cfdbe4, 0.3)
	}

	&:last-child {
		border-right: none;
	}
}

.gantt__period {
	display: grid;
	grid-auto-flow: column;
	position: relative;
	border-bottom: .5px solid rgba(#A8A8A8, 1);
	background: white;
	z-index: 1;
	height: 36px;

	& span {
		align-items: center;
		display: flex;
		justify-content: center;
		font-size: 10px;
		font-family: $sf-medium;
		color: #727272;
		text-wrap: wrap;
	}
}

.gantt__scroll {
	max-height: 62vh;
	overflow-y: auto;
}

.gantt__wrapper {
	height: auto;
}

.gantt__row {
	align-items: center;
	display: grid;
	padding: 0.5rem 0;
	background: none;
	border-bottom: .5px solid rgba(#A8A8A8, .4);
	height: 50px;
}

.gantt__tasks {
	display: grid;
	grid-auto-flow: column;
	grid-row-gap: 0.25rem;
	z-index: 1;
}

.gantt__assign {
	padding: 0 0.25rem;
	font-size: 13px;
	z-index: 1;
}

.gantt__task {
	border-radius: 1rem;
	height: 21px;
	cursor: pointer;
	transition: all 200ms ease;
	position: relative;
	background: #2F9ADB;
	animation: bubble;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;

	&:hover {
		background: rgba(#2F9ADB, 0.8);

		&>.gantt__tooltip {
			opacity: 1;
		}
	}
}

@keyframes bubble {
	0% {
		transform: translateX(-25%) scaleX(50%);
	}

	100% {
		transform: translateX(0%) scaleX(100%);
	}
}

// .gantt__task--done {
// 	background: #2F9ADB;
// }

// .gantt__task--todo {
// 	background: #2F9ADB;
// }

// .gantt__task--inprogress {
// 	background: #2F9ADB;
// }

// .gantt__task--overdue {
// 	background: #2F9ADB;
// }

.gantt__tooltip {
	opacity: 0;
	position: absolute;
	width: max-content;
	padding: 5px;
	font-size: 13px;
	transition: opacity 200ms ease;
	border-radius: 5px;

	background-color: white;
	left: 8px;
	bottom: 25px;
	box-shadow: 0 10px 20px 10px rgba(black, 0.1);

	&::after {
		position: absolute;
		bottom: -8px;
		left: 0;
		content: '';
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 8px solid white;
	}

}

// .bubble-enter-active,
// .bubble-leave-active {
// 	transition: opacity 0.5s ease;
// }

// .bubble-enter-from,
// .bubble-leave-to {
// 	opacity: 0;
// }</style>
