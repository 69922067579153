const measurementUnits = {
    'млрд. руб.': 1000000000,
    'млн. руб.': 1000000,
    'тыс. руб.': 1000,
}

export {
    measurementUnits,
}



