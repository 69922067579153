<template>
	<transition name="expand" mode="out-in">
		<div class="sidebar_general">

			<!-- область с размытием экрана -->
			<transition name="blured_window_trns">
				<div v-if="isSidebarOpened" class="blurred_window" :style="setBlurredWindowStyle"
					@click="changeSidebarWidth"></div>
			</transition>

			<div class="sidebar_body" :style="setSidebarStyle">

				<!-- основной блок с кнопками -->
				<div class="sidebar_buttons_block">
					<!-- #region - блок с верхним лого -->
					<div class="cap_logo_block" :style="setCapLogoStyle">
						<transition name="cubelogo_firststate_trns">
							<img v-if="!isSidebarOpen" class="img_cap_logo" src="@/assets/logos/logo_sidebar_1.svg"
								@click="setSection('projects')" />
						</transition>

						<div class="cap_logo_block_inner">
							<transition name="cubelogo_secondstate_trns">
								<img v-if="isSidebarOpen" class="img_cap_logo2a" src="@/assets/logos/logo_sidebar_1.svg"
									@click="setSection('projects')" />
							</transition>
							<transition name="textlogo_trns">
								<img v-if="isSidebarOpen" class="img_cap_logo2b" src="@/assets/logos/logo_sidebar_2.svg"
									@click="setSection('projects')" />
							</transition>
						</div>
					</div>
					<!-- #endregion -->

					<!-- #region - средний блок с кнопками -->
					<div class="main_buttons_block">
						<!-- #region - список кнопок (первые кнопки) -->
						<div class="sidebar_block_first">
							<ul class="sidebar_block_list">
								<li class="sidebar_button" :selected="checkIsProjectSection()"
									@click="setSection('projects')" @mouseenter="handleMouseEnter('project')"
									@mouseleave="handleMouseLeave">
									<img class=" button_img" src="@/assets/buttons/pojects_list.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">ПРОЕКТЫ</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.project" class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												СПИСОК ПРОЕКТОВ</div>
										</div>
									</Transition>
								</li>
								<li class="sidebar_button" :selected="checkIsUserSection()" @click="setSection('users')"
									:disabled="checkUserPermission()" @mouseenter="handleMouseEnter('users')"
									@mouseleave="handleMouseLeave">
									<img class="button_img" src="@/assets/buttons/users_list.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">ПОЛЬЗОВАТЕЛИ</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.users" class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												СПИСОК ПОЛЬЗОВАТЕЛЕЙ</div>
										</div>
									</Transition>
								</li>
							</ul>
						</div>
						<!-- #endregion -->

						<div class="sidebar_divider"></div>

						<!-- #region - список кнопок (вторые кнопки) -->
						<div v-if="checkIsProjectSection()" class="sidebar_block_second">
							<ul class="sidebar_block_list">
								<li class="sidebar_button" :selected="$route.name == 'createProject'"
									@click="setSubSection('createProject')" :disabled="checkUserPermission()"
									@mouseenter="handleMouseEnter('create_project')" @mouseleave="handleMouseLeave">
									<img class="button_img" src="@/assets/buttons/add_new_project.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">СОЗДАТЬ</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.create_project"
											class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												СОЗДАТЬ ПРОЕКТ</div>
										</div>
									</Transition>
								</li>

								<li class="sidebar_button" :selected="$route.name == 'details'"
									:disabled="['projectList', 'users', 'createProject'].includes($route.name)"
									@click="setSubSection('details')" @mouseenter="handleMouseEnter('details')"
									@mouseleave="handleMouseLeave">
									<img class="button_img" src="@/assets/buttons/project_edit.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">О ПРОЕКТЕ</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.details && !['projectList', 'users', 'createProject'].includes($route.name)"
											class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												ИНФОРМАЦИЯ О ПРОЕКТЕ</div>
										</div>
									</Transition>
								</li>
								<li class="sidebar_button" :selected="$route.name == 'analytics'"
									:disabled="['projectList', 'users', 'createProject'].includes($route.name)"
									@click="setSubSection('analytics')" @mouseenter="handleMouseEnter('analytics')"
									@mouseleave="handleMouseLeave">
									<img class="button_img" src="@/assets/buttons/analytics.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">АНАЛИТИКА</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.analytics && !['projectList', 'users', 'createProject'].includes($route.name)"
											class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												АНАЛИТИКА</div>
										</div>
									</Transition>
								</li>
							</ul>
						</div>

						<div v-else-if="checkIsUserSection()" class="sidebar_block_second">
							<ul class="sidebar_block_list">
								<li class="sidebar_button" :disabled="checkAdmin(user) ? false : true"
									:selected="$route.name == 'createUser'" @click="setSubSection('createUser')"
									@mouseenter="handleMouseEnter('create_user')" @mouseleave="handleMouseLeave">
									<img class="button_img" src="@/assets/buttons/add_new_user.svg" />
									<transition name="show_text_near_icons">
										<div v-if="isSidebarOpen" class="button_text">СОЗДАТЬ</div>
									</transition>
									<Transition name="slide_fade">
										<div v-if="showAbbreviations.create_user && checkAdmin(user)"
											class="layout_designation_abbreviations">
											<div class="sidebar_text_mode text description"
												:style="setDescriptionTextStyle">
												СОЗДАНИЕ ПОЛЬЗОВАТЕЛЯ</div>
										</div>
									</Transition>
								</li>
							</ul>
						</div>
						<!-- #endregion -->
					</div>
					<!-- #endregion -->

					<!-- #region - нижний блок с кнопками ("Результаты") -->
					<div v-if="checkAdmin(user)" class="sidebar_block_third">
						<ul class="sidebar_block_list">
							<li class="sidebar_button" @click.prevent.stop="getTempProjectResults"
								@mouseenter="handleMouseEnter('upload_file')" @mouseleave="handleMouseLeave">
								<img class="button_img" src="@/assets/buttons/upload_file.svg" alt="" />
								<transition name="show_text_near_icons">
									<div v-if="isSidebarOpen" class="button_text">РЕЗУЛЬТАТЫ</div>
								</transition>
								<Transition name="slide_fade">
									<div v-if="showAbbreviations.upload_file" class="layout_designation_abbreviations">
										<div class="sidebar_text_mode text description"
											:style="setDescriptionTextStyle">
											СКАЧАТЬ РЕЗУЛЬТАТЫ</div>
									</div>
								</Transition>
							</li>
						</ul>
					</div>
					<!-- #endregion -->
				</div>

				<!-- стрелочка раскрытия/скрытия sidebar -->
				<div class="sidebar_arrow_block" :style="setSidebarArrowBlockStyle" @click="changeSidebarWidth">
					<img src="@/assets/icons/arrow_light.svg" :style="setArrowStyle" class="img_arrow" />
				</div>
			</div>

		</div>
	</transition>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { sidebar as sidebar, navbar as navbar } from "@/database/generalBase.js";
import { mFile } from "@/utils/file";
import { mPermissions } from "@/utils/permissions";
import { push } from 'notivue'

export default {
	name: "SideBar",
	mixins: [mFile, mPermissions],

	data() {
		return {
			isSidebarOpened: false,
			arrowBlockWidth: 19,
			currentArrowAngle: 0,
			showAbbreviations: {
				project: false,
				users: false,
				create_project: false,
				details: false,
				analytics: false,
				create_user: false,
				upload_file: false,
			},
			timer: null,
		};
	},

	methods: {
		/** Появление описания при наведении на кнопку  */
		handleMouseEnter(type) {
			if (!this.isSidebarOpened) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.showAbbreviations[type.toLowerCase()] = true;
				}, 550);
			}
		},
		async getTempProjectResults() {
			const props = { isUploadNotifiation: true, fileName: 'excel-sheet.xlsx' };
			const promise = push.promise({
				message: 'Файл загружается...',
				props,
			});

			try {
				this.$store.dispatch("project/getResults").then((response) => {
					new Promise((resolve) => {
						this.forceDownload(
							response,
							`projects-${new Date().toISOString()}.csv`,
							"", true
						);
						resolve();
					}).then(() => {
						promise.resolve({
							message: 'Файл успешно загружен!',
							props,
						});
					});
				});
			} catch (error) {
				push.error("Ошибка выгрузки данных");
			}
		},
		...mapActions({
			setSectionToStorage: "domprops/setSection",
			setSubSectionToStorage: "domprops/setSubSection",
			changeSidebar: "domprops/changeSidebar",
			closeSidebar: "domprops/closeSidebar",
		}),

		checkIsProjectSection() {
			let projectRoutes = ["projectList", "project", "section", "createProject", "details", "analytics"];
			return projectRoutes.includes(this.$route.name) ? true : false;
		},

		checkIsUserSection() {
			let userRoutes = ["userList", "user", "createUser"];
			return userRoutes.includes(this.$route.name) ? true : false;
		},

		checkUserCreatePermission() {
			if (!this.user) return false
			return this.checkAdmin(this.user) ? false : true;
		},

		checkUserPermission() {
			if (!this.user) return false
			if (this.user.is_project_manager || this.checkAdmin(this.user)) {
				return false;
			}
			return true;
		},

		setSection(value_) {
			if (this.isSidebarOpened) {
				this.isSidebarOpened = false;
				this.currentArrowAngle = 0;
				this.changeSidebar();
			}
			this.closeSidebar();

			if (value_ == "users") {
				if (this.checkUserPermission() !== false) return;
			}
			this.setSectionToStorage(value_);
			this.setSubSectionToStorage(null);
			let router_map = {
				projects: "projectList",
				users: "userList",
			};
			this.$router.push({ name: router_map[value_] });
		},

		setSubSection(value_) {
			if (this.isSidebarOpened) {
				this.isSidebarOpened = false;
				this.currentArrowAngle = 0;
				this.changeSidebar();
			}
			this.closeSidebar();

			if (value_ == "createProject") {
				if (this.checkUserPermission() !== false) return;
			} else if (value_ == "createUser") {
				if (this.checkAdmin(this.user) == false) return;
			}
			this.setSubSectionToStorage(value_);
			let router_map = {
				createProject: "createProject",
				details: "details",
				analytics: "analytics",
				createUser: "createUser",
			};
			if (value_ == "createProject" || value_ == "createUser") {
				this.$router.push({ name: router_map[value_] });
			} else {
				if (this.$route.params.id_proj) {
					this.$router.push({ name: router_map[value_], params: { id_proj: this.$route.params.id_proj } });
				}
			}
		},

		// меняем ширину sidebar и угол поворота стрелочки (при клике по стрелочке)
		changeSidebarWidth() {
			if (this.isSidebarOpened) {
				this.isSidebarOpened = false;
				this.currentArrowAngle = 0;
			} else {
				this.isSidebarOpened = true;
				this.currentArrowAngle = 180;
			}
			this.changeSidebar();
		},
		/** Исчезнование описания  */
		handleMouseLeave() {
			clearTimeout(this.timer);
			Object.keys(this.showAbbreviations).forEach((key) => (this.showAbbreviations[key] = false));
		},
	},

	watch: {
		getSection(value_) {
			return value_;
		},
		getSubsection(value_) {
			return value_;
		},
	},

	computed: {

		visiableDescription() {
			return { width: this.showAbbreviations.pim ? "250px" : "0px" };
		},
		setDescriptionTextStyle() {
			return {
				color: "#FFFFFF",
				width: "190px"
			};
		},
		setSidebarStyle() {
			let calcWidth;
			if (this.isSidebarOpen) {
				calcWidth = sidebar.extendedWidth;
			} else {
				calcWidth = sidebar.defaultWidth;
			}
			return {
				width: `${calcWidth}px`,
			};
		},

		setCapLogoStyle() {
			return {
				minHeight: `${navbar.height}px`,
			};
		},

		setSidebarArrowBlockStyle() {
			return {
				width: `${this.arrowBlockWidth}px`,
				height: `${10}%`,
				top: `${52}%`,
			};
		},

		setArrowStyle() {
			return {
				rotate: `${this.currentArrowAngle}deg`,
			};
		},

		setBlurredWindowStyle() {
			let screenWidth = window.innerWidth;
			return {
				width: `${screenWidth}px`,
			};
		},

		...mapGetters({
			getSection: "domprops/getSection",
			getSubsection: "domprops/getSubSection",
			isSidebarOpen: "domprops/getIsSidebarOpen",
			user: "user/getUser",
		}),
	},
};
</script>


<style lang="scss" scoped>
@import "@/css/sidebar.scss";

.layout_designation_abbreviations {
	position: absolute;
	margin-left: 48px;
	height: 70px;
	align-items: center;
	display: flex;
	width: fit-content;
	background-color: rgba(#2F9ADB, 0.75);
	transition-property: all;

}

.sidebar_text_mode {
	&.text {
		text-align: left;
		font-size: 14px;
		font-family: "sf-pro-display-medium";
		opacity: 1 !important;
	}

	&.description {
		margin: 0 40px 0 20px;
	}
}

.slide_fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide_fade-leave-active {
	transition: all 0.3s ease-out;
}

.slide_fade-enter-from,
.slide_fade-leave-to {
	transform: translateX(-10px);
	opacity: 0;
}
</style>
