export default function (instance) {
    let apiModule = 'auth/jwt';
    return {
        signIn(payload) {
            return instance.post(`api/${apiModule}/create/`, payload)
        },
        refreshToken(refreshToken) {
            return instance.post(`api/${apiModule}/refresh/`, refreshToken)
        }
    }
}