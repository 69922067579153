<template>
    <label class="checkbox style-black" :disabled="disabled">
        <input type="checkbox" :checked="modelValue" @change="updateInput" :disabled="disabled" class="black" />
        <div class="checkbox__checkmark"></div>
    </label>
</template>

<script>
export default {
    name: "sip-checkbox",
    props: {
        modelValue: {
            type: [String, Number, Boolean, Object],
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateInput(event) {
            // Emit the value as a boolean, considering the checkbox state
            this.$emit("update:modelValue", event.target.checked);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";
@import "@/css/checkbox.scss";
</style>