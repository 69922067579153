<template>
    <div class="non-content-text">
        <p v-if="message">{{ message }}</p>
        <p v-else>Не найдено ни одного элемента</p>
    </div>
</template>

<script>
export default {
    name: 'no-content-inline',
    props: {
        message: String,
    },
}
</script>

<style lang="scss" scoped>
@import '@/css/general.scss';

.no__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    color: $color-gray-3;
}
</style>
