import instance from './instance'
import authModule from './auth'
import sipModule from './sip'
import questionModule from './question'
import userModule from './user'


export default {
    auth: authModule(instance),
    sip: sipModule(instance),
    question: questionModule(instance),
    user: userModule(instance),
}