<template>
	<transition name="fade">
		<div class="modal-window" v-if="projectFileImport" @click="hideModalWindow">
			<div class="modal-window__content">
				<div class="header bg-blue">
					<img class="filter-white" src="@/assets/buttons/upload_file.svg" alt="">
					<span>Загрузка файлов</span>
				</div>
				<div class="content">
					<div class="block row">
						<label>Название файла</label>
						<sip-input type="text" :value="projectFileImport.name" disabled />
					</div>
					<div class="block row">
						<label>Тип файла</label>
						<sip-input type="text" :value="getFileExtension(projectFileImport.type)" disabled />
					</div>
					<div class="block row">
						<label :style="colorSize">Размер файла ({{ formattedSizeUnit }})<span
								class="form-label-required" v-if="projectFileImport.size == 0">*</span></label>
						<sip-input :style="colorSize" type="text" v-model="formattedSizeValue" disabled />
					</div>
				</div>
				<div class="footer">
					<div :class="projectFileImport ? 'sip-form' : 'sip-form-project'">
						<sip-button class="bg-blue" :disabled="!projectFileImport || projectFileImport.size == 0"
							@click="$emit('create-project-file')" @keyup.enter="$emit('create-project-file')">
							Импорт
						</sip-button>
						<sip-button v-if="projectFileImport" :disabled="$emit('expert-permission')"
							@click="projectFileImport = null">
							Отмена
						</sip-button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mFile } from '@/utils/file';

export default {
	name: "sip-modal-import",
	mixins: [mFile],
	props: {
		projectFile: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			projectFileImport: null,
		};
	},
	watch: {
		projectFile() {
			this.projectFileImport = this.projectFile;
			this.updateSize()
		},
		show() {
			if (this.show === false) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
			} else {
				window.addEventListener("keyup", this.onEscapeKeyUp);
			}
		},
	},
	methods: {
		onEscapeKeyUp(event) {
			if (event.which === 27) {
				this.hideModalWindow();
			} else if (event.which === 13) {
				window.removeEventListener("keyup", this.onEscapeKeyUp);
				this.deleteObject();
				this.hideModalWindow();
			}
		},
		hideModalWindow() {
			this.$emit("update:show", false);
		},
		deleteObject() {
			this.$emit("deleteObject", true);
		},
		updateSize() {
			if (!this.projectFileImport) return
			let size = this.projectFileImport.size;
			if (Math.trunc(size / 1024) == 0) {
				this.formattedSizeUnit = 'Б'
				this.formattedSizeValue = size.toPrecision(3)
				return
			}
			if (Math.trunc(size / 1024 / 1024) == 0) {
				this.formattedSizeUnit = 'КБ'
				this.formattedSizeValue = (size / 1024).toPrecision(3)
				return
			}
			if (Math.trunc(size / 1024 / 1024 / 1024) == 0) {
				this.formattedSizeUnit = 'МБ'
				this.formattedSizeValue = (size / 1024 / 1024).toPrecision(3)
				return
			}
			if (Math.trunc(size / 1024 / 1024 / 1024 / 1024) == 0) {
				this.formattedSizeUnit = 'ГБ'
				this.formattedSizeValue = (size / 1024 / 1024 / 1024).toPrecision(3)
				return
			}
		}

	},
	computed: {
		colorSize() {
			return {
				color: this.projectFileImport.size == 0 ? "red" : "#363639",
			};
		}
	},
	mounted() {
		this.updateSize()
	}
};
</script>

<style lang="scss" scoped>
@import "@/css/pages/tableDetail.scss";
@import "@/css/components/navtabs.scss";
@import "@/css/animation/lists.scss";

.bg-red {
	background-color: #fe6259;
	color: white;
}

// .bg-blue {
// 	background-color: $color-blue-4;
// 	color: white;
// }
.filter-white {
	filter: $filter-white;
}

.modal-window {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgb(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	z-index: 100;
}

.modal-window__content {
	margin: auto;
	background: white;
	min-height: 100px;
	min-width: 450px;
	border-radius: 5px;
	box-shadow: 0px 20px 20px 0px rgba(black, 0.1);
	overflow: hidden
}

.header {
	padding: 10px;
	border-bottom: 1px solid #d9d9d9;
	display: flex;
	gap: 10px;
	font-size: 13px;

	&>img {
		height: 20px;
	}
}

.content {
	padding: 20px 10px;
	margin: 0;
}

.footer {
	padding: 10px;
	gap: 10px;
	display: flex;
}

.block {
	margin: 2%;
	width: auto;
}

.bg-red {
	width: 100px !important;
	background-color: transparent;
	border: 1px solid #fe6259;
	color: #fe6259;
}

.sip-form {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	margin: 0 2% 0 2%;
}

.form-label-required {
	margin-left: 3px;
	color: red;
}

.fade-enter-active,
.fade-leave-active {
	opacity: 1;
	transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	transition: opacity 0.1s ease-in-out;
	opacity: 0;
}
</style>
