import Vuex from 'vuex'
import domprops from './domprops'
import user from './user'
import project from './project'

export default new Vuex.Store({
    modules: {
        domprops,
        user,
        project,
    }
})
