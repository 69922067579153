<template>
	<div class="tb-background" :style="{ position: 'relative' }">
		<sip-modal-import :projectFile="projectFileNewItem" @create-project-file="createProjectFile"
			@expert-permission="expertPermission"></sip-modal-import>
		<Transition name="fade"><sip-preloader v-if="isLoading" /></Transition>
		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :currentPage="currentPage" :showSearch="isShowSearch"
			v-model:searchQuery="searchText" @changePage="(page) => (currentPage = page)" />
		<Transition name="fade">
			<div class="content" v-if="!isLoading">
				<div class="content-block" v-if="currentPage == 'checklist'">
					<div class="table" v-if="!isLoading && APIDataFiltered.length != 0">
						<div class="row row__header">
							<div class="col-1 col__header sm"></div>
							<div class="col-1 col__header sm"
								:class="this.currentSortField === 'checklist_id' ? 'selected' : ''"
								@click="sortTable('checklist_id')">#
								<img :class="['imm_array_sort', getSortClasses('checklist_id')]"
									src="@/assets/icons/arrow_filter.svg" />
							</div>
							<div class="col-4 col__header col-lg-4"
								:class="this.currentSortField === 'checklist_text' ? 'selected' : ''"
								@click="sortTable('checklist_text')">
								Формулировка
								<img :class="['imm_array_sort', getSortClasses('checklist_text')]"
									src="@/assets/icons/arrow_filter.svg" />
							</div>
							<div class="col-2 col__header col-sm"
								:class="this.currentSortField === 'checklist_characteristic' ? 'selected' : ''"
								@click="sortTable('checklist_characteristic')">
								Характеристика ГИП
								<img :class="['imm_array_sort', getSortClasses('checklist_characteristic')]"
									src="@/assets/icons/arrow_filter.svg" />
							</div>
							<div class="col col__header col__input" style="position: relative;"
								:class="this.currentSortField === 'checklist_maturity' ? 'selected' : ''"
								@click="sortTable('checklist_maturity')">
								<!-- <div class="header_inner_block"> -->
								Степень проработки
								<img :class="['imm_array_sort', getSortClasses('checklist_maturity')]"
									src="@/assets/icons/arrow_filter.svg" />
								<img class="img_hint" src="@/assets/icons/info.svg" @mouseenter="showLegend()"
									@mouseleave="showLegend()" @click.stop.prevent="'pass'" />
								<transition name="fade">
									<div class="table__legend" v-if="isLegendShow">
										<div class="legend__text header">Условные обозначения</div>
										<div v-for="item in legendItems" :key="item.id" class="legend__block">
											<div class="legend__btn" :style="{ backgroundColor: item.color }">{{
												item.id }}
											</div>
											<div class="legend__text">{{ item.description }}</div>
										</div>
									</div>
								</transition>
							</div>
							<div class="col col__header"
								:class="this.currentSortField === 'checklist_comment' ? 'selected' : ''"
								@click="sortTable('checklist_comment')">
								Комментарий
								<img :class="['imm_array_sort', getSortClasses('checklist_comment')]"
									src="@/assets/icons/arrow_filter.svg" />
							</div>
						</div>
						<div class="table__body">
							<transition-group name="list">
								<div class="row row__content" :class="!row.is_relevant ? 'row__disabled' : ''"
									v-for="row in APIDataFiltered" :key="row.id">
									<div class="col-1 col__content sm container">
										<label :disabled="checkPermission(row)" class='checkbox style-black'>
											<input type="checkbox" v-model="row.is_relevant"
												:disabled="checkPermission(row)" @change="updateRelavant(row)"
												class="black" />
											<div class="checkbox__checkmark"></div>
										</label>
									</div>
									<div class="col-1 col__content sm">{{ row.question_id }}</div>
									<div class="col-4 col__content col-lg-4"
										style="padding-top: 1em; padding-bottom: 1em;">{{ row.text }}</div>
									<div class="col-2 col__content col-sm">{{ row.characteristic }}</div>
									<div class="col col__content col__content__inputs col__input">
										<div class="radio-wrapper">
											<input status="red" type="radio" value="0"
												:name="`inlineRadioOptions${row.id}`"
												:checked="row.maturity == 0 ? true : false"
												@click="updateMaturity($event, row)"
												:disabled="checkPermission(row) || row.is_relevant == false" />
											<input status="yellow" type="radio" value="1"
												:name="`inlineRadioOptions${row.id}`"
												:checked="row.maturity == 1 ? true : false"
												@click="updateMaturity($event, row)"
												:disabled="checkPermission(row) || row.is_relevant == false" />
											<input status="blue" type="radio" value="2"
												:name="`inlineRadioOptions${row.id}`"
												:checked="row.maturity == 2 ? true : false"
												@click="updateMaturity($event, row)"
												:disabled="checkPermission(row) || row.is_relevant == false" />
											<input status="green" type="radio" value="3"
												:name="`inlineRadioOptions${row.id}`"
												:checked="row.maturity == 3 ? true : false"
												@click="updateMaturity($event, row)"
												:disabled="checkPermission(row) || row.is_relevant == false" />
										</div>
									</div>
									<div class="col col__content">
										<div class="textarea__table">
											<sip-table-textarea v-model="row.comment"
												:disabled="checkPermission(row) || row.is_relevant == false"
												@focus="(e) => (focusTmpValue = e.target.value)"
												@blur="updateComment($event.target.value, row.id)" />
											<img class="textarea__pencil" src="@/assets/icons/pencil_textarea.svg" />
										</div>
									</div>
								</div>
							</transition-group>
						</div>
					</div>
				</div>
				<div class="content-block row" v-if="currentPage == 'files'">
					<files-page :listFiles="filesArray" :sectionData="sectionData" :isFileSection=false
						:isFileAuthor=false :widthTable="'50%'" :tableSize="'small'" />
				</div>
				<div class="content-block" v-if="currentPage == 'conclusion'">
					<conclusion-page :APIdata="sectionData" :isProject=false />
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import { mDate } from "@/utils/date";
import { mFile } from "@/utils/file";
import { mColor } from "@/utils/color";
import { mText } from "@/utils/text";
import { mPermissions } from "@/utils/permissions";
import { mFilters } from "@/utils/filters";
import { mapGetters } from "vuex";
import { push } from 'notivue'

import HeaderComp from "@/components/pages/HeaderComp.vue";
import FilesPage from "@/components/pages/components/FilesPage.vue"
import ConclusionPage from "@/components/pages/components/ConclusionPage.vue"

export default {
	name: "TableQuestions",
	mixins: [mDate, mColor, mText, mPermissions, mFilters, mFile],
	components: { HeaderComp, FilesPage, ConclusionPage },
	data() {
		return {
			navLinks: [
				{ id: "checklist", name: "Чек-лист", show: true },
				{ id: "files", name: "Файлы", show: true },
				{ id: "conclusion", name: "Заключение", show: true },
			],
			legendItems: [
				{ id: 0, description: "Недостаточная проработка, отсутствует", color: "#FE6259" },
				{ id: 1, description: "Минимальная проработка, много допущений", color: "#FEC331" },
				{ id: 2, description: "Достаточная проработка", color: "#B3D040" },
				{ id: 3, description: "Полная проработка", color: "#2FAD30" },
			],
			currentPage: "checklist",
			isLegendShow: false,
			isLoading: false,
			APIData: [],
			filesArray: [],
			sectionData: {},
			projectFileNewItem: null,
			objectIdToDelete: null,
			textForDelete: "",
			searchText: "",
			searchNoContentMessage: "😅 ничего не нашлось...",
			sortDirection: 1,
			currentSortField: "checklist_id",
			focusTmpValue: null,
		};
	},
	methods: {
		//  #region - Блок функция для обновления данных 
		/** Обновление степени проработки */
		async updateMaturity(event_, question) {
			let payload = {};
			let newValue = event_.target.value !== null ? Number(event_.target.value) : null;
			let currentMaturity = question.maturity !== null ? Number(question.maturity) : null;
			payload["apiSection"] = "questions";
			payload["objectId"] = question.id;
			payload["maturity"] = currentMaturity == newValue ? null : newValue;
			try {
				this.$store.dispatch("project/patchObject", payload).then(() => {
					this.$store.dispatch("project/fetchIndexGIP", this.$route);
					this.$store.dispatch("project/fetchAnswerQuestions", this.$route);
				});
				question.maturity = payload["maturity"];
			} catch (error) {
				push.error(`Ошибка изменения степени проработки!`)
			}
		},

		/** Обновление API данных с сервера  */
		async updateAPIData() {
			let params = new URLSearchParams();
			params.append("section", this.sectionData.section.id);
			params.append("project", this.$route.params.id_proj);
			params.append("apiSection", "questions");
			try {
				this.$store.dispatch("project/fetchList", params).then((response) => {
					this.APIData = this.normalizeAPIdata(response);
				});
			} catch (error) {
				console.log(error.response);
			}
		},

		/** Обновление вопросов  */
		async updateRelavant(question) {
			let payload = {};
			payload["apiSection"] = "questions";
			payload["objectId"] = question.id;
			payload["is_relevant"] = question.is_relevant;
			try {
				this.$store.dispatch("project/patchObject", payload).then(() => {
					this.$store.dispatch("project/fetchIndexGIP", this.$route);
					this.$store.dispatch("project/fetchAnswerQuestions", this.$route);
				});
			} catch (error) {
				push.error(`Ошибка изменения степени проработки!`)
			}
		},

		/** Обновление комментариев  */
		async updateComment(newValue_, id_) {
			if (newValue_ == this.focusTmpValue) {
				return;
			}
			let payload = {};
			payload["apiSection"] = "questions";
			payload["objectId"] = id_;
			payload["comment"] = newValue_;
			try {
				this.$store.dispatch("project/patchObject", payload);
			} catch (error) {
				push.error(`Ошибка изменения комментариев!`)
			}
		},

		/** Обновление заключения  */
		async updateCommonData(field_, name_) {
			let payload = {};
			payload["apiSection"] = "sections";
			payload["objectId"] = this.sectionData.id;
			payload[field_] = name_;
			try {
				this.$store.dispatch("project/patchObject", payload);
				push.success(`Заключение успешно обновлено!`)
			} catch (error) {
				push.error(`Ошибка обновления параметра секции!`)
			}
		},
		//#endregion

		//  #region - Блок работы с файлом

		/** Скачивание временных результатов проекта  */
		async getTempProjectResults() {
			const params = new URLSearchParams({ project_id: this.$route.params.id_proj });
			try {
				this.$store.dispatch("project/getResults", params).then((response) => {
					this.forceDownload(
						response,
						`${String(this.APIdata.name).replace(" ", "_")}-${new Date().toISOString()}.xlsx`, true
					);
				});
			} catch (error) {
				push.error(`Ошибка выгрузки данных!`)
			}
		},

		//#endregion

		//  #region - Блок работы с данными
		/** Нормализация api данных для их дальнейшего использования  */
		normalizeAPIdata(data_) {
			(data_)
			let new_data = [];
			data_.forEach((el) => {
				new_data.push({
					id: el.id ? el.id : "",
					question_id: el.question_id ? el.question_id : "",
					text: el.text ? el.text : "",
					characteristic: el.characteristic ? el.characteristic : "",
					maturity: el.maturity != null ? el.maturity : null,
					is_relevant: el.is_relevant != null ? el.is_relevant : null,
					comment: el.comment ? el.comment : "",
				});
			});
			return new_data;
		},
		//#endregion

		//  #region - Блок подфункций функция
		/**  Проверка доступа для пользователя */
		checkPermission() {
			if (this.sectionPermission(this.sectionData)) return false;
			return true;
		},

		/** Показать/Скрыть легенду  */
		showLegend() {
			this.isLegendShow = !this.isLegendShow;
		},

		/** Сохранение промежуточного значения  */
		saveInitialValue(value_) {
			this.tempInputValue = value_;
		},

		/** Получить значение инпута  */
		getInputValue(event) {
			if (this.tempInputValue == event.target.value) {
				return;
			}
			let field = event.target.name;
			let value = event.target.value;
			this.updateCommonData(field, value);
			if (field == "name") {
				localStorage.setItem("projectName", value);
			}
		},

		/** Изменить статус публикации заключения  */
		changePublishedStatus() {
			if (this.sectionData.is_conclusion_published) {
				this.sectionData.is_conclusion_published = false;
				this.updateCommonData("is_conclusion_published", false);
			} else {
				this.sectionData.is_conclusion_published = true;
				this.updateCommonData("is_conclusion_published", true);
			}
		},

		//#endregion
	},
	watch: {
		currentPage(val_) {
			if (val_ == 'checklist') this.currentSortField = 'checklist_id'
			if (val_ == 'files') this.currentSortField = 'file_name'
		}
	},
	computed: {
		//  #region - Блок работы со store
		...mapGetters({ userId: "user/getUserId" }),
		//#endregion

		//  #region - Блок работы с данными
		/** Показать/Скрыть икноку поиска  */
		isShowSearch() {
			return this.currentPage == "checklist" ? true : false;
		},

		/** Фильтрация данных при поиске и фильтре  */
		APIDataFiltered() {
			let data = this.APIData;

			// фильтруем посредством ввода символов в текстовое поле "Поиска"
			if (this.searchText.trim()) {
				data = data.filter((q) => q.text.toLowerCase().includes(this.searchText.toLowerCase()));
			}

			// сортировка массива
			if (this.currentSortField.startsWith("checklist_")) {
				let sortField = this.currentSortField.replace('checklist_', '')
				data.sort((a, b) => {
					let a_ = 0;
					let b_ = 0;
					const MINIMAL_VALUE = -1;

					if (sortField == "maturity") {
						a_ = parseFloat(a[sortField]);
						b_ = parseFloat(b[sortField]);
						if (isNaN(a_)) a_ = MINIMAL_VALUE;
						if (isNaN(b_)) b_ = MINIMAL_VALUE;

						// if (a_ == b_) return 1;
						return (a_ - b_) * this.sortDirection;
					} else {
						a_ = a[sortField];
						b_ = b[sortField];
						if (a_ == b_) return 1;
						let comp = a_ < b_ ? -1 : 1;
						return comp * this.sortDirection;
					}
				});
			}

			return data;
		},

		/** Фильтрация для файлов при поиске и фильтре */
		filesFiltered() {
			let data = this.filesArray;
			if (this.currentSortField.startsWith("file_")) {
				let sortField = this.currentSortField.replace('file_', '')
				data.sort((a, b) => {
					let a_ = 0;
					let b_ = 0;
					const MINIMAL_VALUE = -1000000;
					if ([
						'size',
					].includes(sortField)) {
						a_ = parseFloat(a[sortField]);
						b_ = parseFloat(b[sortField]);
						if (isNaN(a_)) a_ = MINIMAL_VALUE;
						if (isNaN(b_)) b_ = MINIMAL_VALUE;
						return (a_ - b_) * this.sortDirection;
					} else {
						a_ = a[sortField];
						b_ = b[sortField];
						if (a_ == b_) return 1;
						let comp = a_ < b_ ? -1 : 1;
						return comp * this.sortDirection;
					}
				});
			}
			return data;
		},
		//#endregion
	},
	async mounted() {
		this.isLoading = true;
		try {
			const section = (await this.$api.sip.retrieve(this.$route.params.id_sect, "sections")).data;
			this.sectionData = { ...section };

		} catch (error) {
			console.log(error);
		}
		let params = new URLSearchParams();
		params.append("section", this.sectionData.section.id);
		params.append("project", this.$route.params.id_proj);
		params.append("apiSection", "questions");
		try {
			this.$store.dispatch("project/fetchList", params).then((response) => {
				this.APIData = this.normalizeAPIdata(response);
			});
		} catch (error) {
			console.log(error.response);
		}
		params.set("apiSection", "files");
		try {
			this.$store.dispatch("project/fetchList", params).then((response) => {
				this.filesArray = [...response];
			});
		} catch (error) {
			console.log(error.response);
		} finally {
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/css/general.scss";
@import "@/css/components/grid.scss";
@import "@/css/pages/tableQuestion.scss";
@import "@/css/animation/lists.scss";
@import "@/css/checkbox.scss";

::-webkit-scrollbar {
	left: 200px;
}

.row-table {
	display: flex;
	flex-wrap: wrap;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.table__body {
	width: calc(100% + 7px);
	max-height: 73vh;
}

// .content {
// 	z-index: 3;
// 	position: relative;
// 	height: 100%;
// 	width: 100%;
// }

.page {
	height: 100%;
}

textarea {
	width: 100%;
	background: transparent;
}

.nav {
	--bs-nav-link-padding-y: 0rem;
	--bs-nav-link-padding-x: 0rem;
}




.nav-tabs {
	border-bottom: none;
	gap: 2em;
	padding-bottom: 1.5rem;
}

.nav-item {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.nav-tabs .nav-link {
	border: none;
}

.divider {
	height: 20px;
	width: 1px;
	background-color: #afafaf75;
}

.selected-divider {
	height: 2px;
	width: 20px;
	background-color: #363639;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}

.bounce-leave-active {
	animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

.search-filter {
	width: 80px;
	height: 35px;
	padding: 0;
	background: none;
	font-family: $sf-light;
	font-size: 15px;
	color: #363639;

	border: none;
	border-radius: 0;
	border-bottom: 1px solid #363639;

	transition: all ease 0.3s;

	&::placeholder {
		color: #363639;
		transition: all ease 0.3s;
	}

	&:hover {
		background: none;
	}

	&:hover::placeholder,
	&:focus::placeholder {
		color: $color-gray-4;
	}

	&:focus {
		outline: none;
		border: none;
		background: none;
		border-bottom: 1px solid #363639;
		width: 200px;
	}
}

.row.row__content.row__disabled {
	background: $color-gray-0;

	& .col__content {
		color: $color-gray-3;
	}

	& .col__content {
		color: $color-gray-3;
	}
}

.col-4 {
	padding-left: 3em;
}

.col-2 {
	padding-left: 3%;
	width: 17vw;
}

.col {
	width: 5em;
}

.col__input {
	width: 5em;
}

.header_inner_block {
	padding: 0px;
}

.col__header {
	user-select: none;
}

.col-1 {
	width: 15%;
}

.table__legend {
	position: absolute;
	top: 50px;
	left: 0;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	flex-wrap: wrap;
	background-color: white;
	box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
	transition: all ease-in-out 500ms;
	animation: appearing-23da840c;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	padding: 10px;
	align-content: center;
	z-index: 99;
	height: fit-content;
	width: 250px;
}

.legend__btn {
	font-size: 12px;
	width: 41px;
	height: 21px;
	background-color: $color-red-1;
	border-radius: 15%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.legend__text {
	font-size: clamp(10px, 0.7vw, 12px);
	width: 9vw;
	line-height: normal;

	&.header {
		color: #363639;
		font-family: "sf-pro-display-bold";
		font-size: 14px;
		display: flex;
		width: 100%;
		justify-content: flex-start;
		padding-bottom: 10px;
	}
}

.legend__block {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 6%;
	gap: 5%;
	height: 25px;
	align-items: center;
}

.img_hint {
	width: 31px;
	margin-left: 6em;
	filter: none !important;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}

.status_badge {
	gap: 2%;
	display: flex;
	align-items: center;
	height: 6vh;
}

.block-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: nowrap;
	gap: 20px;
}

.file-picker {
	display: flex;
	max-width: 410px;
	width: 410px;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	padding: 0.6%;
	position: relative;
	border: 2px solid rgba(0, 120, 193, 0.3);
	background-color: rgba(0, 120, 193, 0.05);
}

.content-block {
	padding: 0 15px;
	overflow-y: hidden !important;
}

.content-block.row {
	gap: 20px;
}

.subtitle {
	font-size: 14px;
	width: 100%;
	padding-bottom: 0px;
}

.sip-form>.btn-file {
	width: 110px;
}

.sip-form {
	gap: 10px;
	justify-content: flex-end;
}

.sip-form-project {
	gap: 10px;
	justify-content: flex-start;
}

.btn-file,
.btn-image {
	font-size: 13px !important;
}

.table__menu__btns .blue {
	width: 18px;
}

.table__menu__btns .red {
	width: 18px;
}

.badge-conclusion {
	height: 31px;
	display: flex;
	align-items: center;
}

.textarea__table {
	display: flex;
	width: 100%;
}

.textarea__pencil {
	transition: all ease 0.3s;
	opacity: 0;
	filter: invert(93%) sepia(11%) saturate(109%) hue-rotate(202deg) brightness(83%) contrast(86%);
}


.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e0e5e8;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 17px;
	width: 17px;
	left: 0;
	background-color: #b3b8ba;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #C2DFF1;
}

input:focus+.slider {
	box-shadow: 0 0 1px #C2DFF1;
}

input:checked+.slider:before {
	-webkit-transform: translateX(17px);
	-ms-transform: translateX(17px);
	transform: translateX(17px);
	background-color: #2F9ADB;
}

.slider.round {
	border-radius: 17px;
}

.slider.round:before {
	border-radius: 50%;
}


.block>.text {
	font-family: $sf-light;
	font-size: 12px;

	&>span.bold {
		font-family: $sf-bold;
	}
}

.content {
	padding: 0;
	margin: 0;
}

@media (max-width: 1380px) or (max-height: 720px) {}
</style>
