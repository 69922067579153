<template>
	<div class="tb-background" style="position: relative">
		<sip-modal-logout v-model:show="modalLogoutVisible" @logout="logout" />

		<HeaderComp :isLoading="isLoading" :navItems="navLinks" :currentPage="currentPage"
			@changePage="(page) => (currentPage = page)" :showBtn="true">
			<template v-slot:buttons>
				<sip-button class="bg-red" @click="showLogoutModal()">Выйти</sip-button>
			</template>
		</HeaderComp>

		<div class="content">
			<div class="content-block" v-if="currentPage == 'profile'">
				<div class="figure__wrapper" :disabled="!isMe && !checkAdmin(currentUser)">
					<input name="image" id="file" type="file" ref="file" accept="image/jpeg,image/png"
						@change="handleFileUpload($event.target.files)" style="display: none"
						:disabled="!isMe && !checkAdmin(currentUser)" />
					<img v-if="userData.image" class="figure__img" :src="userData.image" alt="Аватар пользователя"
						:disabled="!isMe && !checkAdmin(currentUser)" @click="$refs.file.click()" />
					<div v-else class="figure__img default" :style="{
						backgroundColor: getBGColor(userData.color), color: userData.color,
					}" alt="Аватар пользователя" :disabled="!isMe && !checkAdmin(currentUser)" @click="$refs.file.click()">
						{{ getInitialsByUser(userData) }}</div>
					<div class="figure__text" @click="$refs.file.click()" v-if="isMe || checkAdmin(currentUser)">
						Нажмите на фото, чтобы изменить
					</div>
					<img class="delete_figure__img" src="@/assets/icons/cross_mark.svg" alt="Удалить изображение"
						@click="handleDeleteImage(userData.id)" v-if="isMe || checkAdmin(currentUser)" />
					<div class="size-warning"><span class="form-label-required">*</span>Максимальный размер файла 5 мб
					</div>
				</div>
				<div class="block-group">
					<div class="subtitle">Данные аккаунта</div>
					<!-- Название проекта -->
					<div class="block">
						<label>Логин</label>
						<sip-input type="text" v-model="userData.username" disabled />
					</div>
					<div class="block">
						<label>Электронная почта</label>
						<sip-input type="email" v-model="userData.email" disabled />
					</div>
					<div class="block">
						<label>Имя</label>
						<sip-input type="text" v-model="userData.first_name" name="first_name"
							@focus="saveInitialValue($event.target.value)" @blur="getInputValue($event)"
							@keyup.enter="getInputValue($event)" :disabled="!isMe && !checkAdmin(currentUser)" />
					</div>
					<div class="block">
						<label>Фамилия</label>
						<sip-input type="text" v-model="userData.last_name" name="last_name" id="last_name"
							@focus="saveInitialValue($event.target.value)" @blur="getInputValue($event)"
							@keyup.enter="getInputValue($event)" :disabled="!isMe && !checkAdmin(currentUser)" />
					</div>
					<div class="block">
						<label>Отчество</label>
						<sip-input type="text" v-model="userData.second_name" name="second_name"
							@focus="saveInitialValue($event.target.value)" @blur="getInputValue($event)"
							@keyup.enter="getInputValue($event)" :disabled="!isMe && !checkAdmin(currentUser)" />
					</div>
					<div class="block">
						<label>Цвет <span>(*для отображения аватара без изображения)</span></label>
						<sip-input name="color" type="color" v-model="userData.color"
							:disabled="!isMe && !checkAdmin(currentUser)" @change="getInputValue($event)" />
					</div>
				</div>
				<div class="block-group">
					<div class="subtitle">Ролевая модель</div>
					<div class="block-row">
						<label>Администратор</label>
						<sip-checkbox disabled name="is_admin" v-model="userData.is_admin" />
					</div>
					<div class="block-row">
						<label>Руководитель проекта</label>
						<sip-checkbox :disabled="!checkAdmin(currentUser)" name="is_project_manager"
							v-model="userData.is_project_manager" @change="getInputValue($event)" />
					</div>
					<div class="block-row">
						<label>Эксперт</label>
						<sip-checkbox disabled name="is_expert" v-model="userData.is_expert" />

					</div>
				</div>
				<!-- <div class="block-group"> -->

				<!-- </div> -->
			</div>

			<div class="content-block" v-if="currentPage == 'areaExpertise'">
				<expertise-page :sectionsArray="sectionsData" :page='"userPage"'
					:userSectionsIdArray='userSectionsId' />
				<!-- <div class="table">
					<div class="row row__header">
						<div class="col-1 col__header">#</div>
						<div class="col col__header">Область экспертизы</div>
						<div class="col-2 col__header"></div>
					</div>
					<div class="table__body">
						<div class="row row__content" v-for="(row, rowIndex) in sectionsData" :key="row.id">
							<div class="col-1 col__content">{{ rowIndex + 1 }}</div>
							<div class="col col__content">{{ row.name }}</div>
							<div class="col-2 col__content">
								<sip-checkbox disabled :checked="isUserSectionsId(row)" />
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import HeaderComp from "@/components/pages/HeaderComp.vue";
import { mPermissions } from "@/utils/permissions";
import { mapGetters } from "vuex";
import { push } from 'notivue'

import ExpertisePage from "@/components/pages/components/ExpertisePage.vue";

export default {
	name: "TableUserPage",
	components: { HeaderComp, ExpertisePage },
	mixins: [mPermissions],
	data() {
		return {
			userData: {},
			sectionsData: [],
			userSectionsId: [],
			isMe: false,
			tempInputValue: "",
			currentPage: "profile",
			navLinks: [
				{ id: "profile", name: "Профиль пользователя", show: true },
				{ id: "areaExpertise", name: "Области экспертизы", show: true },
			],
			isLoading: false,
			projectFileNewItem: "",
			modalLogoutVisible: false,
		};
	},

	computed: {
		...mapGetters({
			currentUser: "user/getUser",
		}),
	},

	async mounted() {
		this.loadUserData()
	},

	methods: {
		showLogoutModal() {
			this.modalLogoutVisible = true;
		},
		getBGColor(hex_) {
			if (hex_) {
				return hex_ + "70";
			}
			return "#ffffff60";
		},
		getInitialsByUser(user_) {
			if (!user_) return "";
			if (Object.hasOwn(user_, 'first_name') && Object.hasOwn(user_, 'last_name')) {
				let f_name = '';
				let l_name = '';
				if (user_.first_name != null && user_.first_name.length > 0) f_name = user_.first_name[0];
				if (user_.last_name != null && user_.last_name.length > 0) l_name = user_.last_name[0];
				return `${f_name}${l_name}`;
			}
			return "";
		},
		/** Загрузка данных пользователя  */
		async loadUserData() {
			try {
				const userId = this.$route.params.id_user;
				const user = (await this.$api.user.retrieve(userId)).data;
				const sections = (await this.$api.question.list("sections")).data;
				this.userData = user;
				this.$store.dispatch("domprops/setActiveViewUser", user);
				this.sectionsData = sections;
				this.userSectionsId = user.sections;
				if (this.currentUser.id == userId) this.isMe = true;
			} catch (error) {
				console.log(error);
			}
		},
		/** Обновление фото пользователя  */
		handleFileUpload(targetFiles_) {
			if (targetFiles_.length > 0) {
				let fileToLoad = targetFiles_[0];
				let fileReader = new FileReader();
				fileReader.onload = (fileLoadedEvent) => {
					this.updateUserData("image", fileLoadedEvent.target.result);
				};
				fileReader.readAsDataURL(fileToLoad);
			}
		},

		/** Сохранение временного значения input  */
		saveInitialValue(value_) {
			this.tempInputValue = value_;
		},
		/** Получение значения input  */
		getInputValue(event) {
			if (event == this.tempInputValue) return
			let field = event.target.name;
			let value = "";
			if (field == "is_project_manager") {
				value = this.userData.is_project_manager;
			} else {
				if (this.tempInputValue == event.target.value) {
					return;
				}
				value = event.target.value;
				if (value == '') value = null
			}


			this.tempInputValue = value
			this.updateUserData(field, value);
		},
		async handleDeleteImage(id_) {
			try {
				await this.$api.user.deleteUserImage(id_).then((res) => {
					if (res.status == 204 && this.isMe) {
						this.$store.commit("user/CLEAR_IMAGE")
					}
				});
				push.success(`Фотография успешно удалена!`)
			} catch (error) {
				push.error(`Вес файла превышает максимально допустимый!`)
			}
		},
		/** Обновление данных пользователя  */
		async updateUserData(field, value) {
			let payload = {};
			payload[field] = value;
			try {
				let userData = (await this.$api.user.update(this.userData.id, payload)).data;
				this.$store.commit("domprops/SET_ACTIVE_VIEW_USER", userData);
				if (this.isMe) {
					this.$store.commit("user/SET_USER", userData);
					localStorage.setItem("user", JSON.stringify(userData));
				}
				this.userData = userData;
				if (field == "first_name") {
					push.success(`Значение поля "Имя" успешно обновлено!`)
				} else if (field == "last_name") {
					push.success(`Значение поля "Фамилия" успешно обновлено!`)
				} else if (field == "second_name") {
					push.success(`Значение поля "Отчество" успешно обновлено!`)
				} else if (field == "image") {
					push.success(`Фотография успешно обновлена!`)
				} else if (field == "color") {
					push.success(`Цвет профиля успешно обновлен!`)
				} else if (field == "is_project_manager") {
					push.success(`Ролевая модель успешно обновлена!`)
				}
			} catch (response) {
				let text
				if (response.request.responseText == "{\"image\":[\"Max file size is 5MB\"]}") {
					text = "Вес файла превышает максимально допустимый"
				} else {
					text = "Ошибка редактирования полей"
				}
				push.error(text)
			}
		},
		/** Выход из аккаунта  */
		logout() {
			localStorage.removeItem("access");
			localStorage.removeItem("refresh");
			this.$router.push({ name: "login" });
		},
	},

};
</script>

<style lang="scss" scoped>
@import "@/css/pages/userProfile.scss";


.default {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 36px;
	text-transform: uppercase;
}

.content-block {
	overflow-y: hidden !important;

	&.commons-page {
		align-items: center;
		padding: 0;
	}
}

label>span {
	font-size: 11px;
	color: $color-gray-4;
}
</style>
